import {
  Button,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  Input,
  Progress,
  Stack,
  Textarea,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineSave, AiOutlineReload } from 'react-icons/ai';

import {
  addGroupPending,
  addGroupSuccess,
  addGroupFail,
} from '../../../../../services/slice/addGroupSlice';

import {
  updateGroupPending,
  updateGroupSuccess,
  updateGroupFail,
} from '../../../../../services/slice/updateGroupSlice';

import {
  addGroupApi,
  updateGroupApi,
} from '../../../../../services/api/users-api';
import { useForm } from 'react-hook-form';

const SideDrawer = props => {
  console.log('🚀 ~ file: SideDrawer.js ~ line 30 ~ props', props);
  const {
    register,
    handleSubmit,
    // eslint-disable-next-line no-unused-vars
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();

  const {
    addGroupLoading,
  } = useSelector(state => state.addGroup);

  const {
    updateGroupSuccessful,
    updateGroupIsLoading,
  } = useSelector(state => state.updateGroup);

  const toast = useToast();

  const onSubmit = async data => {
    console.log('🚀 ~ file: SideDrawer.js ~ line 39 ~ data', data);

    dispatch(addGroupPending());

    try {
      const addGroup = await addGroupApi(data);
      console.log(
        '🚀 ~ file: SideDrawer.js ~ line 68 ~ addGroup',
        addGroup
      );

      await dispatch(addGroupSuccess(addGroup));
      toast({
        title: `Add Group ${addGroup}`,
        position: 'top-right',
        status: 'success',
        isClosable: true,
      });
      props.onClose();
    } catch (error) {
      console.log('🚀 ~ file: SideDrawer.js ~ line 49 ~ error', error);
      dispatch(addGroupFail(error));
      toast({
        title: `${error}`,
        position: 'top-right',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const onEditSubmit = async data => {
    console.log('🚀 ~ file: GroupSideDrawer.js ~ line 98 ~ data', data);

    dispatch(updateGroupPending());

    try {
      const updateProduct = await updateGroupApi(data);
      dispatch(updateGroupSuccess(updateProduct), () => {
        console.log(updateGroupSuccessful);
      });

      toast({
        title: `${updateProduct}`,
        position: 'top-right',
        status: 'success',
        isClosable: true,
      });

      props.onClose();
    } catch (error) {
      dispatch(updateGroupFail(error));
      toast({
        title: `${error}`,
        position: 'top-right',
        status: 'error',
        isClosable: true,
      });
    }
  };
  const color = useColorModeValue('#ae0000', 'white');

  return (
    <>
      <Drawer onClose={props.onClose} isOpen={props.isOpen} size={'lg'}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            {props.type === 'add' ? 'Add Group' : 'Edit Group'}
          </DrawerHeader>
          <Drawer />
          <DrawerBody>
            <form
              onSubmit={
                props.type === 'add'
                  ? handleSubmit(onSubmit)
                  : handleSubmit(onEditSubmit)
              }
            >
              <Stack spacing="6">
                {props.type !== "add" && 
                <FormControl id="id">
                  <FormLabel></FormLabel>
                  <Input
                    name="id"
                    {...register('id', { required: true })}
                    type="text"
                    autoComplete="text"
                    defaultValue={props.data.id}
                  />
                </FormControl>}
                <FormControl id="name">
                  <FormLabel>Group Name</FormLabel>
                  <Input
                    name="name"
                    {...register('name', { required: true })}
                    type="text"
                    autoComplete="text"
                    required
                    defaultValue={props.data.name}
                  />
                </FormControl>

                <FormControl id="description">
                  <FormLabel>Description</FormLabel>
                  <Textarea
                    name="description"
                    {...register('description', { required: true })}
                    type="text"
                    autoComplete="text"
                    required
                    defaultValue={props.data.description}
                  />
                </FormControl>

                <FormControl id="group_status">
                
                  <Checkbox
                    size="md"
                    colorscheme="green"
                    {...register('enable', { required: true })}
                    defaultChecked={props.data.enable}
                  >
                    Enable
                  </Checkbox>
               
              </FormControl>
                
                <Button
                  type="submit"
                  leftIcon={
                    addGroupLoading || updateGroupIsLoading ? (
                      <Progress size="xs" isIndeterminate />
                    ) : (
                      <AiOutlineSave
                        color={color}
                      />
                    )
                  }
                  width='full'
                >
                  Submit
                </Button>
                <Button
                  variant={'link'}
                  leftIcon={
                    <AiOutlineReload
                      color={color}
                    />
                  }
                  width='full'
                >
                  {props.type === 'add' ? 'Reset' : 'Cancel'}
                </Button>
              </Stack>
            </form>
            {addGroupLoading && <Progress size="xs" isIndeterminate />}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default SideDrawer;

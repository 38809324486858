import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addBrandSuccessful: false,
  addBrandLoading: false,
  addBrandError: "",
  addBrandMessage:"",
};

const addBrandSlice = createSlice({
  name: "addBrand",
  initialState,
  reducers: {
    addBrandPending: (state) => {
      state.addBrandLoading = true;
    },
    addBrandSuccess: (state, { payload }) => {
      state.addBrandLoading = false;
      state.addBrandSuccessful = true;
      state.addBrandError = "";
      state.addBrandMessage = payload;
    },
    addBrandFail: (state, { payload }) => {
      state.addBrandLoading = false;
      state.addBrandError = payload;
    },
  },
});

const { reducer, actions } = addBrandSlice;
export const { addBrandPending, addBrandSuccess, addBrandFail } =
  actions;

export default reducer;

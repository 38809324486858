import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  qualification: [],
  qualifiactionIsLoading: false,
  error: "",
};

const getAllQualificationsSlice = createSlice({
  name: "qualifications",
  initialState,
  reducers: {
    getAllQualificationsPending: (state) => {
      state.qualifiactionIsLoading = true;
    },
    getAllQualificationsSuccess: (state, { payload }) => {
      state.qualifiactionIsLoading = false;
      state.qualification = payload;
      state.error = "";
    },
    getAllQualificationsFail: (state, { payload }) => {
      state.qualifiactionIsLoading = false;
      state.error = payload;
    },
  },
});

const { reducer, actions } = getAllQualificationsSlice;
export const { getAllQualificationsPending, getAllQualificationsSuccess, getAllQualificationsFail } =
  actions;

export default reducer;

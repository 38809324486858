export const wishlistdata = [
    {
        "category_level_id": 0,
        "category_name": "AI",
        "category_status": true,
        "parent_group_id": "0",
        "product_id": 0,
        "product_name": "Data Science with Python",
        "description": "Data Science implimented using Python as a programing language",
        "attribute_id": 0,
        "key": "Course Level",
        "value": "Basic",
        "colour": null,
        "cost_price": 7500,
        "mrp": 25000,
        "units": null,
        "media_path": "https://redhood.s3.amazonaws.com/images/products/0.jpg",
        "brand_id": 0,
        "brand_name": "default",
        "coupon_code": null,
        "discount": null,
        "validity_status": null,
        "user_id": 1
    },
    {
        "category_level_id": 0,
        "category_name": "AI",
        "category_status": true,
        "parent_group_id": "0",
        "product_id": 0,
        "product_name": "Data Science with Python",
        "description": "Data Science implimented using Python as a programing language",
        "attribute_id": 1,
        "key": "Course Level",
        "value": "Advance",
        "colour": null,
        "cost_price": 7500,
        "mrp": 25000,
        "units": null,
        "media_path": "https://redhood.s3.amazonaws.com/images/products/1.jpg",
        "brand_id": 0,
        "brand_name": "default",
        "coupon_code": null,
        "discount": null,
        "validity_status": null,
        "user_id": 1
    }
]
import axios from 'axios';
import {
  ADD_ITEM_TO_CART_API,
  UPDATE_CART_API,
  //   DELETE_ATTRIBUTE_API,
  GET_CART_ITEM_BY_ID_API,
  GET_ALL_CART_ITEM_API,
} from './generic-api';

export const getCartItemByIdApi = payload => {
console.log("🚀 ~ file: cart-api.js:11 ~ getCartItemByIdApi ~ payload:", payload)

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        GET_CART_ITEM_BY_ID_API, payload,
        {
          headers: {
            session_id: sessionStorage.getItem('session_id'),
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      reject(error.message);
    }
  });
 
};

export const getAllCartItemApi = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(GET_ALL_CART_ITEM_API, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
        },
      });

      resolve(response.data);
    } catch (error) {
      reject(error.message);
    }
  });
};

export const addItemToCartApi = payload => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(ADD_ITEM_TO_CART_API, payload, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
        },
      });

      resolve(response.data);
    } catch (error) {
      reject(error.message);
    }
  });
};
export const updateCartApi = payLoadData => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.patch(UPDATE_CART_API, payLoadData, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
          'Access-Control-Allow-Origin': '*',
          withCredentials: false,
        },
      });

      resolve(response.data);
      // }
    } catch (error) {

      reject(error.message);
    }
  });
};

// export const deleteAttributeApi = payLoadData => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const response = await axios.delete(DELETE_ATTRIBUTE_API, payLoadData, {
//         headers: {
//           session_id: sessionStorage.getItem('session_id'),
//           'Content-type': 'application/json',
//         },
//       });
//       resolve(response.data);
//     } catch (error) {
//       reject(error.message);
//     }
//   });
// };

import { Button, FormControl, FormErrorMessage, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';

const ChangePassword = ({isOpen,onClose}) => {
    const [error, setError] = useState(true);
    const errorMessage = "Password should match"
    const {
        register,
        handleSubmit,
        // eslint-disable-next-line no-unused-vars
        formState: { errors },
      } = useForm();
    const initialRef = React.useRef()
  const finalRef = React.useRef()

  const onSubmit = async data => {
  
      if(data.password === data.confirmPassword) {
          setError(true);
      }
      else {
          setError(false);
      }
  }

  return (
    <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
        <form onSubmit={e => {
            e.preventDefault();
            
            handleSubmit(onSubmit);
          }}>
          <ModalHeader>Change Password</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
          
            <FormControl>
              <FormLabel>Password</FormLabel>
              <Input {...register('password', { required: true })} type="password" ref={initialRef} placeholder='Password' />

            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Confirm Password</FormLabel>
              <Input  {...register('confirm_password', { required: true })} type="password" ref={finalRef} placeholder='Confirm Password'  />
              {error && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
            </FormControl>
            
          </ModalBody>

          <ModalFooter>
            <Button type="submit" colorscheme='blue' mr={3}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
  )
}

export default ChangePassword
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateProductSuccessful: false,
  updateProductIsLoading: false,
  updateProductError: "",
  updateProductMessage:"",
};

const updateProductSlice = createSlice({
  name: "updateProduct",
  initialState,
  reducers: {
    updateProductPending: (state) => {
      state.updateProductIsLoading = true;
    },
    updateProductSuccess: (state, { payload }) => {
      state.updateProductIsLoading = false;
      state.updateProductSuccessful = true;
      state.updateProductError = "";
      state.updateProductMessage = payload;
    },
    updateProductFail: (state, { payload }) => {
    console.log("🚀 ~ file: updateProductSlice.js ~ line 24 ~ payload", payload)
      state.updateProductIsLoading = false;
      state.updateProductMessage = payload;
      state.updateProductError = true;
    },
  },
});

const { reducer, actions } = updateProductSlice;
export const { updateProductPending, updateProductSuccess, updateProductFail } =
  actions;

export default reducer;

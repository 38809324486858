import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addAttributeSuccessful: false,
  addAttributeLoading: false,
  addAttributeError: "",
  addAttributeMessage:"",
};

const addAttributeSlice = createSlice({
  name: "addAttribute",
  initialState,
  reducers: {
    addAttributePending: (state) => {
      state.addAttributeLoading = true;
    },
    addAttributeSuccess: (state, { payload }) => {
      state.addAttributeLoading = false;
      state.addAttributeSuccessful = true;
      state.addAttributeError = "";
      state.addAttributeMessage = payload;
    },
    addAttributeFail: (state, { payload }) => {
      state.addAttributeLoading = false;
      state.addAttributeError = payload;
    },
  },
});

const { reducer, actions } = addAttributeSlice;
export const { addAttributePending, addAttributeSuccess, addAttributeFail } =
  actions;

export default reducer;

import { Box, Heading, ListItem, Text, UnorderedList } from '@chakra-ui/react'
import React from 'react'


const CourseOverview = () => {
    return (
        <Box>
            <Box paddingTop={2} paddingBottom={2} marginTop={3} marginBottom={3} maxW={{ base: 200, md: 500, lg: 750 }}>
                <Heading marginTop={2} marginBottom={2}>Course Overview</Heading>
                <Text fontSize='md'>Our comprehensive suite of programming and IT courses offers cutting-edge learning opportunities for beginners, intermediate learners, and advanced professionals alike.
                    <UnorderedList py={5}>
                        <ListItem py={1}>
                            In-depth curriculum: Covering essential concepts, best practices, and industry-standard techniques.
                        </ListItem>
                        <ListItem py={1}>
                            Hands-on experience: Practical projects and exercises to reinforce your learning.
                        </ListItem>
                        <ListItem py={1}>
                            Expert instruction: Courses led by experienced professionals in the field.
                        </ListItem>
                        <ListItem py={1}>
                            Flexible learning: Self-paced modules allowing you to learn on your schedule.
                        </ListItem>
                        <ListItem py={1}>
                            Up-to-date content: Regular updates to keep pace with rapidly evolving technologies.
                        </ListItem>
                        <ListItem py={1}>
                            Interactive community: Forums and discussion boards to connect with fellow learners.
                        </ListItem>

                    </UnorderedList>
                    Whether you're looking to start a career in tech, upgrade your existing skills, or explore new programming languages and frameworks, our courses provide the tools and knowledge you need to succeed in today's digital landscape.
                    Explore our wide range of topics including web development, mobile app creation, data science, cloud computing, cybersecurity, and more. Start your journey towards becoming a proficient programmer or IT professional today!
                </Text>
            </Box>
        </Box >
    )
}

export default CourseOverview
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allMedia: [],
  getAllMediaLoading: false,
  getAllMediaError: "",
};

const getAllMediaSlice = createSlice({
  name: "getAllMedia",
  initialState,
  reducers: {
    getAllMediaPending: (state) => {
      state.getAllMediaLoading = true;
    },
    getAllMediaSuccess: (state, { payload }) => {
      state.getAllMediaLoading = false;
      state.allMedia = payload;
      state.getAllMediaError = "";
    },
    getAllMediaFail: (state, { payload }) => {
      state.getAllMediaLoading = false;
      state.getAllMediaError = payload;
    },
  },
});

const { reducer, actions } = getAllMediaSlice;
export const { getAllMediaPending, getAllMediaSuccess, getAllMediaFail } =
  actions;

export default reducer;

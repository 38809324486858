import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],
  isLoading: false,
  error: "",
};

const getAllUsersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    getAllUsersPending: (state) => {
      state.isLoading = true;
    },
    getAllUsersSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.users = payload;
      state.error = "";
    },
    getAllUsersFail: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

const { reducer, actions } = getAllUsersSlice;
export const { getAllUsersPending, getAllUsersSuccess, getAllUsersFail } =
  actions;

export default reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  deleteCategorySuccessful: false,
  deleteCategoryIsLoading: false,
  deleteCategoryError: "",
  deleteCategoryMessage:"",
};

const deleteCategorySlice = createSlice({
  name: "deleteCategory",
  initialState,
  reducers: {
    deleteCategoryPending: (state) => {
      state.deleteCategoryIsLoading = true;
    },
    deleteCategorySuccess: (state, { payload }) => {
      state.deleteCategoryIsLoading = false;
      state.addCategorySuccessful = true;
      state.addCategoryError = "";
      state.addCategoryMessage = payload;
    },
    deleteCategoryFail: (state, { payload }) => {
      state.deleteCategoryIsLoading = false;
      state.addCategoryError = payload;
    },
  },
});

const { reducer, actions } = deleteCategorySlice;
export const { deleteCategoryPending, deleteCategorySuccess, deleteCategoryFail } =
  actions;

export default reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateUserSuccessful: false,
  updateUserIsLoading: false,
  updateUserError: "",
  updateUserMessage:"",
};

const updateUserSlice = createSlice({
  name: "updateUser",
  initialState,
  reducers: {
    updateUserPending: (state) => {
      state.updateUserIsLoading = true;
    },
    updateUserSuccess: (state, { payload }) => {
      state.updateUserIsLoading = false;
      state.updateUserSuccessful = true;
      state.updateUserError = "";
      state.updateUserMessage = payload;
    },
    updateUserFail: (state, { payload }) => {
    
      state.updateUserIsLoading = false;
      state.updateUserMessage = payload;
      state.updateUserError = true;
    },
  },
});

const { reducer, actions } = updateUserSlice;
export const { updateUserPending, updateUserSuccess, updateUserFail } =
  actions;

export default reducer;

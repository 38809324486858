/* eslint-disable no-unused-vars */
import { AbsoluteCenter, Button, Divider, Flex, Heading, IconButton, Input, InputGroup, InputRightElement, Text, useColorModeValue, useToast } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { HiOutlineSearch } from 'react-icons/hi'
import { useDispatch, useSelector } from 'react-redux'

import {
  getAllQualificationsPending,
  getAllQualificationsSuccess,
  getAllQualificationsFail
} from '../../../../services/slice/getAllQualificationSlice';

import {deleteQualificationPending, deleteQualificationSuccess,
  deleteQualificationFail} from '../../../../services/slice/deleteQualificationSlice';

import {
  getAllQualificationApi,
  deleteQualificationApi
} from '../../../../services/api/qualification-api';
import CustomSpinner from '../../../../common/CustomSpinner'
import ProductQuickCards from '../../../../common/ProductCard/ProductQuickCards'
import SideDrawer from './QualificationSidePanel/QualificationSidePanel'

const Qualification = () => {


  const { qualification, qualifiactionIsLoading, error } = useSelector(state => state.qualification);
  

  const getAllQualification = async () => {
    dispatch(getAllQualificationsPending());
    try {
      const qualification = await getAllQualificationApi();
      console.log("🚀 ~ file: Qualification.js:31 ~ getAllQualification ~ qualification:", qualification);
      dispatch(getAllQualificationsSuccess(qualification));
    } catch (error) {
      dispatch(getAllQualificationsFail(error));
    }

  }

  const [openPanel, setOpenPanel] = useState(false);
  const [typeOfPanel, setTypeOfPanel] = useState('');
  const [dataForPanel, setDataForPanel] = useState([]);

  const [searchInput, setSearchInput] = useState('');
  const dispatch = useDispatch();
  const toast = useToast();


  const filteredData = qualification.filter(element => {
    const {
      type,
    } = element;


    return (
      type.toLowerCase().indexOf(searchInput.toLowerCase()) >= 0
    );
  });

  const openSidePanel = (data = [], type) => {

    if (openPanel) {
      getAllQualification();
    }
    setOpenPanel(!openPanel);
    setTypeOfPanel(type);
    setDataForPanel(data);
  };

  const deleteQualification = async deleteData => {
    
    dispatch(deleteQualificationPending());
    try {
      const deleteQualification = await deleteQualificationApi(JSON.stringify(deleteData));

      dispatch(deleteQualificationSuccess(deleteQualification));
      toast({
        title: `${deleteQualification}`,
        position: 'top-right',
        status: 'success',
        isClosable: true,
      })

      getAllQualification();
    }
    catch (error) {
      
      dispatch(deleteQualificationFail(error));
      toast({
        title: `${error}`,
        position: 'top-right',
        status: 'error',
        isClosable: true,
      });
    }
  }

  

  useEffect(() => {
    getAllQualification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Flex m={{ base: 3, md: 6 }} direction={['column']}>
        <Flex direction="row" justifyContent={'space-between'}>
          <Heading color={useColorModeValue('gray.700', 'white')}>
            Qualifications
          </Heading>
          <Button
            variant={'outline'}
            rightIcon={
              <AiOutlinePlus color={useColorModeValue('#ae0000', 'white')} />
            }
            rounded="full"
            onClick={() => openSidePanel([], 'add')}
            color={useColorModeValue('gray.700', 'white')}
          >
            <Text fontWeight={'bold'}>Add Qualification</Text>
          </Button>
        </Flex>

        <Divider marginY={{ base: 3, md: 5 }} />
        <InputGroup w={{ base: 'full', md: 'md' }} marginY={3}>
          <InputRightElement>
            <IconButton
              color="#ae0000"
              aria-label="Call Segun"
              size="sm"
              borderRadius={'full'}
              icon={<HiOutlineSearch />}
            />
          </InputRightElement>
          <Input
            id="searchData"
            placeholder="search here.."
            name="searchData"
            type={'text'}
            autoComplete="current-searchData"
            required
            variant={'flushed'}
            onChange={event => setSearchInput(event.target.value)}
          />
        </InputGroup>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          justifyContent="flex-start"
          wrap={'wrap'}
        >
          {qualifiactionIsLoading && (
            <AbsoluteCenter>
              <CustomSpinner />
            </AbsoluteCenter>
          )}
          {filteredData.map((element, index) => {
            return (
              <ProductQuickCards
                key={index + element.id}
                action={element => openSidePanel(element, 'edit')}
                data={element}
                type={'qualification'}
              deleteProductCard={element => deleteQualification(element)}
              />
            );
          })}
        </Flex>

        {openPanel && (
          <SideDrawer
            isOpen={openPanel}
            onClose={() => openSidePanel()}
            type={typeOfPanel}
            data={dataForPanel}
          />
        )}
      </Flex>
    </div>
  )
}

export default Qualification
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateQualificationSuccessful: false,
  updateQualificationIsLoading: false,
  updateQualificationError: "",
  updateQualificationMessage:"",
};

const updateQualificationSlice = createSlice({
  name: "updateQualification",
  initialState,
  reducers: {
    updateQualificationPending: (state) => {
      state.updateQualificationIsLoading = true;
    },
    updateQualificationSuccess: (state, { payload }) => {
      state.updateQualificationIsLoading = false;
      state.updateQualificationSuccessful = true;
      state.updateQualificationError = "";
      state.updateQualificationMessage = payload;
    },
    updateQualificationFail: (state, { payload }) => {
    
      state.updateQualificationIsLoading = false;
      state.updateQualificationMessage = payload;
      state.updateQualificationError = true;
    },
  },
});

const { reducer, actions } = updateQualificationSlice;
export const { updateQualificationPending, updateQualificationSuccess, updateQualificationFail } =
  actions;

export default reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartItems: [],
  getCartItemByIdLoading: false,
  getCartItemByIdError: "",
};

const getCartItemByIdSlice = createSlice({
  name: "CartItems",
  initialState,
  reducers: {
    getCartItemByIdPending: (state) => {
      state.getCartItemByIdLoading = true;
    },
    getCartItemByIdSuccess: (state, { payload }) => {
      state.getCartItemByIdLoading = false;
      state.cartItems = payload;
      state.getCartItemByIdError = "";
    },
    getCartItemByIdFail: (state, { payload }) => {
      state.getCartItemByIdLoading = false;
      state.getCartItemByIdError = payload;
    },
  },
});

const { reducer, actions } = getCartItemByIdSlice;
export const { getCartItemByIdPending, getCartItemByIdSuccess, getCartItemByIdFail } =
  actions;

export default reducer;

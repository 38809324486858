import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addCategorySuccessful: false,
  addCategoryLoading: false,
  addCategoryError: "",
  addCategoryMessage:"",
};

const addCategorySlice = createSlice({
  name: "addCategory",
  initialState,
  reducers: {
    addCategoryPending: (state) => {
      state.addCategoryLoading = true;
    },
    addCategorySuccess: (state, { payload }) => {
      state.addCategoryLoading = false;
      state.addCategorySuccessful = true;
      state.addCategoryError = "";
      state.addCategoryMessage = payload;
    },
    addCategoryFail: (state, { payload }) => {
      state.addCategoryLoading = false;
      state.addCategoryError = payload;
    },
  },
});

const { reducer, actions } = addCategorySlice;
export const { addCategoryPending, addCategorySuccess, addCategoryFail } =
  actions;

export default reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateSpecializationSuccessful: false,
  updateSpecializationIsLoading: false,
  updateSpecializationError: "",
  updateSpecializationMessage:"",
};

const updateSpecializationSlice = createSlice({
  name: "updateSpecialization",
  initialState,
  reducers: {
    updateSpecializationPending: (state) => {
      state.updateSpecializationIsLoading = true;
    },
    updateSpecializationSuccess: (state, { payload }) => {
      state.updateSpecializationIsLoading = false;
      state.updateSpecializationSuccessful = true;
      state.updateSpecializationError = "";
      state.updateSpecializationMessage = payload;
    },
    updateSpecializationFail: (state, { payload }) => {
    
      state.updateSpecializationIsLoading = false;
      state.updateSpecializationMessage = payload;
      state.updateSpecializationError = true;
    },
  },
});

const { reducer, actions } = updateSpecializationSlice;
export const { updateSpecializationPending, updateSpecializationSuccess, updateSpecializationFail } =
  actions;

export default reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addGroupSuccessful: false,
  addGroupIsLoading: false,
  addGroupError: "",
  addGroupMessage:"",
};

const addGroupSlice = createSlice({
  name: "addGroup",
  initialState,
  reducers: {
    addGroupPending: (state) => {
      state.addGroupIsLoading = true;
    },
    addGroupSuccess: (state, { payload }) => {
      state.addGroupIsLoading = false;
      state.addGroupSuccessful = true;
      state.addGroupError = "";
      state.addGroupMessage = payload;
    },
    addGroupFail: (state, { payload }) => {
      state.addGroupIsLoading = false;
      state.addGroupError = payload;
    },
  },
});

const { reducer, actions } = addGroupSlice;
export const { addGroupPending, addGroupSuccess, addGroupFail } =
  actions;

export default reducer;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Progress,
  Select,
  Stack,
  Textarea,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineSave, AiOutlineReload, AiOutlinePlusCircle } from 'react-icons/ai';


import {
  addProductFail,
  addProductPending,
  addProductSuccess,
} from '../../services/slice/addProductSlice';

import {
  updateProductPending,
  updateProductSuccess,
  updateProductFail,
} from '../../services/slice/updateProductSlice';

import {
  addProductApi,
  updateProductApi,
} from '../../services/api/product-api';

import { getAllCategoryApi } from '../../services/api/category-api';

import {
  getAllCategoryPending,
  getAllCategorySuccess,
  getAllCategoryFail,
} from '../../services/slice/getAllCategorySlice';
import { useForm } from 'react-hook-form';
import StepComponent from '../Step/StepComponent.js.jsx';
import AttributeSideDrawer from './AttributeSideDrawer';
import AttributeForm from './AttributeForm';
import { useNavigate } from 'react-router';

const SideDrawer = props => {
  const navigate = useNavigate();
  const [currentScreen, setCurrentScreen] = useState(props.type !== "attributes" ? 'addProduct' : 'attributes');
  
  const [latestProductId, setLatestProductId] = useState("");
  const brandColor = useColorModeValue('#ae0000', 'white');

  const {
    getAllCategoryLoading,
    categories,
    // getAllCategoryError,
  } = useSelector(state => state.category);
  const {
    register,
    handleSubmit,
    // eslint-disable-next-line no-unused-vars
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();

  const {
    addProductLoading,
    // addProductSuccessful,
    // addProductError,
    addProductMessage,
  } = useSelector(state => state.addProduct);

  const {
    updateProductSuccessful,
    // updateProductIsLoading,
    // updateProductError,
    updateProductMessage,
  } = useSelector(state => state.updateProduct);

  const toast = useToast();

  const fetchAllCategories = async () => {
    dispatch(getAllCategoryPending());
    try {
      const getAllCategories = await getAllCategoryApi();
      dispatch(getAllCategorySuccess(getAllCategories));
    } catch (error) {
      if (error.message === "Request failed with status code 401") {
        navigate('/signin', { replace: true })
      }
      dispatch(getAllCategoryFail(error));
    }
  };

  const onSubmit = async (data) => {
  


    dispatch(addProductPending());

    try {
      const addProduct = await addProductApi(data);
      setLatestProductId(addProduct.inserted_id);
      dispatch(addProductSuccess('inserted'));
      toast({
        title: `Add Product ${addProductMessage}`,
        position: 'top-right',
        status: 'success',
        isClosable: true,
      });
      setCurrentScreen('addAttribute');
    } catch (error) {
      if (error.message === "Request failed with status code 401") {
        navigate('/signin', { replace: true })
      }
      
      dispatch(addProductFail(error.message));
      toast({
        title: `${addProductMessage}`,
        position: 'top-right',
        status: 'error',
        isClosable: true,
      });
      props.onClose();
    }
  };

  const onEditSubmit = async data => {
    

    dispatch(updateProductPending());

    try {
      const updateProduct = await updateProductApi(data);

      dispatch(updateProductSuccess('updated'));
      if (updateProductSuccessful) {
        toast({
          title: `${updateProductMessage}`,
          position: 'top-right',
          status: 'success',
          isClosable: true,
        });
      }
    } catch (error) {
      if (error.message === "Request failed with status code 401") {
        navigate('/signin', { replace: true })
      }
      dispatch(updateProductFail(error));
      toast({
        title: `${error}`,
        position: 'top-right',
        status: 'error',
        isClosable: true,
      });
    }
  };

  
  useEffect(() => {
    if (categories.length === 0) {
      fetchAllCategories();
    }
  },[]);

  return (
    <>
      <Drawer onClose={props.onClose} isOpen={props.isOpen} size={'xl'}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
          <Flex width={'100%'} justify="space-between">
            {props.type === 'add' ? 'Add Product' : props.type === 'edit' ? 'Edit Product' : 'Attribute'}
            {props.type === 'attributes' && <AiOutlinePlusCircle  />}
            </Flex>
          </DrawerHeader>
          <Drawer />
          {props.type === 'add' && <StepComponent current={currentScreen} />}

          <DrawerBody>
            {currentScreen === 'addProduct' ? (
              <form
                onSubmit={
                  props.type === 'add'
                    ? handleSubmit(onSubmit)
                    : handleSubmit(onEditSubmit)
                }
              >
                <Stack spacing="6">
                  <FormControl id="product_name">
                    <FormLabel>Product Name</FormLabel>
                    <Input
                      name="product_name"
                      {...register('product_name', { required: true })}
                      type="text"
                      autoComplete="text"
                      required
                      defaultValue={props.data.product_name}
                    />
                  </FormControl>
                  <FormControl id="description">
                    <FormLabel>Description</FormLabel>
                    <Textarea
                      name="description"
                      {...register('description', { required: true })}
                      type="text"
                      required
                      placeholder="description here"
                      defaultValue={props.data.description}
                    />
                  </FormControl>

                  <FormControl id="categoryLevelId" isRequired>
                    <FormLabel>Category Level Id</FormLabel>

                    <Select
                      {...register('category_level_id', { required: true })}
                      placeholder="Select Category"
                      defaultValue={props.data.category_level_id}
                    >
                      {categories.map((element, item) => {
                        return (
                          <option
                            key={item + element.category_name}
                            value={element.category_level_id}
                          >
                            {element.category_name}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                  {/*<FormControl id="categoryLevelId">
                  <FormLabel>Category Level Id</FormLabel>
                  <Input
                    name="categoryLevelId"
                    {...register('category_level_id', { required: true })}
                    type="number"
                    autoComplete="number"
                    required
                    placeholder="number only"
                    defaultValue={props.data.category_level_id}
                  />
                    </FormControl>*/}
                  <Button
                    type="submit"
                    leftIcon={
                      addProductLoading ? (
                        <Progress size="xs" isIndeterminate />
                      ) : (
                        <AiOutlineSave
                          color={brandColor}
                        />
                      )
                    }
                    width='full'
                  >
                    Submit
                  </Button>
                  <Button
                    type="submit"
                    leftIcon={
                      <AiOutlineReload
                        color={brandColor}
                      />
                    }
                    width='full'
                  >
                    {props.type === 'add' ? 'Reset' : 'Cancel'}
                  </Button>
                </Stack>
              </form>
            ) : (
              <AttributeForm product_id={latestProductId} type="addNewAttribute" />
            )}

            {addProductLoading && <Progress size="xs" isIndeterminate />}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default SideDrawer;

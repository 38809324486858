import { Box, Container, ListItem, Text, UnorderedList } from '@chakra-ui/react'
import React from 'react'

const Privacy = () => {
    return (
        <Box py={10}>
            <Container>
                <Box>
                    <Text as='b' fontSize='2xl'>
                        Privacy Policy
                    </Text>
                </Box>
                <Text py={3}>Redhood Technologies LLP is committed to protecting the privacy and security of our users. This Privacy Policy explains how we collect, use, and protect your personal information when you use our website and services.</Text>

                <Text>Information We Collect We may collect the following types of personal information from you:</Text>

                <UnorderedList rowGap={2}>
                    <ListItem py={1}>Contact information such as your name, email address, and phone number.</ListItem>
                    <ListItem py={1}>Billing information such as your credit card number, billing address, and purchase history.</ListItem>
                    <ListItem py={1}>Technical information such as your IP address, browser type, and device information.</ListItem>
                    <ListItem py={1}>Usage information such as the pages you visit on our website and how you interact with our services.</ListItem>
                </UnorderedList>

                <Text py={3}>How We Use Your Information We use your personal information to:</Text>

                <UnorderedList rowGap={2}>
                    <ListItem py={1}>Provide and improve our products and services</ListItem>
                    <ListItem py={1}>Process your purchases and payments</ListItem>
                    <ListItem py={1}>Communicate with you about your account and our offerings</ListItem>
                    <ListItem py={1}>Personalize your experience on our website</ListItem>
                    <ListItem py={1}>Analyze usage trends and preferences</ListItem>
                    <ListItem py={1}>Protect against fraud and ensure the security of our systems</ListItem>
                </UnorderedList>
                <Text noOfLines={[1, 3, 5]} py={3}>Sharing Your Information We may share your personal information with third-party service providers that assist us in operating our website and delivering our services, such as payment processors, web hosting providers, and analytics providers. We do not sell or rent your personal information to any third parties for their own marketing purposes.</Text>
                <Text noOfLines={[1, 3, 5]} py={3}>Data Security We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, disclosure, or misuse. However, no data transmission or storage system can be guaranteed to be 100% secure.</Text>
                <Text noOfLines={[1, 3, 5]} py={3}>Your Rights You have the right to access, correct, or delete the personal information we have collected about you. You can also opt-out of receiving marketing communications from us. Please contact us at info@redhood.ai if you would like to exercise any of these rights.</Text>
                <Text noOfLines={[1, 3, 5]} py={3}>Changes to this Privacy Policy We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website.</Text>

            </Container>
        </Box>
    )
}

export default Privacy
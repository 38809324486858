/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Progress,
  Select,
  Stack,
  Textarea,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AiOutlineSave,
  AiOutlineReload,
  AiOutlinePlusCircle,
} from 'react-icons/ai';

import { getAttributeByIdApi } from '../../services/api/attribute-api';

import { getAllBrandApi } from '../../services/api/brand-api';

import {
  getAttributeByIdPending,
  getAttributeByIdSuccess,
  getAttributeByIdFail,
} from '../../services/slice/getAttributeById';

import {
  getAllBrandPending,
  getAllBrandSuccess,
  getAllBrandFail,
} from '../../services/slice/getAllBrandSlice';

import { useForm } from 'react-hook-form';
import StepComponent from '../Step/StepComponent.js.jsx';
import AttributeForm from './AttributeForm';
import { useNavigate } from 'react-router';

const AttributeSideDrawer = props => {
  const navigate = useNavigate();
  const listedSteps = props?.numberOfSteps;
  const [currentScreen, setCurrentScreen] = useState(
    props.type !== 'attributes' ? 'addProduct' : 'attributes'
  );
  const [showNewAttributeForm, setShowNewAttributeForm] = useState(false);
  const [attributeResponse, setAttributeResponse] = useState([{}]);
  const { attributes, getAttributeByIdLoading } = useSelector(
    state => state.getAttributeById
  );
  const { getAllBrandLoading, brands } = useSelector(
    state => state.getAllBrand
  );

  const attributeData = [];
  const {
    register,
    handleSubmit,
    // eslint-disable-next-line no-unused-vars
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();

  const toast = useToast();

  const fetchAllBrands = async () => {
    dispatch(getAllBrandPending());
    try {
      const getAllBrands = await getAllBrandApi();
      dispatch(getAllBrandSuccess(getAllBrands));
    } catch (error) {
    
      if (error.message === "Request failed with status code 401") {
        navigate('/signin', { replace: true })
      }
      dispatch(getAllBrandFail(error));
    }
  };

  const getAttributeById = async (payload = props.data) => {
    dispatch(getAttributeByIdPending());
    try {
      const getAttributeById = await getAttributeByIdApi(payload);

      dispatch(getAttributeByIdSuccess(getAttributeById));

      setAttributeResponse(getAttributeById);
    } catch (error) {
      if (error.message === "Request failed with status code 401") {
        navigate('/signin', { replace: true })
      }
      setShowNewAttributeForm(true);
      dispatch(getAttributeByIdFail(error));
    }
  };

  const addNewAttributeClicked = () => {
    setShowNewAttributeForm(true);
  };

  useEffect(() => {
    getAttributeById();
    fetchAllBrands();
  }, []);

  return (
    <>
      <Drawer onClose={props.onClose} isOpen={props.isOpen} size={'lg'}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <Flex width={'100%'} justify="space-between">
              Update Attributes
              <Button onClick={addNewAttributeClicked}>Add</Button>
            </Flex>
          </DrawerHeader>
          <Drawer />
          {props.type === 'add' && <StepComponent current={currentScreen} />}
          {(showNewAttributeForm === true) ? (
            <DrawerBody>
              <AttributeForm type={"addNewAttribute"}
                product_id={props.data}
                dataValues={[]} />
            </DrawerBody>
          ) : (
            <DrawerBody>
              {(getAllBrandLoading || getAttributeByIdLoading) && (
                <h2>Loading...</h2>
              )}

              {attributeResponse?.map((element, item) => {
                return (
                  <Accordion allowToggle key={item}>
                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box flex="1" textAlign="left">
                            Attribute {element.attribute_id}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <AttributeForm
                          type={props.type}
                          product_id={props.data}
                          dataValues={element}
                        />
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                );
              })}
              {getAttributeByIdLoading && (
                <Progress size="xs" isIndeterminate />
              )}
            </DrawerBody>
          )}
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default AttributeSideDrawer;

import axios from 'axios';
import {
  ADD_ATTRIBUTE_API,
  UPDATE_ATTRIBUTE_API,
  DELETE_ATTRIBUTE_API,
  GET_ALL_ATTRIBUTE_API,
  GET_ATTRIBUTE_BY_ID_API,
} from './generic-api';

export const getAllAttributeApi = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(GET_ALL_ATTRIBUTE_API, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
        },
      });
      
      resolve(response.data);
    } catch (error) {
      reject(error.message);
    }
  });
};

export const getAttributeByIdApi = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(GET_ATTRIBUTE_BY_ID_API+payload+"/select", {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
        },
      });
      
      
      resolve(response.data);
    } catch (error) {
      reject(error.message);
    }
  });
}

export const addAttributeApi = payload => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(ADD_ATTRIBUTE_API, payload, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
        },
      });
      
      resolve(response.data);
    } catch (error) {
      reject(error.message);
    }
  });
};
export const updateAttributeApi = payLoadData => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.patch(UPDATE_ATTRIBUTE_API, payLoadData, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
          'Access-Control-Allow-Origin': '*',
          withCredentials: false,
        },
      });

      resolve(response.data);
      // }
    } catch (error) {
      
      reject(error.message);
    }
  });
};

export const deleteAttributeApi = payLoadData => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(DELETE_ATTRIBUTE_API, payLoadData, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
          'Content-type': 'application/json',
        },
      });
      resolve(response.data);
    } catch (error) {
      reject(error.message);
    }
  });
};

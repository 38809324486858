/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  Textarea,
  useColorModeValue as mode,
  VStack,
} from '@chakra-ui/react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { CartItem } from '../Cart/CartItem';
import { CartPreview } from './CartPreview';
import { cartData } from './_data';
import {Link} from "react-router-dom";
import { colorConfig } from '../../../../colorConfig';

const Checkout = () => {
  const {
    register,
    handleSubmit,
    // eslint-disable-next-line no-unused-vars
    formState: { errors },
  } = useForm();

  return (
    <Box
      maxW={{
        base: '3xl',
        lg: '7xl',
      }}
      mx="auto"
      px={{
        base: '4',
        md: '8',
        lg: '12',
      }}
      py={{
        base: '6',
        md: '8',
        lg: '12',
      }}
    >
      <Stack
        direction={{
          base: 'column',
          lg: 'row',
        }}
        align={{
          lg: 'flex-start',
        }}
        spacing={{
          base: '8',
          md: '16',
        }}
      >
        <Stack
          direction={{
            base: 'column',
            lg: 'row',
          }}
          align={{
            lg: 'flex-start',
          }}
          spacing={{
            base: '8',
            md: '16',
          }}
        >
          <Stack
            spacing={{
              base: '8',
              md: '10',
            }}
            flex="2"
          >
            <Heading fontSize="2xl" fontWeight="extrabold">
              Shopping Cart ({cartData.length} items)
            </Heading>

            <Stack spacing="2">
              {cartData.map(item => (
                <CartItem key={item.id} {...item} />
              ))}
            </Stack>
          </Stack>
        </Stack>

        <Flex direction="column" align="center" flex="1">
          <CartPreview  />
          <HStack mt="6" fontWeight="semibold">
            <p>or</p>

            <Button variant="link" color={mode(colorConfig.primary)}>
              <Link to="/allcourses/all" >
                Continue shopping
              </Link>
            </Button>
          </HStack>
        </Flex>
      </Stack>
    </Box>
  );
};
export default Checkout;

import axios from 'axios';
import {
  ADD_BRAND_API,
  UPDATE_BRAND_API,
  GET_ALL_BRAND_API,
  DELETE_BRAND_API,
} from './generic-api';

export const getAllBrandApi = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(GET_ALL_BRAND_API, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
        },
      });

      resolve(response.data);
    } catch (error) {
      reject(error.message);
    }
  });
};
export const addBrandApi = payload => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(ADD_BRAND_API, payload, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
        },
      });
     

      resolve(response.data);
    } catch (error) {
      reject(error.message);
    }
  });
};
export const updateBrandApi = payLoadData => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.patch(UPDATE_BRAND_API, payLoadData, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
          'Access-Control-Allow-Origin': '*',
          withCredentials: false,
        },
      });
     

      resolve(response.data);
      // }
    } catch (error) {
      
      reject(error.message);
    }
  });
};

export const deleteBrandApi = (payLoadData) => {
 
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.delete(DELETE_BRAND_API, {data: payLoadData}, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
          'Access-Control-Allow-Origin': '*',
        },
      });

      resolve(response.data);
    } catch (error) {
      reject(error.message);
    }
  });
};

import React from "react";
import underProgress from "./underProgress.svg";
import {
  Wrapper,
  IntroWrapper,
  Details,
  Thumbnail,
  StyledContainer,
} from "./styles.js";
import { Container } from "@chakra-ui/react";

const WorkInProgress = () => {
  return (
    <Container maxW="container.xl">
      <Wrapper>
        <IntroWrapper as={StyledContainer}>
          <Details>
            <h1>404 - Page not found</h1>

          </Details>
          <Thumbnail>
            <img src={underProgress} alt="Page Not Available" />
          </Thumbnail>
        </IntroWrapper>

      </Wrapper>
    </Container>
  );
};
export default WorkInProgress;

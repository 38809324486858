import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateGroupSuccessful: false,
  updateGroupIsLoading: false,
  updateGroupError: "",
  updateGroupMessage:"",
};

const updateGroupSlice = createSlice({
  name: "addGroup",
  initialState,
  reducers: {
    updateGroupPending: (state) => {
      state.updateGroupIsLoading = true;
    },
    updateGroupSuccess: (state, { payload }) => {
      state.updateGroupIsLoading = false;
      state.updateGroupSuccessful = true;
      state.updateGroupError = "";
      state.updateGroupMessage = payload;
    },
    updateGroupFail: (state, { payload }) => {
      state.updateGroupIsLoading = false;
      state.updateGroupError = payload;
    },
  },
});

const { reducer, actions } = updateGroupSlice;
export const { updateGroupPending, updateGroupSuccess, updateGroupFail } =
  actions;

export default reducer;

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Progress,
  Radio,
  RadioGroup,
  Select,
  Textarea,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineSave, AiOutlineReload } from 'react-icons/ai';
import {
  getAllGroupsPending,
  getAllGroupsSuccess,
  getAllGroupsFail,
} from '../../../../../services/slice/getAllGroupSlice';

import {
  addUserPending,
  addUserSuccess,
  addUserFail,
} from '../../../../../services/slice/addUserSlice';

import {
  updateUserPending,
  updateUserSuccess,
  updateUserFail,
} from '../../../../../services/slice/updateUserSlice';

import { useForm } from 'react-hook-form';
import {
  addUserApi,
  getAllGroupApi,
  updateUserApi,
} from '../../../../../services/api/users-api';

import {
  getAllSpecializationsPending,
getAllSpecializationsSuccess,
getAllSpecializationsFail
} from '../../../../../services/slice/getAllSpecializationSlice';

import { getAllSpecializationApi } from '../../../../../services/api/specialization-api';

const SideDrawer = props => {
  console.log("🚀 ~ file: SideDrawer.js:64 ~ SideDrawer ~ props:", props)
  const {
    reset,
    register,
    handleSubmit,
    // eslint-disable-next-line no-unused-vars
    formState: { errors },
  } = useForm();
  
  const { specializations } = useSelector(state => state.specialization);
  const { groups } = useSelector(state => state.getAllGroup);

  const getAllSpecialization = async () => {
    dispatch(getAllSpecializationsPending());
    try {
      const specialization = await getAllSpecializationApi();
      console.log("🚀 ~ file: Specialization.js:31 ~ getAllSpecialization ~ Specialization:", specialization);
      dispatch(getAllSpecializationsSuccess(specialization));
      reset(props.data)
    } catch (error) {
      dispatch(getAllSpecializationsFail(error));
    }
  }

  const fetchGroupData = async () => {
    dispatch(getAllGroupsPending());
    try {
      const getAllCategories = await getAllGroupApi();
      dispatch(getAllGroupsSuccess(getAllCategories));
      reset(props.data)
    } catch (error) {
      dispatch(getAllGroupsFail(error));
    }
  };


  
  const dispatch = useDispatch();

  const { addCategoryLoading } = useSelector(state => state.addCategory);

  const { updateUserSuccessful, updateUserIsLoading } = useSelector(
    state => state.updateUser
  );

  const toast = useToast();

  const onSubmit = async ( data) => {
    
    data.country_code = '91'
    console.log('🚀 ~ file: SideDrawer.js ~ line 39 ~ data', data);
    data.exit_date === "" && delete data['exit_date']; 
    dispatch(addUserPending());

    try {
      const addUser = await addUserApi(data);

      await dispatch(addUserSuccess(addUser));
      toast({
        title: `Add user ${addUser}`,
        position: 'top-right',
        status: 'success',
        isClosable: true,
      });
      props.onClose();
    } catch (error) {
      console.log('🚀 ~ file: SideDrawer.js ~ line 49 ~ error', error);
      dispatch(addUserFail(error));
      toast({
        title: `${error}`,
        position: 'top-right',
        status: 'error',
        isClosable: true,
      });
    }
  };
  

  const onEditSubmit = async data => {
    data.id = props.data.id;
    data.exit_date === "" && delete data['exit_date']; 
    dispatch(updateUserPending());

    try {
      const updateUser = await updateUserApi(data);
      dispatch(updateUserSuccess(updateUser), () => {
        console.log(updateUserSuccessful);
      });

      toast({
        title: `${updateUser}`,
        position: 'top-right',
        status: 'success',
        isClosable: true,
      });

      props.onClose();
    } catch (error) {
      dispatch(updateUserFail(error));
      toast({
        title: `${error}`,
        position: 'top-right',
        status: 'error',
        isClosable: true, 
      });
    }
  };


  useEffect(() => {
    if (groups.length === 0) {
      fetchGroupData();
      
    }
    if (specializations.length === 0) {
      getAllSpecialization();
    }
    // reset(props.data)
  }, []);

  const color = useColorModeValue('#ae0000', 'white');
  return (
    <>
      <Drawer onClose={props.onClose} isOpen={props.isOpen} size={'xl'}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            {props.type === 'add'
              ? 'Add User'
              : `Edit User id: ${props.data.id}`}
          </DrawerHeader>
          <Drawer />
          <DrawerBody>
            <form
              onSubmit={
                props.type === 'add'
                  ? handleSubmit(onSubmit)
                  : handleSubmit(onEditSubmit)
              }
            >
              <Divider />
              <Box w={{ base: '100%', md: '100%' }}>
                <Flex direction={{ base: 'column', md: 'row' }}>
                  <FormControl id="first_name" isRequired py="5" px="5">
                    <FormLabel>First Name</FormLabel>
                    <Input
                      type="text"
                      defaultValue={props.data.first_name}
                      name="first_name"
                      {...register('first_name', { required: false })}
                    />
                    <FormHelperText>what should we call you?</FormHelperText>
                  </FormControl>

                  <FormControl id="middle_name" py="5" px="5">
                    <FormLabel>Second Name</FormLabel>
                    <Input
                      defaultValue={props.data.middle_name}
                      type="text"
                      {...register('middle_name', { required: false })}
                    />
                    <FormHelperText>
                      not everyone has a second name.
                    </FormHelperText>
                  </FormControl>
                  <FormControl id="last_Name" isRequired py="5" px="5">
                    <FormLabel>Last Name</FormLabel>
                    <Input
                      type="text"
                      defaultValue={props.data.last_name}
                      {...register('last_name', { required: false })}
                    />
                    <FormHelperText>Your family name.</FormHelperText>
                  </FormControl>
                </Flex>
              </Box>
              

              <Box w={{ base: '100%', md: '100%' }}>
                <Flex direction={{ base: 'column', md: 'row' }}>
                  <FormControl id="country_code" isRequired py="5" px="5">
                    <FormLabel>Phone Number</FormLabel>

                    <InputGroup>
                      <InputLeftAddon children='+91' {...register('country_code')} />
                      <Input type='tel' placeholder='phone number'
                        defaultValue={props.data.phone}
                        {...register('phone', { required : true })} />
                    </InputGroup>
                    <FormHelperText>we won't share your number.</FormHelperText>
                  </FormControl>


                  <FormControl id="phone_verified" py="5" px="5">
                    <FormLabel as="legend">Verified Contact Number</FormLabel>
                    <RadioGroup defaultValue={props.data.phone_verified === true ? 'true' : 'false'}>
                      <HStack spacing="24px">
                        <Radio
                          value={'true'}
                          {...register('phone_verified', { required : true })}
                        >
                          Verified
                        </Radio>
                        <Radio
                          value={'false'}
                          {...register('phone_verified', { required : true })}
                        >
                          Not Verified
                        </Radio>

                      </HStack>
                    </RadioGroup>
                    
                    
                  </FormControl>

                </Flex>
              </Box>

              <Box w={{ base: '100%', md: '100%' }}>
                <Flex direction={{ base: 'column', md: 'row' }}>
                  <FormControl id="email" isRequired py="5" px="5">
                    <FormLabel>Email</FormLabel>
                    <Input
                      type="email"
                      defaultValue={props.data.email}
                      {...register('email', { required : true })}
                    />
                    <FormHelperText>
                      you will use this as login credential.
                    </FormHelperText>
                  </FormControl>
                  <FormControl id="password" isRequired py="5" px="5">
                    <FormLabel>Password</FormLabel>
                    <Input
                      defaultValue={props.data.password}
                      type="password"
                      {...register('password', { required : true })}
                    />
                    <FormHelperText>
                      you will use this as login credential.
                    </FormHelperText>
                  </FormControl>

                  {/*<FormControl id="confirmPassword" isRequired py="5" px="5">
                    <FormLabel>Confirm Password</FormLabel>
                    <Input type="password" />
                    <FormHelperText>
                      Re-enter the password to confirm.
                    </FormHelperText>
            </FormControl>*/}
                </Flex>
              </Box>

              <Box w={{ base: '100%', md: '100%' }}>
                <Flex direction={{ base: 'column', md: 'row' }}>
                  <FormControl as="fieldset" isRequired py="5" px="5">
                    <FormLabel as="legend">Gender</FormLabel>
                    <RadioGroup defaultValue={props.data.gender}>
                      <HStack spacing="24px">
                        <Radio
                          value="Male"
                          {...register('gender', { required : true })}
                        >
                          Male
                        </Radio>
                        <Radio
                          value="Female"
                          {...register('gender', { required : true })}
                        >
                          Female
                        </Radio>
                        <Radio
                          value="Others"
                          {...register('gender', { required : true })}
                        >
                          Others
                        </Radio>
                      </HStack>
                    </RadioGroup>
                  </FormControl>
                  <FormControl id="group_id" isRequired py="5" px="5">
                    <FormLabel>Group ID</FormLabel>

                    <Select
                      {...register('group_id', { required : true })}
                      placeholder="Select Group"
                      defaultValue={props.data.group_id}
                    >
                      {groups.map((element, item) => {
                        return (
                          <option key={item + element.name} value={element.id}>
                            {element.name}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Flex>
              </Box>

              <Box w={{ base: '100%', md: '100%' }}>
                <Flex direction={{ base: 'column', md: 'row' }}>
                  <FormControl id="dateOfBirth" isRequired py="5" px="5">
                    <FormLabel>DOB</FormLabel>
                    
                    <Input
                    
                      type="date"
                      defaultValue={props.data.dob}
                      {...register('dob', { required : true })}
                    />
                    <FormHelperText>Your birth date & year.</FormHelperText>
                  </FormControl>

                  <FormControl id="bloodGroup" isRequired py="5" px="5">
                    <FormLabel>Blood Group</FormLabel>
                    <Input
                      type="text"
                      defaultValue={props.data.blood_group}
                      {...register('blood_group', { required: false })}
                    />
                    <FormHelperText>
                      as mentioned in your medical book/record.
                    </FormHelperText>
                  </FormControl>
                </Flex>
              </Box>

              <Box w={{ base: '100%', md: '100%' }}>
                <Flex direction={{ base: 'column', md: 'row' }}>
                  <FormControl id="presentAddress" isRequired py="5" px="5">
                    <FormLabel>Present Address</FormLabel>
                    <Textarea
                      placeholder="address here."
                      size="sm"
                      resize="none"
                      defaultValue={props.data.present_address}
                      {...register('present_address', { required : true })}
                    />
                    <FormHelperText>your current address.</FormHelperText>
                  </FormControl>

                  <FormControl id="permanent_address" py="5" isRequired px="5">
                    <FormLabel>Parmanent Address</FormLabel>
                    <Textarea
                      placeholder="address here."
                      defaultValue={props.data.present_address}
                      size="sm"
                      resize="none"
                      {...register('permanent_address', { required: false })}
                    />
                    <FormHelperText>
                      as mentioned in your id-proof.
                    </FormHelperText>
                  </FormControl>
                </Flex>
              </Box>
              <Divider />
              <Box w={{ base: '100%', md: '100%' }}>
                <Flex direction={{ base: 'column', md: 'row' }}>
                  <FormControl id="joining_date" isRequired py="5" px="5">
                    <FormLabel>Joining Date</FormLabel>
                    <Input
                      type="date"
                      defaultValue={props.data.joining_date}
                      dateFormat='yyyy-mm-dd'
                      {...register('joining_date', { required : true })}
                    />
                  </FormControl>

                  <FormControl id="exit_date" py="5" px="5">
                    <FormLabel>Exit Date</FormLabel>
                    <Input
                      type="date"
                      defaultValue={props.data.exit_date}
                      dateFormat='yyyy-mm-dd'
                      {...register('exit_date')}
                    />
                  </FormControl>
                </Flex>
              </Box>
              <Box w={{ base: '100%', md: '100%' }}>
                <Flex direction={{ base: 'column', md: 'row' }}>
                  <FormControl id="display_pic" py="5" px="5">
                    <FormLabel>Display Pic</FormLabel>
                    <Input
                      type="text"
                      defaultValue={props.data.display_pic}
                      {...register('display_pic', { required: false })}
                    />
                  </FormControl>

                  <FormControl id="profile_pic" py="5" px="5">
                    <FormLabel>Profile Pic</FormLabel>
                    <Input
                      type="text"
                      defaultValue={props.data.profile_pic}
                      {...register('profile_pic', { required: false })}
                    />
                  </FormControl>
                </Flex>
              </Box>
              <Box w={{ base: '100%', md: '100%' }}>
                <Flex direction={{ base: 'column', md: 'row' }}>
                  <FormControl id="qualification_id" isRequired py="5" px="5">
                    <FormLabel>Qualification</FormLabel>
                    <Select
                      {...register('qualification_specialization_id', { required : true })}
                      placeholder="Select Qualification"
                      defaultValue={props.data.qualification_specialization_id}
                    >
                      {specializations.map((element, item) => {
                        return (
                          <option
                            key={item + element.id}
                            value={element.id}
                          >
                            {element.specialization}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>

                  
                  <FormControl id="profile_status" isRequired py="5" px="5">
                    <FormLabel as="legend">Enabled</FormLabel>
                    <RadioGroup defaultValue={props.data.profile_status === true ? 'true' : 'false'}>
                      <HStack spacing="24px">
                        <Radio
                          value={'true'}
                          {...register('profile_status', { required : true })}
                        >
                          Enable
                        </Radio>
                        <Radio
                          value={'false'}
                          {...register('profile_status', { required : true })}
                        >
                          Disable
                        </Radio>

                      </HStack>
                    </RadioGroup>
                  </FormControl>
                </Flex>
              </Box>
              <Divider />
              <Box w={{ base: '100%', md: '100%' }}>
                <Flex direction={{ base: 'column', md: 'row' }}>
                  <FormControl id="fatherName" py="5" px="5">
                    <FormLabel>Father Name</FormLabel>
                    <Input
                      type="text"
                      defaultValue={props.data.father_name}
                      {...register('father_name', { required : true })}
                    />
                    <FormHelperText>Your father's name.</FormHelperText>
                  </FormControl>

                  <FormControl id="fatherPhoneNumber" py="5" px="5">
                    <FormLabel>Father's Phone Number</FormLabel>
                    <Input
                      type="number"
                      defaultValue={props.data.father_phone}
                      {...register('father_phone', { required: false })}
                    />
                    <FormHelperText>
                      in emergency contact number.
                    </FormHelperText>
                  </FormControl>
                </Flex>
              </Box>
              <Box w={{ base: '100%', md: '100%' }}>
                <Flex direction={{ base: 'column', md: 'row' }}>
                  <FormControl id="motherName" py="5" px="5">
                    <FormLabel>Mother Name</FormLabel>
                    <Input
                      defaultValue={props.data.mother_name}
                      type="text"
                      {...register('mother_name', { required : true })}
                    />
                    <FormHelperText>Your mother's name.</FormHelperText>
                  </FormControl>

                  <FormControl id="motherPhoneNumber" py="5" px="5">
                    <FormLabel>Mother's Phone Number</FormLabel>
                    <Input
                      type="number"
                      defaultValue={props.data.mother_phone}
                      {...register('mother_phone', { required: false })}
                    />
                    <FormHelperText>
                      in emergency contact number.
                    </FormHelperText>
                  </FormControl>
                </Flex>
              </Box>
              <Button
                type="submit"
                leftIcon={
                  addCategoryLoading || updateUserIsLoading ? (
                    <Progress size="xs" isIndeterminate />
                  ) : (
                    <AiOutlineSave
                      color={color}
                    />
                  )
                }
                width='full'
              >
                Submit
              </Button>
              <Button
                py="5"
                variant={'link'}
                leftIcon={
                  <AiOutlineReload
                    color={color}
                  />
                }
                width='full'
              >
                {props.type === 'add' ? 'Reset' : 'Cancel'}
              </Button>
            </form>

            {addCategoryLoading && <Progress size="xs" isIndeterminate />}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};


export default SideDrawer;

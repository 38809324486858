import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import {
  AbsoluteCenter,
  Button,
  Divider,
  Flex,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { AiOutlinePlus } from 'react-icons/ai';
import { HiOutlineSearch } from 'react-icons/hi';
import SideDrawer from './BrandSideDrawer/BrandSideDrawer';
import ProductQuickCards from '../../../../common/ProductCard/ProductQuickCards';

import CustomSpinner from '../../../../common/CustomSpinner';
import {
  deleteBrandApi,
  getAllBrandApi,
} from '../../../../services/api/brand-api';
import {
  getAllBrandPending,
  getAllBrandSuccess,
  getAllBrandFail,
} from '../../../../services/slice/getAllBrandSlice';

import {
  deleteBrandPending,
  deleteBrandSuccess,
  deleteBrandFail,
} from '../../../../services/slice/deleteBrandSlice';
import { useNavigate } from 'react-router';

const Brand = () => {
  const navigate = useNavigate();
  // const [searchText, setSearchText] = useState('');
  const {
    getAllBrandLoading,
    brands,
    // getAllBrandError,
  } = useSelector(state => state.getAllBrand);
  const {
    // deleteBrandSuccessful,
    deleteBrandIsLoading,
    // deleteBrandError,
    // deleteBrandMessage,
  } = useSelector(state => state.deleteBrand);

  const [openPanel, setOpenPanel] = useState(false);
  const [typeOfPanel, setTypeOfPanel] = useState('');
  const [dataForPanel, setDataForPanel] = useState([]);

  const [searchInput, setSearchInput] = useState('');
  const dispatch = useDispatch();
  const toast = useToast();
  // const setSeacrhText = val => {
  //   setSearchText(val);
  // };

  const fetchAllBrands = async () => {
    dispatch(getAllBrandPending());
    try {
      const getAllBrands = await getAllBrandApi();
      dispatch(getAllBrandSuccess(getAllBrands));
    } catch (error) {
      if (error.message === "Request failed with status code 401") {
        navigate('/signin', { replace: true })
      }
      dispatch(getAllBrandFail(error));
    }
  };

  const openSidePanel = (data = [], type) => {
    

    if (openPanel) {
      fetchAllBrands();
    }
    setOpenPanel(!openPanel);
    setTypeOfPanel(type);
    setDataForPanel(data);
  };

  const filteredData = brands.filter(element => {
    const { brand_id, brand_name } = element;

    return (
      brand_name.toLowerCase().indexOf(searchInput.toLowerCase()) >= 0 ||
      brand_id.toString().indexOf(searchInput) >= 0
    );
  });

  const deleteBrand = async deleteData => {
    dispatch(deleteBrandPending());
    try {
      const deleteBrand = await deleteBrandApi({
        brand_id: deleteData.brand_id,
      }, sessionStorage.getItem('session_id'));

      dispatch(deleteBrandSuccess(deleteBrand));
      toast({
        title: `${deleteBrand}`,
        position: 'top-right',
        status: 'success',
        isClosable: true,
      });

      fetchAllBrands();
    } catch (error) {
      if (error.message === "Request failed with status code 401") {
        navigate('/signin', { replace: true })
      }
      dispatch(deleteBrandFail(error));
      toast({
        title: `${error}`,
        position: 'top-right',
        status: 'error',
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchAllBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex m={{ base: 3, md: 6 }} direction={['column']}>
      <Flex direction="row" justifyContent={'space-between'}>
        <Heading color={useColorModeValue('gray.700', 'white')}>
          All Brands
        </Heading>
        <Button
          variant={'outline'}
          rightIcon={
            <AiOutlinePlus color={useColorModeValue('#ae0000', 'white')} />
          }
          rounded="full"
          onClick={() => openSidePanel([], 'add')}
          color={useColorModeValue('gray.700', 'white')}
        >
          <Text fontWeight={'bold'}>Add Brand</Text>
        </Button>
      </Flex>

      <Divider marginY={{ base: 3, md: 5 }} />
      <InputGroup w={{ base: 'full', md: 'md' }} marginY={3}>
        <InputRightElement>
          <IconButton
            color="#ae0000"
            aria-label="Call Segun"
            size="sm"
            borderRadius={'full'}
            icon={<HiOutlineSearch />}
          />
        </InputRightElement>
        <Input
          id="searchData"
          placeholder="search here.."
          name="searchData"
          type={'text'}
          autoComplete="current-searchData"
          required
          variant={'flushed'}
          onChange={event => setSearchInput(event.target.value)}
        />
      </InputGroup>

      <Flex
        direction={{ base: 'column', md: 'row' }}
        justifyContent="flex-start"
        wrap={'wrap'}
      >
        {(getAllBrandLoading || deleteBrandIsLoading) && (
          <AbsoluteCenter>
            <CustomSpinner />
          </AbsoluteCenter>
        )}
        {filteredData.map((element, index) => {
          return (
            <ProductQuickCards
              key={index + element.id}
              action={element => openSidePanel(element, 'edit')}
              data={element}
              type={'brand'}
              deleteProductCard={element => deleteBrand(element)}
            />
          );
        })}
      </Flex>

      {openPanel && (
        <SideDrawer
          isOpen={openPanel}
          onClose={() => openSidePanel()}
          type={typeOfPanel}
          data={dataForPanel}
        />
      )}
    </Flex>
  );
};

export default Brand;

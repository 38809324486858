import {
  Box,
  Container,
  SimpleGrid,
  useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';
import { Stat } from './Stat';

const QuickCards = props => {
  const stats = props.data;

  const data = stats.slice(0, 3);
  const boxShadow = useColorModeValue('dark-lg', 'outline');

  return (
    <Box
      as="section"
      py={{
        base: '4',
        md: '8',
      }}
    >
      <Container maxW="container.xl">
      
        <SimpleGrid
          columns={{
            base: 1,
            md: 3,
          }}
          spacing={{
            base: '5',
            md: '6',
          }}
        >
        {props.type === 'product' ? (
          <React.Fragment>
          {data.map(element => {
            return (
              <Stat
                    cursor={'pointer'}
                    boxShadow={boxShadow}
                    key={element.product_id + element.product_name}
                    label={element.product_name}
                    value={element.product_id}
                    data={element}
                    type={props.type}
                  />)
              })}{' '}
              </React.Fragment>
              ) : (
                <React.Fragment>
                
                {data.map((element) => {
                  
                  return (
                    <Stat
                    cursor={'pointer'}
                    boxShadow={boxShadow}
                    key={element.category_level_id + element.category_name}
                    label={element.category_name}
                    value={element.category_level_id}
                    data={element}
                    type={props.type}
                    />
                    )
                  })}
                  </React.Fragment>
                  )}
                  </SimpleGrid>
                  
      </Container>
    </Box>
  );
};
export default QuickCards;

import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
} from '@chakra-ui/react';

const CustomAccordion = ({ title, contentText }) => {
  return (
    <Box paddingBottom={10}>
      <Accordion maxW={{ base: 200, md: 500, lg: 750 }} >
        <AccordionItem key={1} data-aos="fade-up"
          data-aos-anchor-placement="top-bottom" data-aos-delay="50" >
          <h2>
            <AccordionButton>
              <Box flex="1" padding={2} textAlign="left">
                <Text as='b' fontSize='xl'>Introduction to IT & Computer Science</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Box flex="1" padding={2} textAlign="left">
              <ul>
                <li><Text as='samp' fontSize={'lg'}>Overview of IT industries and careers</Text></li>
                <li><Text as='samp' fontSize={'lg'}>Introduction to Computer Science fundamentals</Text></li>
                <li><Text as='samp' fontSize={'lg'}>Software vs. Hardware: Basic Concepts</Text></li>
                <li><Text as='samp' fontSize={'lg'}>Digital literacy and cybersecurity essentials</Text></li>
              </ul>
            </Box>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem key={2} data-aos="fade-up"
          data-aos-anchor-placement="top-bottom" data-aos-delay="120">
          <h2>
            <AccordionButton>
              <Box flex="1" padding={2} textAlign="left">
                <Text as='b' fontSize='xl'>Programming Fundamentals</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Box flex="1" padding={2} textAlign="left">
              <ul>
                <li><Text as='samp' fontSize={'lg'}>Introduction to Programming Languages (e.g., Python, Java, C++)</Text></li>
                <li><Text as='samp' fontSize={'lg'}>Control Structures (Conditional Statements, Loops)</Text></li>
                <li><Text as='samp' fontSize={'lg'}>Variables, Data Types, and Operators</Text></li>
                <li><Text as='samp' fontSize={'lg'}>Debugging and Error Handling</Text></li>
              </ul>
            </Box>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem key={2} data-aos="fade-up"
          data-aos-anchor-placement="top-bottom" data-aos-delay="120">
          <h2>
            <AccordionButton>
              <Box flex="1" padding={2} textAlign="left">
                <Text as='b' fontSize='xl'>Data Structures & Algorithms</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Box flex="1" padding={2} textAlign="left">
              <ul>
                <li><Text as='samp' fontSize={'lg'}>Arrays, Lists, Stacks, Queues, and Trees</Text></li>
                <li><Text as='samp' fontSize={'lg'}>Searching and Sorting Algorithms</Text></li>
                <li><Text as='samp' fontSize={'lg'}>Time and Space Complexity</Text></li>
                <li><Text as='samp' fontSize={'lg'}>Recursion and Dynamic Programming</Text></li>
                <li><Text as='samp' fontSize={'lg'}>Graph Theory</Text></li>
              </ul>
            </Box>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem key={2} data-aos="fade-up"
          data-aos-anchor-placement="top-bottom" data-aos-delay="120">
          <h2>
            <AccordionButton>
              <Box flex="1" padding={2} textAlign="left">
                <Text as='b' fontSize='xl'>Database Management Systems</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Box flex="1" padding={2} textAlign="left">
              <ul>
                <li><Text as='samp' fontSize={'lg'}>Introduction to Databases: SQL and NoSQL</Text></li>
                <li><Text as='samp' fontSize={'lg'}>Entity-Relationship Model</Text></li>
                <li><Text as='samp' fontSize={'lg'}>Normalization and Database Design</Text></li>
                <li><Text as='samp' fontSize={'lg'}>Querying with SQL</Text></li>
                <li><Text as='samp' fontSize={'lg'}>Database Transactions, Backup, and Security</Text></li>
              </ul>
            </Box>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem key={2} data-aos="fade-up"
          data-aos-anchor-placement="top-bottom" data-aos-delay="120">
          <h2>
            <AccordionButton>
              <Box flex="1" padding={2} textAlign="left">
                <Text as='b' fontSize='xl'>Software Engineering & Development Lifecycle</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Box flex="1" padding={2} textAlign="left">
              <ul>
                <li><Text as='samp' fontSize={'lg'}>Introduction to Software Development Models (Agile, Waterfall, etc.)</Text></li>
                <li><Text as='samp' fontSize={'lg'}>Requirements Gathering and Analysis</Text></li>
                <li><Text as='samp' fontSize={'lg'}>Version Control (e.g., Git and GitHub)</Text></li>
                <li><Text as='samp' fontSize={'lg'}>Unit Testing and Test-Driven Development</Text></li>
                <li><Text as='samp' fontSize={'lg'}>Continuous Integration/Continuous Deployment (CI/CD)</Text></li>
              </ul>
            </Box>
          </AccordionPanel>
        </AccordionItem>

      </Accordion>
    </Box>
  );
};

export default CustomAccordion;

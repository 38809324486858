import axios from 'axios';
import {
  GET_ALL_SPECIALIZATION_API,
  GET_SPECIALIZATION_BY_ID_API,
  ADD_SPECIALIZATION_API,
  UPDATE_SPECIALIZATION_API,
  DELETE_SPECIALIZATION_API
} from './generic-api';

export const getAllSpecializationApi = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(GET_ALL_SPECIALIZATION_API, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
        },
      });

      resolve(response.data);
    } catch (error) {
      reject(error.message);
    }
  });
};

export const getSpecializationByIdApi = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(GET_SPECIALIZATION_BY_ID_API + payload + "/select", {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
        },
      });


      resolve(response.data);
    } catch (error) {
      reject(error.message);
    }
  });
}

export const addSpecializationApi = payload => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(ADD_SPECIALIZATION_API, payload, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
        },
      });

      resolve(response.data);
    } catch (error) {
      reject(error.message);
    }
  });
};
export const updateSpecializationApi = payload => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.patch(UPDATE_SPECIALIZATION_API, payload, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
          'Access-Control-Allow-Origin': '*',
          withCredentials: false,
        },
      });

      resolve(response.data);
      // }
    } catch (error) {

      reject(error.message);
    }
  });
};

export const deleteSpecializationApi = payload => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(DELETE_SPECIALIZATION_API, payload, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
          'Content-type': 'application/json',
        },
      });
      resolve(response.data);
    } catch (error) {
      reject(error.message);
    }
  });
};

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  deleteSpecializationSuccessful: false,
  deleteSpecializationIsLoading: false,
  deleteSpecializationError: "",
  deleteSpecializationMessage:"",
};

const deleteSpecializationSlice = createSlice({
  name: "deleteSpecialization",
  initialState,
  reducers: {
    deleteSpecializationPending: (state) => {
      state.deleteSpecializationIsLoading = true;
    },
    deleteSpecializationSuccess: (state, { payload }) => {
      state.deleteSpecializationIsLoading = false;
      state.addSpecializationSuccessful = true;
      state.addSpecializationError = "";
      state.addSpecializationMessage = payload;
    },
    deleteSpecializationFail: (state, { payload }) => {
      state.deleteSpecializationIsLoading = false;
      state.addSpecializationError = payload;
    },
  },
});

const { reducer, actions } = deleteSpecializationSlice;
export const { deleteSpecializationPending, deleteSpecializationSuccess, deleteSpecializationFail } =
  actions;

export default reducer;

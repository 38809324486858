import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addUserSuccessful: false,
  addUserLoading: false,
  addUserError: "",
  addUserMessage:"",
};

const addUserSlice = createSlice({
  name: "addUser",
  initialState,
  reducers: {
    addUserPending: (state) => {
      state.addUserLoading = true;
    },
    addUserSuccess: (state, { payload }) => {
      state.addUserLoading = false;
      state.addUserSuccessful = true;
      state.addUserError = "";
      state.addUserMessage = payload;
    },
    addUserFail: (state, { payload }) => {
      state.addUserLoading = false;
      state.addUserError = payload;
    },
  },
});

const { reducer, actions } = addUserSlice;
export const { addUserPending, addUserSuccess, addUserFail } =
  actions;

export default reducer;

import React from 'react';
import {
  chakra,
  Box,
  Flex,
  useColorModeValue,
  Icon,
  Stack,
} from '@chakra-ui/react';

import { RiFocus3Line, RiAwardFill, RiSecurePaymentLine } from 'react-icons/ri';

const Features = () => {
  const Feature = props => {
    return (
      <Flex>
        <Flex shrink={0}>
          <Flex
            alignItems="center"
            justifyContent="center"
            h={12}
            w={12}
            rounded="md"
            bg={useColorModeValue('brand.500')}
            color={useColorModeValue('#ae0000')}
          >
            <Icon
              boxSize={6}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              {props.icon}
            </Icon>
          </Flex>
        </Flex>
        <Box ml={4}>
          <chakra.dt
            fontSize="lg"
            fontWeight="medium"
            lineHeight="6"
            color={useColorModeValue('gray.900')}
          >
            {props.title}
          </chakra.dt>
          <chakra.dd mt={2} color={useColorModeValue('gray.500', 'gray.400')}>
            {props.children}
          </chakra.dd>
        </Box>
      </Flex>
    );
  };
  return (
    <Flex
      bg={useColorModeValue('#F9FAFB', 'gray.600')}
      p={{ base: 5, md: 20 }}
      borderRadius={'md'}
      w="auto"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        py={{ base: 6, md: 12 }}
        bg={useColorModeValue('white', 'gray.800')}
        rounded="xl"
      >
        <Box maxW="7xl" mx="auto" px={{ base: 2, lg: 8 }}>
          <Box textAlign={{ lg: 'center' }}>
            <chakra.h2
              color={useColorModeValue('#ae0000')}
              fontWeight="semibold"
              textTransform="uppercase"
              letterSpacing="wide"
            >
              Redhood.ai
            </chakra.h2>
            <chakra.p
              mt={2}
              fontSize={{ base: '3xl', sm: '4xl' }}
              lineHeight="8"
              fontWeight="extrabold"
              letterSpacing="tight"
              color={useColorModeValue('gray.900')}
            >
              Why us?
            </chakra.p>
            <chakra.p
              mt={4}
              maxW="2xl"
              fontSize="xl"
              mx={{ lg: 'auto' }}
              color={useColorModeValue('gray.500', 'gray.400')}
            >
              Professional IT courses which includes Classroom, Online and
              Corporate training. Let our highly experienced industry experts
              groom you for the better tomorrow.
            </chakra.p>
          </Box>

          <Box mt={10}>
            <Stack
              spacing={{ base: 10, md: 0 }}
              display={{ md: 'grid' }}
              gridTemplateColumns={{ md: 'repeat(2,1fr)' }}
              gridColumnGap={{ md: 8 }}
              gridRowGap={{ md: 10 }}
            >
              <Feature
                title="Reasonable price."
                icon={
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                  />
                }
              >
                The prices are significantly low then any of our competitors out
                there. We mainly focus on the offline classes so that we can
                also provide the materials and resouces and can interact with
                students, all at the same price.
              </Feature>

              <Feature title="Focused Mentors" icon={<RiFocus3Line />}>
                Our mentors are not specifically teachers, but they are actually
                well skilled and have years of experince working with the IT
                industry.
              </Feature>

              <Feature
                title="Track your class syllabus "
                icon={
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 10V3L4 14h7v7l9-11h-7z"
                  />
                }
              >
                Missed your class? No worries we provide you the syllabus
                completed and in progress, with recorded sessions.
              </Feature>

              <Feature
                title="Resources and materials availability"
                icon={
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                  />
                }
              >
                Being an offline presence is awesome. But we understand the need
                of notes and other resources and we provide the resources and
                projects.
              </Feature>
              <Feature
                title="Test your skills and get Certified"
                icon={<RiAwardFill />}
              >
                We dont just give out the certificate at the end of the month,
                we will make sure you gained some basic blocks of the technology
                in which you are enrolled so as to make you survive in this
                cruel IT industry.
              </Feature>
              <Feature title="Secure Payment" icon={<RiSecurePaymentLine />}>
                You can pay directly through our web-app or offline at our office. Both are safe and can be traced even after a decade.
              </Feature>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};
export default Features;

import axios from 'axios';
import {
    ADD_MEDIA_IMAGES_API, DELETE_MEDIA_IMAGES_API, GET_ALL_MEDIA_IMAGES_API,
    GET_MEDIA_BY_ATTRIBUTE_API
} from './generic-api';

export const getAllMediaApi = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(GET_ALL_MEDIA_IMAGES_API, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
        },
      });
      
      resolve(response.data);
    } catch (error) {
      reject(error.message);
    }
  });
};

export const getMediaByAttributeIdApi = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(GET_MEDIA_BY_ATTRIBUTE_API+payload+"/select", {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
        },
      });
      
      
      resolve(response.data);
    } catch (error) {
      reject(error.message);
    }
  });
}

export const addMediaImagesApi = payload => {
  console.log("🚀 ~ file: media-api.js:40 ~ addMediaImagesApi ~ payload:", payload)
  console.log("🚀 ~ file: media-api.js:44 ~ returnnewPromise ~ ADD_MEDIA_IMAGES_API:", ADD_MEDIA_IMAGES_API)
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(ADD_MEDIA_IMAGES_API, payload, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
        },
      });
      
      console.log("🚀 ~ file: media-api.js:50 ~ returnnewPromise ~ response:", response)
      resolve(response.data);
    } catch (error) {
      console.log("🚀 ~ file: media-api.js:52 ~ returnnewPromise ~ error:", error)
      reject(error.message);
    }
  });
};

// export const updateAttributeApi = payLoadData => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const response = await axios.patch(UPDATE_ATTRIBUTE_API, payLoadData, {
//         headers: {
//           session_id: sessionStorage.getItem('session_id'),
//           'Access-Control-Allow-Origin': '*',
//           withCredentials: false,
//         },
//       });

//       resolve(response.data);
//       // }
//     } catch (error) {
      
//       reject(error.message);
//     }
//   });
// };

export const deleteMediaImagesApi = payLoadData => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(DELETE_MEDIA_IMAGES_API+payLoadData+'/delete', {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
          'Content-type': 'application/json',
        },
      });
      resolve(response);
    } catch (error) {
      reject(error.message);
    }
  });
};

import { AspectRatio, Box, Image, Link, Skeleton, Text } from '@chakra-ui/react'
import * as React from 'react'
import { useNavigate } from 'react-router';
import { colorConfig } from '../../../colorConfig';


export const CategoryCard = (props) => {
  const navigate = useNavigate();
  const { category, rootProps } = props
  console.log("🚀 ~ file: CatetgoryCard.jsx:6 ~ CategoryCard ~ category", category)
  return (
    <AspectRatio ratio={1/1}>
    <Box border={"1px solid red"} onClick={() => navigate(`/allcourses/${category.category_level_id}`)} position="relative" key={category.category_name} borderRadius="xl" overflow="hidden" {...rootProps}>
      <Link>
        {/*<AspectRatio ratio={1 / 1}>
          <Image src={'https://images.unsplash.com/photo-1477039181047-efb4357d01bd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'} 
          alt={category.category_name} fallback={<Skeleton />} />
  </AspectRatio>*/}
        <Box
          position="absolute"
          inset="0"
          bgGradient={`linear(to-b, ${colorConfig.primary} , ${colorConfig.secondary})`}
         
          boxSize="full"
        />
        <Box position="relative"  width="full" textAlign="center">
          <Text color={colorConfig.textColor} fontSize="lg" fontWeight="semibold">
            {category.category_name}
          </Text>
        </Box>
      </Link>
    </Box>
    </AspectRatio>
  )
}

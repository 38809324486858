import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import {
  AbsoluteCenter,
  Button,
  Divider,
  Flex,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { AiOutlinePlus } from 'react-icons/ai';
import { HiOutlineSearch } from 'react-icons/hi';
import SideDrawer from './CategorySideDrawer/CategorySideDrawer';
import ProductQuickCards from '../../../../common/ProductCard/ProductQuickCards';

import CustomSpinner from '../../../../common/CustomSpinner';
import {
  deleteCategoryApi,
  getAllCategoryApi,
} from '../../../../services/api/category-api';
import {
  getAllCategoryPending,
  getAllCategorySuccess,
  getAllCategoryFail,
} from '../../../../services/slice/getAllCategorySlice';

import {
  deleteCategoryPending,
  deleteCategorySuccess,
  deleteCategoryFail,
} from '../../../../services/slice/deleteCatagorySlice';

const Category = () => {
  // const [searchText, setSearchText] = useState('');
  const {
    getAllCategoryLoading,
    categories,
    // getAllCategoryError,
  } = useSelector(state => state.category);
  const {
    // deleteCategorySuccessful,
    deleteCategoryIsLoading,
    // deleteCategoryError,
    // deleteCategoryMessage,
  } = useSelector(state => state.deleteCategory);

  const [openPanel, setOpenPanel] = useState(false);
  const [typeOfPanel, setTypeOfPanel] = useState('');
  const [dataForPanel, setDataForPanel] = useState([]);

  const [searchInput, setSearchInput] = useState('');
  const dispatch = useDispatch();
  const toast = useToast();
  // const setSeacrhText = val => {
  //   setSearchText(val);
  // };

  const fetchAllCategories = async () => {
    dispatch(getAllCategoryPending());
    try {
      const getAllCategories = await getAllCategoryApi();
      dispatch(getAllCategorySuccess(getAllCategories));
    } catch (error) {
      dispatch(getAllCategoryFail(error));
    }
  };

  const openSidePanel = (data = [], type) => {

    if (openPanel) {
      fetchAllCategories();
    }
    setOpenPanel(!openPanel);
    setTypeOfPanel(type);
    setDataForPanel(data);
  };

  const filteredData = categories.filter(element => {
    const { parent_group_id, category_name, category_level_id } = element;

    return (
      category_name.toLowerCase().indexOf(searchInput.toLowerCase()) >= 0 ||
      parent_group_id.toString().indexOf(searchInput) >= 0 ||
      category_level_id.toString().indexOf(searchInput) >= 0
    );
  });

  const deleteCategory = async deleteData => {
    
    dispatch(deleteCategoryPending());
    try {
      const deleteCategory = await deleteCategoryApi(JSON.stringify({"category_level_id": deleteData.category_level_id}));

      dispatch(deleteCategorySuccess(deleteCategory));
      toast({
        title: `${deleteCategory}`,
        position: 'top-right',
        status: 'success',
        isClosable: true,
      });

      fetchAllCategories();
    }
    // try {
    //   const res = await fetch(
    //     'http://d64b-2405-201-d01b-58c3-7d7f-4555-93c6-13ae.ngrok.io/ecommerce/category/delete',
    //     { method: 'delete' },
    //     {
    //       headers: {
    //         'Content-Type': 'application/json',
    //         session_id: sessionStorage.getItem('session_id'),
    //       },
    //     },
    //     { body: JSON.stringify(deleteData.category_level_id) }
    //   );
    //   const data = await res.json();
    //   const result = {
    //     status: res.status + '-' + res.statusText,
    //     headers: { 'Content-Type': res.headers.get('Content-Type') },
    //     data: data,
    //   };
    // } 
    catch (error) {
      
      dispatch(deleteCategoryFail(error));
      toast({
        title: `${error}`,
        position: 'top-right',
        status: 'error',
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchAllCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex m={{ base: 3, md: 6 }} direction={['column']}>
      <Flex direction="row" justifyContent={'space-between'}>
        <Heading color={useColorModeValue('gray.700', 'white')}>
          All Categories
        </Heading>
        <Button
          variant={'outline'}
          rightIcon={
            <AiOutlinePlus color={useColorModeValue('#ae0000', 'white')} />
          }
          rounded="full"
          onClick={() => openSidePanel([], 'add')}
          color={useColorModeValue('gray.700', 'white')}
        >
          <Text fontWeight={'bold'}>Add Category</Text>
        </Button>
      </Flex>

      <Divider marginY={{ base: 3, md: 5 }} />
      <InputGroup w={{ base: 'full', md: 'md' }} marginY={3}>
        <InputRightElement>
          <IconButton
            color="#ae0000"
            aria-label="Call Segun"
            size="sm"
            borderRadius={'full'}
            icon={<HiOutlineSearch />}
          />
        </InputRightElement>
        <Input
          id="searchData"
          placeholder="search here.."
          name="searchData"
          type={'text'}
          autoComplete="current-searchData"
          required
          variant={'flushed'}
          onChange={event => setSearchInput(event.target.value)}
        />
      </InputGroup>

      <Flex
        direction={{ base: 'column', md: 'row' }}
        justifyContent="flex-start"
        wrap={'wrap'}
      >
        {(getAllCategoryLoading || deleteCategoryIsLoading) && (
          <AbsoluteCenter>
            <CustomSpinner />
          </AbsoluteCenter>
        )}
        {filteredData.map((element, index) => {
          return (
            <ProductQuickCards
              key={index + element.id}
              action={element => openSidePanel(element, 'edit')}
              data={element}
              type={'category'}
              deleteProductCard={element => deleteCategory(element)}
            />
          );
        })}
      </Flex>

      {openPanel && (
        <SideDrawer
          isOpen={openPanel}
          onClose={() => openSidePanel()}
          type={typeOfPanel}
          data={dataForPanel}
        />
      )}
    </Flex>
  );
};

export default Category;

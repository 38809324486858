import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allAttributes: [],
  getAllAttributesLoading: false,
  getAllAttributesError: "",
};

const getAllAttributesSlice = createSlice({
  name: "Attributes",
  initialState,
  reducers: {
    getAllAttributesPending: (state) => {
      state.getAllAttributesLoading = true;
    },
    getAllAttributesSuccess: (state, { payload }) => {
      state.getAllAttributesLoading = false;
      state.allAttributes = payload;
      state.getAllAttributesError = "";
    },
    getAllAttributesFail: (state, { payload }) => {
      state.getAllAttributesLoading = false;
      state.getAllAttributesError = payload;
    },
  },
});

const { reducer, actions } = getAllAttributesSlice;
export const { getAllAttributesPending, getAllAttributesSuccess, getAllAttributesFail } =
  actions;

export default reducer;

import {
  Menu,
  MenuDivider,
  MenuItem,
  MenuList,
  useColorModeValue,
} from '@chakra-ui/react'
import * as React from 'react'
import { useNavigate, useLocation } from 'react-router';
import { AccountSwitcherButton } from './AccountSwitcherButton'
import { Link } from 'react-router-dom';

export const AccountSwitcher = () => {

  const navigate = useNavigate();
  const location = useLocation();
  console.log("🚀 ~ file: AccountSwitcher.jsx:20 ~ AccountSwitcher ~ location:", location.pathname);
  
  const logoutClicked = () => {

    sessionStorage.clear();
    localStorage.clear();
    navigate('/', { replace: true });
    window.location.reload();
  }

  return (
    <Menu>
      <AccountSwitcherButton />
      <MenuList shadow="lg" py="4" color={useColorModeValue('gray.600', 'gray.200')} px="3">
        {/* <Text fontWeight="medium" mb="2">
          
  </Text>*/}

        <MenuItem fontWeight="semibold" rounded="md" >
          <Link to={{pathname : `/user-profile/${sessionStorage.getItem('user_id')}`, state: location?.pathname }} >
            Go to profile
          </Link>
        </MenuItem>

        <MenuItem fontWeight="semibold" rounded="md" >
          <Link to={{pathname : `/`, replace: true}} >
            Go to store
          </Link>
        </MenuItem>

        <MenuDivider />
        <MenuItem rounded="md" onClick={logoutClicked}>Logout</MenuItem>
      </MenuList>
    </Menu>
  )
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import SideNav from '../../../common/SideNavShell/SideNav';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
const Dashboard = () => {
  const navigate = useNavigate();
  const { isAuth, userGroup } = useSelector(state => state.login);
  useEffect(() => {
    if (isAuth === true && userGroup !== 1) {
      navigate('/admindashboard', { replace: true });
    } else if (isAuth === false) {
      navigate('/signin', { replace: true });
    }
  }, []);
  return (
    <div>
      <SideNav />
    </div>
  );
};

export default Dashboard;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  wishList: [],
  getWishlistByUserIdLoading: false,
  getWishlistByUserIdError: "",
};

const getWishlistByUserIdSlice = createSlice({
  name: "wishList",
  initialState,
  reducers: {
    getWishlistByUserIdPending: (state) => {
      state.getWishlistByUserIdLoading = true;
    },
    getWishlistByUserIdSuccess: (state, { payload }) => {
      state.getWishlistByUserIdLoading = false;
      state.userDetail = payload;
      state.getWishlistByUserIdError = "";
    },
    getWishlistByUserIdFail: (state, { payload }) => {
      state.getWishlistByUserIdLoading = false;
      state.getWishlistByUserIdError = payload;
    },
  },
});

const { reducer, actions } = getWishlistByUserIdSlice;
export const { getWishlistByUserIdPending, getWishlistByUserIdSuccess, getWishlistByUserIdFail } =
  actions;

export default reducer;

/* eslint-disable no-unused-vars */
import {
  Button,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  Input,
  Progress,
  Select,
  Stack,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineSave, AiOutlineReload } from 'react-icons/ai';

import {
  getAllGroupApi,
} from '../../../../../services/api/users-api';
import {
  getAllGroupsPending,
  getAllGroupsSuccess,
  getAllGroupsFail,
} from '../../../../../services/slice/getAllGroupSlice';


import {
  addCategoryPending,
  addCategorySuccess,
  addCategoryFail,
} from '../../../../../services/slice/addCategorySlice';

import {
  updateCategoryPending,
  updateCategorySuccess,
  updateCategoryFail,
} from '../../../../../services/slice/updateCategorySlice';

import {
  addCategoryApi,
  updateCategoryApi,
} from '../../../../../services/api/category-api';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';

const SideDrawer = props => {
  
  const {
    register,
    handleSubmit,
    // eslint-disable-next-line no-unused-vars
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();

  const { addCategoryLoading } = useSelector(state => state.addCategory);

  const { getAllGroupsLoading, groups } = useSelector(state => state.getAllGroup);

  const {
    updateCategorySuccessful,
    updateCategoryIsLoading,
  } = useSelector(state => state.updateCategory);

  const toast = useToast();

  const fetchAllGroup = async () => {
    dispatch(getAllGroupsPending());
    try {
      const getAllCategories = await getAllGroupApi();
      dispatch(getAllGroupsSuccess(getAllCategories));
    } catch (error) {
      dispatch(getAllGroupsFail(error));
    }
  };

  const onSubmit = async data => {
    
    

    dispatch(addCategoryPending());

    try {
      const addCategory = await addCategoryApi(data);

      await dispatch(addCategorySuccess(addCategory));
      toast({
        title: `Add Category ${addCategory}`,
        position: 'top-right',
        status: 'success',
        isClosable: true,
      });
      props.onClose();
    } catch (error) {
      
      dispatch(addCategoryFail(error));
      toast({
        title: `${error}`,
        position: 'top-right',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const onEditSubmit = async data => {
    data.category_level_id = props.data.category_level_id;
    
    

    dispatch(updateCategoryPending());

    try {
      const updateProduct = await updateCategoryApi(data);
      dispatch(updateCategorySuccess(updateProduct), () => {
        console.log(updateCategorySuccessful);
      });

      toast({
        title: `${updateProduct}`,
        position: 'top-right',
        status: 'success',
        isClosable: true,
      });

      props.onClose();
    } catch (error) {
      dispatch(updateCategoryFail(error));
      toast({
        title: `${error}`,
        position: 'top-right',
        status: 'error',
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchAllGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const color = useColorModeValue('#ae0000', 'white');
  return (
    <>
      <Drawer onClose={props.onClose} isOpen={props.isOpen} size={'lg'}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            {props.type === 'add' ? 'Add Category' : 'Edit Category'}
          </DrawerHeader>
          <Drawer />
          <DrawerBody>
            <form
              onSubmit={
                props.type === 'add'
                  ? handleSubmit(onSubmit)
                  : handleSubmit(onEditSubmit)
              }
            >
              <Stack spacing="6">
                <FormControl id="category_level_id">
                  <FormLabel>Category Level ID</FormLabel>
                  <Input
                    name="category_level_id"
                    isDisabled
                    {...register('category_level_id', { required: false })}
                    type="text"
                    autoComplete="text"
                    defaultValue={props.data.category_level_id}
                  />
                </FormControl>
                <FormControl id="category_name">
                  <FormLabel>Category Name</FormLabel>
                  <Input
                    name="category_name"
                    {...register('category_name', { required: true })}
                    type="text"
                    autoComplete="text"
                    required
                    defaultValue={props.data.category_name}
                  />
                </FormControl>
                {/*<FormControl id="parent_group_id">
                  <FormLabel>Parent Group Id</FormLabel>
                  <Input
                    name="parent_group_id"
                    {...register('parent_group_id', { required: true })}
                    type="number"
                   
                    required
                    placeholder="number only"
                    defaultValue={props.data.parent_group_id}
                  />
            </FormControl>*/}
                <FormControl id="parent_group_id" isRequired>
                <FormLabel>Parent Group Id</FormLabel>

                    <Select
                      {...register('parent_group_id', { required: true })}
                      placeholder="Please select a parent group"
                      defaultValue={props.data.parent_group_id}
                    >
                      {groups.map((element, item) => {
                        return (
                          <option
                            key={item + element.id}
                            value={element.id}
                          >
                            {element.name}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                <FormControl id="categoryStatus">
                  <Stack spacing={5} direction="row">
                    <Checkbox
                      size="md"
                      colorscheme="green"
                      {...register('category_status', { required: true })}
                      defaultChecked={props.data.category_status}
                    >
                      status
                    </Checkbox>
                  </Stack>
                </FormControl>
                <Button
                  type="submit"
                  leftIcon={
                    addCategoryLoading || updateCategoryIsLoading ? (
                      <Progress size="xs" isIndeterminate />
                    ) : (
                      <AiOutlineSave
                        color={color}
                      />
                    )
                  }
                  width='full'
                >
                  Submit
                </Button>
                <Button
                  variant={'link'}
                  leftIcon={
                    <AiOutlineReload
                      color={color}
                    />
                  }
                  width='full'
                >
                  {props.type === 'add' ? 'Reset' : 'Cancel'}
                </Button>
              </Stack>
            </form>
            {addCategoryLoading && <Progress size="xs" isIndeterminate />}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default SideDrawer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateCategorySuccessful: false,
  updateCategoryIsLoading: false,
  updateCategoryError: "",
  updateCategoryMessage:"",
};

const updateCategorySlice = createSlice({
  name: "updateCategory",
  initialState,
  reducers: {
    updateCategoryPending: (state) => {
      state.updateCategoryIsLoading = true;
    },
    updateCategorySuccess: (state, { payload }) => {
      state.updateCategoryIsLoading = false;
      state.updateCategorySuccessful = true;
      state.updateCategoryError = "";
      state.updateCategoryMessage = payload;
    },
    updateCategoryFail: (state, { payload }) => {
    
      state.updateCategoryIsLoading = false;
      state.updateCategoryMessage = payload;
      state.updateCategoryError = true;
    },
  },
});

const { reducer, actions } = updateCategorySlice;
export const { updateCategoryPending, updateCategorySuccess, updateCategoryFail } =
  actions;

export default reducer;

/* eslint-disable react-hooks/rules-of-hooks */
import {
    Box,
    Button,
    Checkbox,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Progress,
    Select,
    Stack,
    Table,
    TableContainer,
    Tbody,
    Td,
    Textarea,
    Tfoot,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useToast
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineSave, AiOutlineReload } from 'react-icons/ai';

//   import {
//     addProductFail,
//     addProductPending,
//     addProductSuccess,
//   } from '../../services/slice/addProductSlice';

//   import {
//     updateProductPending,
//     updateProductSuccess,
//     updateProductFail,
//   } from '../../services/slice/updateProductSlice';

//   import {
//     addProductApi,
//     updateProductApi,
//   } from '../../services/api/product-api';
import { useForm } from 'react-hook-form';
import { colorConfig } from '../../../../../../colorConfig';

const SidePanel = props => {


    const { reset,
        register,
        handleSubmit,
        // eslint-disable-next-line no-unused-vars
        formState: { errors },
    } = useForm();

    const dispatch = useDispatch();

    // const {
    //   addProductLoading,
    //   // addProductSuccessful,
    //   // addProductError,
    //   addProductMessage,
    // } = useSelector(state => state.addProduct);

    // const {
    //   updateProductSuccessful,
    //   // updateProductIsLoading,
    //   // updateProductError,
    //   updateProductMessage,
    // } = useSelector(state => state.updateProduct);

    const toast = useToast();

    const onSubmit = async data => {


        //   dispatch(addProductPending());

        //   try {
        //     const addProduct = await addProductApi(data);
        //     console.log(
        //       '🚀 ~ file: SideDrawer.js ~ line 46 ~ addProduct',
        //       addProduct
        //     );
        //     dispatch(addProductSuccess('inserted'));
        //     toast({
        //       title: `Add Product ${addProductMessage}`,
        //       position: 'top-right',
        //       status: 'success',
        //       isClosable: true,
        //     })
        //     props.onClose();

        //   } catch (error) {

        //     dispatch(addProductFail(error.message));
        //     toast({
        //       title: `${addProductMessage}`,
        //       position: 'top-right',
        //       status: 'error',
        //       isClosable: true,
        //     })
        //     props.onClose();
        //   }
    };

    const onEditSubmit = async data => {

        //   dispatch(updateProductPending());

        //   try {
        //     const updateProduct = await updateProductApi(data);
        //     console.log(
        //       '🚀 ~ file: SideDrawer.js ~ line 89 ~ updateProduct',
        //       updateProduct
        //     );

        //     dispatch(updateProductSuccess('updated'));
        //     if (updateProductSuccessful) {
        //       toast({
        //         title: `${updateProductMessage}`,
        //         position: 'top-right',
        //         status: 'success',
        //         isClosable: true,
        //       })

        //     }
        //   } catch (error) {
        //     dispatch(updateProductFail(error));
        //     toast({
        //       title: `${error}`,
        //       position: 'top-right',
        //       status: 'error',
        //       isClosable: true,
        //     })

        //   }
    };

    // useEffect(() => {
    //     reset(props.data);
    // })

    return (
        <div>
            <Drawer onClose={props.onClose} isOpen={props.isOpen} size={'xl'}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerHeader>
                        {props.type === 'add' ? 'Add Feature' : 'Edit Feature'}
                    </DrawerHeader>
                    <Drawer />
                    <DrawerBody>
                        <form
                            onSubmit={
                                props.type === 'add'
                                    ? handleSubmit(onSubmit)
                                    : handleSubmit(onEditSubmit)
                            }
                        >
                            <Stack spacing="6">
                                <Flex direction={{ base: 'column', md: 'row' }} gap={4}>

                                    <FormControl id="feature_name">
                                        <FormLabel>Feature Name</FormLabel>
                                        <Input
                                            name="feature_name"
                                            isDisabled
                                            {...register('feature_name', { required: false })}
                                            type="text"
                                            autoComplete="text"
                                            defaultValue={props.data.feature_name}
                                        />
                                    </FormControl>
                                </Flex>
                                <Flex direction={{ base: 'column', md: 'row' }} gap={4}>

                                    <FormControl id="feature_description">
                                        <FormLabel>Feature Description</FormLabel>
                                        <Input
                                            name="feature_description"
                                            isDisabled
                                            {...register('feature_description', { required: false })}
                                            type="text"
                                            autoComplete="text"
                                            defaultValue={props.data.feature_description}
                                        />
                                    </FormControl>
                                </Flex>
                                
                                <Button
                                    type="submit"
                                    // leftIcon={ addProductLoading ? ( <Progress size="xs" isIndeterminate /> ) : (
                                    //     <AiOutlineSave
                                    //       color={useColorModeValue(colorConfig.primary, 'white')}
                                    //     />
                                    //   )
                                    // }
                                    width='full'
                                >
                                    Submit
                                </Button>
                                <Button
                                    type="submit"
                                    leftIcon={
                                        <AiOutlineReload
                                            color={useColorModeValue(colorConfig.primary, 'white')}
                                        />
                                    }
                                    width='full'
                                >
                                    {props.type === 'add' ? 'Reset' : 'Cancel'}
                                </Button>
                            </Stack>
                        </form>
                        {/*addProductLoading && <Progress size="xs" isIndeterminate />*/}
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </div>
    );
};

export default SidePanel;

/* eslint-disable no-unused-vars */
import {
    Box,
    HStack,
    Icon,
    Image,
    Link,
    Stack,
    Text,
    useColorModeValue as mode,
  } from '@chakra-ui/react'
  import * as React from 'react'
  import { FiGift } from 'react-icons/fi'
  
  export const CartProductMeta = (props) => {
    const { image, product_name, category_name, attribute } = props
    console.log("🚀 ~ file: CartProductMeta.js:17 ~ CartProductMeta ~ props:", props)
    return (
      <Stack direction="row" spacing="5" width="full">
        <Image
          rounded="lg"
          width="120px"
          height="120px"
          fit="contain"
          src={image}
          alt={product_name}
          draggable="false"
          loading="lazy"
        />
        <Box pt="4">
          <Stack spacing="0.5">
            <Text fontWeight="medium">{product_name}</Text>
            <Text color={mode('gray.600', 'gray.400')} fontSize="sm">
              {category_name} | {attribute}
    </Text>
          </Stack>
          
        </Box>
      </Stack>
    )
  }
  
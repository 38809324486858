import axios from 'axios';
import {
  GET_ALL_QUALIFICATION_API,
  GET_QUALIFICATION_BY_ID_API,
  ADD_QUALIFICATION_API,
  UPDATE_QUALIFICATION_API,
  DELETE_QUALIFICATION_API
} from './generic-api';

export const getAllQualificationApi = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(GET_ALL_QUALIFICATION_API, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
        },
      });

      resolve(response.data);
    } catch (error) {
      reject(error.message);
    }
  });
};

export const getQualificationByIdApi = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(GET_QUALIFICATION_BY_ID_API + payload + "/select", {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
        },
      });


      resolve(response.data);
    } catch (error) {
      reject(error.message);
    }
  });
}

export const addQualificationApi = payload => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(ADD_QUALIFICATION_API, payload, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
        },
      });

      resolve(response.data);
    } catch (error) {
      reject(error.message);
    }
  });
};
export const updateQualificationApi = payload => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.patch(UPDATE_QUALIFICATION_API, payload, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
          'Access-Control-Allow-Origin': '*',
          withCredentials: false,
        },
      });

      resolve(response.data);
      // }
    } catch (error) {

      reject(error.message);
    }
  });
};

export const deleteQualificationApi = payload => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(DELETE_QUALIFICATION_API, payload, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
          'Content-type': 'application/json',
        },
      });
      resolve(response.data);
    } catch (error) {
      reject(error.message);
    }
  });
};

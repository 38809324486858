/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Progress,
  Select,
  Textarea,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineSave, AiOutlineReload } from 'react-icons/ai';

import {
  addBatchPending,
  addBatchSuccess,
  addBatchFail,
} from '../../../../../services/slice/addBatchSlice';

import {
  updateBatchPending,
  updateBatchSuccess,
  updateBatchFail,
} from '../../../../../services/slice/updateBatchSlice';

import { useForm } from 'react-hook-form';
import {
  addBatchApi,
  updateBatchApi,
} from '../../../../../services/api/batch-api';
import { getAllProductsFail, getAllProductsPending, getAllProductsSuccess } from '../../../../../services/slice/getAllProductsSlice';
import { getAllProductApi } from '../../../../../services/api/product-api';
import { getAllUsersFail, getAllUsersPending, getAllUsersSuccess } from '../../../../../services/slice/userSlice';
import { getAllUsersApi } from '../../../../../services/api/users-api';

const SideDrawer = props => {
  console.log('🚀 ~ file: SideDrawer.js ~ line 30 ~ props', props);
  const {
    register,
    handleSubmit,
    // eslint-disable-next-line no-unused-vars
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();

  const { addCategoryLoading } = useSelector(state => state.addCategory);

  const { updateCategorySuccessful, updateCategoryIsLoading } = useSelector(
    state => state.updateCategory
  );

  const {getAllUserLoading, users } = useSelector(state => state.users);
  const { isLoading, products, error } = useSelector(state => state.products);

  const toast = useToast();

  const onSubmit = async data => {
    console.log('🚀 ~ file: SideDrawer.js ~ line 39 ~ data', data);

    dispatch(addBatchPending());

    try {
      const addBatch = await addBatchApi(data);

      await dispatch(addBatchSuccess(addBatch));
      toast({
        title: `Add Batch ${addBatch}`,
        position: 'top-right',
        status: 'success',
        isClosable: true,
      });
      props.onClose();
    } catch (error) {
      dispatch(addBatchFail(error));
      toast({
        title: `${error}`,
        position: 'top-right',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const onEditSubmit = async data => {
    console.log('🚀 ~ file: CategorySideDrawer.js ~ line 98 ~ data', data);
    data.id = props.data.id;
    dispatch(updateBatchPending());

    try {
      const updateProduct = await updateBatchApi(data);
      dispatch(updateBatchSuccess(updateProduct), () => {
        console.log(updateCategorySuccessful);
      });

      toast({
        title: `${updateProduct}`,
        position: 'top-right',
        status: 'success',
        isClosable: true,
      });

      props.onClose();
    } catch (error) {
      dispatch(updateBatchFail(error));
      toast({
        title: `${error}`,
        position: 'top-right',
        status: 'error',
        isClosable: true,
      });
    }
  };
  const color = useColorModeValue('#ae0000', 'white');

  const fetchAllProducts = async () => {
    dispatch(getAllProductsPending());
    try {
      const getAllProduct = await getAllProductApi();
      console.log("🚀 ~ file: Product.js:78 ~ fetchAllProducts ~ getAllProduct:", getAllProduct)
      dispatch(getAllProductsSuccess(getAllProduct));
    } catch (error) {
      dispatch(getAllProductsFail(error));
    }
  };

  const fetchAllUsers = async () => {
    dispatch(getAllUsersPending());
    try {
      const getAllUsers = await getAllUsersApi();
      dispatch(getAllUsersSuccess(getAllUsers));
    } catch (error) {
      dispatch(getAllUsersFail(error));
    }
  };

  useEffect(() => {
    fetchAllProducts();
    fetchAllUsers();
  }, [])
  return (
    <>
      <Drawer onClose={props.onClose} isOpen={props.isOpen} size={'xl'}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            {props.type === 'add' ? 'Add Batch' : 'Edit Batch'}
          </DrawerHeader>
          <Drawer />
          <DrawerBody>
            <form
              onSubmit={
                props.type === 'add'
                  ? handleSubmit(onSubmit)
                  : handleSubmit(onEditSubmit)
              }
            >
              <Divider />
              <Box w={{ base: '100%', md: '100%' }}>
                <Flex direction={{ base: 'column', md: 'row' }}>
                  <FormControl id="name" isRequired py="5" px="5">
                    <FormLabel>Name</FormLabel>
                    <Input
                      type="text"
                      defaultValue={props.data.name}
                      {...register('name', { required: true })}
                    />
                  </FormControl>
                </Flex>
              </Box>

              <Box w={{ base: '100%', md: '100%' }}>
                <Flex direction={{ base: 'column', md: 'row' }}>
                  <FormControl id="description" isRequired py="5" px="5">
                    <FormLabel>Description</FormLabel>
                    <Textarea
                      defaultValue={props.data.description}
                      type="text"
                      {...register('description', { required: true })}
                    />
                    <FormHelperText>Some description here.</FormHelperText>
                  </FormControl>
                </Flex>
              </Box>

              <Box w={{ base: '100%', md: '100%' }}>
                <Flex direction={{ base: 'column', md: 'row' }}>
                  
                  <FormControl id="courseid" isRequired py="5" px="5">
                    <FormLabel>Course ID</FormLabel>
                    <Select
                      {...register('course_id', { required: true })}
                      placeholder="Select Course/ID"
                      defaultValue={props.data.id}
                    >
                      {products.map((element, item) => {
                        return (
                          <option
                            key={item + element.product_id}
                            value={element.product_id}
                          >
                            {element.product_name}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                  
                  <FormControl id="staff_id" isRequired py="5" px="5">
                    <FormLabel>Staff Name/ID</FormLabel>
                    <Select
                      {...register('staff_id', { required: true })}
                      placeholder="Select staff"
                      defaultValue={props.data.staff_id}
                    >
                      {users.map((element, item) => {
                        if(element.group_id !== 4 ){
                        return (
                          <option
                            key={item + element.id}
                            value={element.id}
                          >
                            {element.first_name} {element.last_name} 
                          </option>
                        )}
                      })}
                    </Select>
                  </FormControl>
                  {/*<FormControl id="confirmPassword" isRequired py="5" px="5">
                    <FormLabel>Confirm Password</FormLabel>
                    <Input type="password" />
                    <FormHelperText>
                      Re-enter the password to confirm.
                    </FormHelperText>
            </FormControl>*/}
                </Flex>
              </Box>

              {/*<Box w={{ base: '100%', md: '80%' }}>
                <Flex direction={{ base: 'column', md: 'row' }}>
                  <FormControl as="fieldset" isRequired py="5" px="5">
                    <FormLabel as="legend">Gender</FormLabel>
                    <RadioGroup defaultValue="Itachi">
                      <HStack spacing="24px">
                        <Radio
                          value="Male"
                          {...register('gender', { required: true })}
                        >
                          Male
                        </Radio>
                        <Radio
                          value="Female"
                          {...register('gender', { required: true })}
                        >
                          Female
                        </Radio>
                        <Radio
                          value="Others"
                          {...register('gender', { required: true })}
                        >
                          Others
                        </Radio>
                      </HStack>
                    </RadioGroup>
                  </FormControl>
                  <FormControl id="group_id" isRequired py="5" px="5">
                    <FormLabel>Group ID</FormLabel>
                    <Input
                      type="text"
                      {...register('group_id', { required: true })}
                    />
                    <FormHelperText>Your birth date & year.</FormHelperText>
                  </FormControl>
                </Flex>
          </Box>*/}

              <Box w={{ base: '100%', md: '100%' }}>
                <Flex direction={{ base: 'column', md: 'row' }}>
                  <FormControl id="startDateTime" isRequired py="5" px="5">
                    <FormLabel>Start Date-Time</FormLabel>
                    <Input
                      type="date"
                      defaultValue={props.data.start_datetime}
                      {...register('start_datetime', { required: true })}
                    />
                  </FormControl>

                  <FormControl id="endDateTime" isRequired py="5" px="5">
                    <FormLabel>End Date-Time</FormLabel>
                    <Input
                      type="date"
                      defaultValue={props.data.end_datetime}
                      {...register('end_datetime', { required: true })}
                    />
                  </FormControl>
                </Flex>
              </Box>

              <Box w={{ base: '100%', md: '100%' }}>
                <Flex direction={{ base: 'column', md: 'row' }}>
                  <FormControl id="start_time" isRequired py="5" px="5">
                    <FormLabel>Start Time</FormLabel>
                    <Input
                      type="time"
                      defaultValue={props.data.start_time}
                      {...register('start_time', { required: true })}
                    />
                  </FormControl>

                  <FormControl id="end_time" isRequired py="5" px="5">
                    <FormLabel>End Time</FormLabel>
                    <Input
                      type="time"
                      defaultValue={props.data.end_time}
                      {...register('end_time', { required: true })}
                    />
                  </FormControl>
                </Flex>
              </Box>

              <Divider />
              <Box w={{ base: '100%', md: '100%' }}>
                <Flex direction={{ base: 'column', md: 'row' }}>
                  <FormControl id="schedule" py="5" px="5">
                    <FormLabel>schedule</FormLabel>
                    <Input
                      type="text"
                      defaultValue={props.data.schedule}
                      {...register('schedule', { required: true })}
                    />
                  </FormControl>

                  <FormControl id="classroom_link" py="5" px="5">
                    <FormLabel>classroom link</FormLabel>
                    <Input
                      type="text"
                      defaultValue={props.data.classroom_link}
                      {...register('classroom_link', { required: false })}
                    />
                  </FormControl>
                </Flex>
              </Box>
              <Box w={{ base: '100%', md: '100%' }}>
                <Flex direction={{ base: 'column', md: 'row' }}>
                  <FormControl id="whatsapp_link" py="5" px="5">
                    <FormLabel>whatsapp link</FormLabel>
                    <Input
                      type="text"
                      defaultValue={props.data.whatsapp_link}
                      {...register('whatsapp_link', { required: true })}
                    />
                  </FormControl>

                  <FormControl id="batch_status" py="5" px="5">
                    <Checkbox
                      size="md"
                      colorscheme="green"
                      {...register('batch_status', { required: false })}
                      defaultChecked={props.data.batch_status}
                    >
                      Batch_status
                    </Checkbox>
                  </FormControl>
                </Flex>
              </Box>
              <Button
                type="submit"
                leftIcon={
                  addCategoryLoading || updateCategoryIsLoading ? (
                    <Progress size="xs" isIndeterminate />
                  ) : (
                    <AiOutlineSave
                      color={color}
                    />
                  )
                }
                width='full'
              >
                Submit
              </Button>
              <Button
                variant={'link'}
                leftIcon={
                  <AiOutlineReload
                    color={color}
                  />
                }
                width='full'
              >
                {props.type === 'add' ? 'Reset' : 'Cancel'}
              </Button>
            </form>

            {addCategoryLoading && <Progress size="xs" isIndeterminate />}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default SideDrawer;

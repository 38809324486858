/* eslint-disable no-unused-vars */
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, useToast } from '@chakra-ui/react'
import React from 'react'
import Uploader from '../Uploader/Uploader'
import { addMediaImagesApi } from '../../services/api/media-api'
import { useDispatch, useSelector } from 'react-redux';
import {
    addMediaImagesPending,
    addMediaImagesSuccess,
    addMediaImagesFail,
} from '../../services/slice/addMediaImagesSlice';
import { useNavigate } from 'react-router';



const UploadModal = (props) => {
    const navigate = useNavigate();
    // const [overlay, setOverlay] = React.useState(<ModalOverlay />)

    const toast = useToast();
    const dispatch = useDispatch();

    const {
        addMediaImagesSuccessful,
        addMediaImagesLoading,
        addMediaImagesError,
        addMediaImagesMessage,
    } = useSelector(state => state.addMediaImages);
    const submit = async (state) => {
        console.log("🚀 ~ file: UploadModal.jsx:10 ~ submit ~ state:", state)
        dispatch(addMediaImagesPending());

        try {
            const addImages = await addMediaImagesApi(state);


            dispatch(addMediaImagesSuccess('inserted'));
            toast({
                title: `Add Images ${addImages}`,
                position: 'top-right',
                status: 'success',
                isClosable: true,
            });
        } catch (error) {
            if (error.message === "Request failed with status code 401") {
                navigate('/signin', { replace: true })
              }
            dispatch(addMediaImagesFail(error.message));
            toast({
                title: `${addMediaImagesMessage}`,
                position: 'top-right',
                status: 'error',
                isClosable: true,
            });
            // props.onClose();
        }

    }
    return (


        <Modal isCentered isOpen={props.isOpen} onClose={props.onClose}>
            <ModalOverlay
                bg='blackAlpha.300'
                backdropFilter='blur(10px) hue-rotate(90deg)'
            />
            <ModalContent>
                <ModalHeader>Upload Image</ModalHeader>
                <ModalCloseButton onClick={props.onClose} />
                <ModalBody>
                    <Uploader isLoading={addMediaImagesLoading} attributeId={props.attributeId} callBackToParent={submit} />
                </ModalBody>
                <ModalFooter>
                   
                    <Button onClick={props.onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default UploadModal


export const cartData = [
  {
    "category_level_id": 0,
    "category_name": "AI",
    "category_status": true,
    "parent_group_id": "0",
    "product_id": 0,
    "product_name": "Data Science with Python",
    "description": "Data Science implimented using Python as a programing language",
    "attribute_id": 0,
    "key": "Course Level",
    "value": "Basic",
    "colour": null,
    "cost_price": 7500,
    "mrp": 25000,
    "units": null,
    "media_path": "https://redhood.s3.amazonaws.com/images/products/0.jpg",
    "brand_id": 0,
    "brand_name": "default",
    "coupon_code": null,
    "discount": null,
    "validity_status": null,
    "user_id": 0
  },
  {
    "category_level_id": 0,
    "category_name": "AI",
    "category_status": true,
    "parent_group_id": "0",
    "product_id": 0,
    "product_name": "Data Science with Python",
    "description": "Data Science implimented using Python as a programing language",
    "attribute_id": 1,
    "key": "Course Level",
    "value": "Advance",
    "colour": null,
    "cost_price": 7500,
    "mrp": 25000,
    "units": null,
    "media_path": "https://redhood.s3.amazonaws.com/images/products/1.jpg",
    "brand_id": 0,
    "brand_name": "default",
    "coupon_code": null,
    "discount": null,
    "validity_status": null,
    "user_id": 0
  },
  {
    "category_level_id": 1,
    "category_name": "Programming",
    "category_status": true,
    "parent_group_id": "1",
    "product_id": 2,
    "product_name": "Java",
    "description": "Basic and Advance",
    "attribute_id": 3,
    "key": "Course Level",
    "value": "Basic and Advance",
    "colour": null,
    "cost_price": 3000,
    "mrp": 10000,
    "units": null,
    "media_path": "https://redhood.s3.amazonaws.com/images/products/3.jpg",
    "brand_id": 2,
    "brand_name": "Oracle",
    "coupon_code": null,
    "discount": null,
    "validity_status": null,
    "user_id": 0
  },
  {
    "category_level_id": 2,
    "category_name": "Cloud",
    "category_status": true,
    "parent_group_id": "2",
    "product_id": 5,
    "product_name": "AWS",
    "description": "Basic and Advance",
    "attribute_id": 7,
    "key": "Course Level",
    "value": "Basic and Advance",
    "colour": null,
    "cost_price": 4500,
    "mrp": 15000,
    "units": null,
    "media_path": "https://redhood.s3.amazonaws.com/images/products/7.jpg",
    "brand_id": 4,
    "brand_name": "Amazon",
    "coupon_code": null,
    "discount": null,
    "validity_status": null,
    "user_id": 0
  }
]
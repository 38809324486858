/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Box,
  Center,
  Heading,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Card } from './Card';
import { Link } from './Link';

import { useNavigate, useNavigationType } from 'react-router';
import { LoginForm } from './LoginForm';

import { useSelector, useDispatch } from 'react-redux';
import { colorConfig } from '../../../../../colorConfig';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuth, group_id, error } = useSelector(state => state.login);
  const navigationType = useNavigationType();


  useEffect(() => {
    console.log("🚀 ~ file: Login.js ~ line 23 ~ Login ~ navigationType", navigationType)
    if (isAuth && navigationType === "PUSH" && group_id !== 0) {
      navigate(-1);
    }
    if (isAuth) {
      parseInt(sessionStorage.getItem('group_id')) === 4
        ? navigate('/', { replace: true })
        : navigate('/admindashboard', { replace: true });
    }
  }, [isAuth]);
  return (
    <Box
      bg={useColorModeValue('gray.50', 'inherit')}
      minH="100vh"
      py="12"
      px={{
        base: '4',
        lg: '8',
      }}
    >
      <Center>
        <Box maxW="md" mx="auto">
          <Heading textAlign="center" size="xl" fontWeight="extrabold">
            Sign in to your account
          </Heading>

          <Card my={5}>
            <LoginForm />
          </Card>
          <Text mt="4" mb="8" align="center" maxW="md" fontWeight="bold">
            <Text as="span">Don&apos;t have an account?</Text>
            <Link color={colorConfig.primary} _hover={{ color: colorConfig.secondary }} href="/contact">Contact</Link>
          </Text>
        </Box>
      </Center>
      {error && (
        <Center>
          <Text
            mt="4"
            mb="8"
            align="center"
            color="red.500"
            maxW="md"
            fontWeight="light"
          >
            {error}
          </Text>
        </Center>
      )}
    </Box>
  );
};
export default Login;

/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
import { Box, CloseButton, Flex, IconButton, Link, Select, Text, useColorModeValue } from '@chakra-ui/react'
import * as React from 'react'
import { PriceTag } from './PriceTag'
import { CartProductMeta } from './CartProductMeta'
import { useParams } from 'react-router'
import { FaMoon, FaSun } from 'react-icons/fa';
import { colorConfig } from '../../../../colorConfig'



const QuantitySelect = (props) => {
  return (
    <Select
      maxW="64px"
      aria-label="Select quantity"
      focusBorderColor={useColorModeValue('blue.500', 'blue.200')}
      {...props}
    >
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
    </Select>
  )
}

export const CartItem = (props) => {
  
  
  const {
    isGiftWrapping,
    product_name,
    description,
    quantity,
    media_path,
    currency,
    mrp,
    onChangeQuantity,
    category_name,
    onClickDelete,
    salePrice,
    value
  } = props;
  return (
    <Box border={`1px solid ${colorConfig.primary}`} padding={3} borderRadius={'5'}>
    <Flex
      direction={{
        base: 'column',
        md: 'row',
      }}
      justify="space-between"
      align="center"
    >
      <CartProductMeta
        product_name={product_name}
        category_name={category_name}
        image={media_path}
        isGiftWrapping={isGiftWrapping}
        attribute={value}
      />

      {/* Desktop */}
      <Flex
        width="full"

        flexDirection={'column'}
        display={{
          base: 'none',
          md: 'flex',
        }}

      >
        {/*<QuantitySelect
          value={quantity}
          onChange={(e) => {
            onChangeQuantity?.(+e.currentTarget.value)
          }}
        />*/}
        <PriceTag mrp={mrp} currency={currency} />
        <Flex width={'full'} justifyContent={'space-between'}>

        <Link fontSize="sm" textDecor="underline">
          Move to wishlist
        </Link>
       
          <Link fontSize="sm" color={colorConfig.primary} textDecor="underline">
          Delete
        </Link>

        </Flex>
      </Flex>

      {/* Mobile */}
      <Flex
        mt="4"
        align="center"
        width="full"
        justify="space-between"
        display={{
          base: 'flex',
          md: 'none',
        }}
      >
        <Link fontSize="sm" textDecor="underline">
          Delete
        </Link>
        {/*<QuantitySelect
          value={quantity}
          onChange={(e) => {
            onChangeQuantity?.(+e.currentTarget.value)
          }}
        />*/}
        <PriceTag salePrice={salePrice} mrp={mrp} currency={currency} />
      </Flex>
    </Flex>
    </Box>
  )
}

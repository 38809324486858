import { configureStore } from '@reduxjs/toolkit';
import loginReducer from '../services/slice/loginSlice';
import getAllUsersReducer from '../services/slice/userSlice';
import addUserReducer from '../services/slice/addUserSlice';
import getAllProductsSlice from '../services/slice/getAllProductsSlice';
import getAllGroupsSlice from '../services/slice/getAllGroupSlice';
import addGroupSlice from '../services/slice/addGroupSlice';
import deleteGroupSlice from '../services/slice/deleteGroupSlice';
import updateGroupSlice from '../services/slice/updateGroupSlice';
import addProductSlice from '../services/slice/addProductSlice';
import updateProductSlice from '../services/slice/updateProductSlice';
import deleteProductSlice from '../services/slice/deleteProductSlice';
import getAllCategorySlice from '../services/slice/getAllCategorySlice';
import addCategorySlice from '../services/slice/addCategorySlice';
import updateCategorySlice from '../services/slice/updateCategorySlice';
import deleteCategorySlice from '../services/slice/deleteCatagorySlice';
import addBrandSlice from '../services/slice/addBrandSlice';
import updateBrandSlice from '../services/slice/updateBrandSlice';
import getAllBrandSlice from '../services/slice/getAllBrandSlice';
import deleteBrandSlice from '../services/slice/deleteBrandSlice';
import getAllBatchSlice from '../services/slice/getAllBatchSlice';
import addBatchSlice from '../services/slice/addBatchSlice';
import deleteBatchSlice from '../services/slice/deleteBatchSlice';
import updateBatchSlice from '../services/slice/updateBatchSlice';
import updateUserSlice from '../services/slice/updateUserSlice';
import addAttributeSlice from '../services/slice/addAttributeSlice';
import updateAttributeSlice from '../services/slice/updateAttributeSlice';
import getAllAttributeSlice from '../services/slice/getAllAttributeSlice';
import getAttributeByIdSlice from '../services/slice/getAttributeById';
import getProductByIdSlice from '../services/slice/getProductById';
import getUserByIdSlice from '../services/slice/getUserByIdSlice';
import addItemToCartSlice from '../services/slice/addItemToCartSlice';
import getCartItemByIdSlice from '../services/slice/getCartItemSlice';
import addItemToWhishlistSlice from '../services/slice/addItemToWishlistSlice';
import getAllQualificationsSlice from '../services/slice/getAllQualificationSlice';
import addQualificationSlice from '../services/slice/addQualificationSlice';
import updateQualificationSlice from '../services/slice/updateQualificationSlice';
import deleteQualificationSlice from '../services/slice/deleteQualificationSlice';
import getAllSpecializationsSlice from '../services/slice/getAllSpecializationSlice';
import addSpecializationSlice from '../services/slice/addSpecializationSlice';
import updateSpecializationSlice from '../services/slice/updateSpecializationSlice';
import addMediaImagesSlice from '../services/slice/addMediaImagesSlice';
import deleteMediaImagesSlice from '../services/slice/deleteMediaImagesSlice';
import getAllMediaSlice from '../services/slice/getAllMediaSlice';
import getWishlistByUserIdSlice from '../services/slice/getWishlistByUserIdSlice';
import getAllProductsViewSlice from '../services/slice/getAllProductsViewSlice';

export const store = configureStore({
  reducer: {
    login: loginReducer,
    users: getAllUsersReducer,
    addUsers: addUserReducer,
    products: getAllProductsSlice,
    getAllGroup: getAllGroupsSlice,
    addGroup: addGroupSlice,
    deleteGroup: deleteGroupSlice,
    updateGroup: updateGroupSlice,
    addProduct: addProductSlice,
    updateProduct: updateProductSlice,
    deleteProduct: deleteProductSlice,
    category: getAllCategorySlice,
    addCategory: addCategorySlice,
    updateCategory: updateCategorySlice,
    deleteCategory: deleteCategorySlice,
    addBrand: addBrandSlice,
    updateBrand: updateBrandSlice,
    getAllBrand: getAllBrandSlice,
    deleteBrand: deleteBrandSlice,
    getAllBatch: getAllBatchSlice,
    addBatch: addBatchSlice,
    deleteBatch: deleteBatchSlice,
    updateBatch: updateBatchSlice,
    updateUser: updateUserSlice,
    addAttribute: addAttributeSlice,
    updateAttribute: updateAttributeSlice,
    getAllAttribute: getAllAttributeSlice,
    getAttributeById: getAttributeByIdSlice,
    getProductById: getProductByIdSlice,
    getUserById: getUserByIdSlice,
    addItemToCart: addItemToCartSlice,
    getCartItemById: getCartItemByIdSlice,
    addItemToWhishlist: addItemToWhishlistSlice,
    qualification: getAllQualificationsSlice,
    addQualification: addQualificationSlice,
    updateQualification: updateQualificationSlice,
    deleteQualification: deleteQualificationSlice,
    specialization: getAllSpecializationsSlice,
    addSpecialization: addSpecializationSlice,
    updateSpecialization: updateSpecializationSlice,
    addMediaImages: addMediaImagesSlice,
    deleteMediaImages: deleteMediaImagesSlice,
    getAllMedia: getAllMediaSlice,
    getWishListByUserId: getWishlistByUserIdSlice,
    getAllProductsView: getAllProductsViewSlice
  },
});
export default store;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import "./style.css"
import { Button, Progress } from '@chakra-ui/react';
import { convertion } from './base64Encode';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
  cursor: 'pointer'
};



const Uploader = (props) => {
  const [files, setFiles] = useState([]);
  const [encoded, setEncoded] = useState([]);



  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.png', '.jpg']
    },

    onDrop: async acceptedFiles => {
      const imageData = [];
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
      acceptedFiles.map((file, index) => {
        let temp = { "attribute_id": props.attributeId, "media_path": '' };
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          temp["media_path"] = fileReader.result.split(',')[1];
          console.log("🚀 ~ file: Uploader.jsx:65 ~ acceptedFiles.map ~ fileReader.result.split(',')[1]:", fileReader.result.split(',')[1])
          
          imageData.push(temp);
        };

      });
      setEncoded(imageData);
      
    }
  });

  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          alt="images"
          // Revoke data uri after image is loaded
          onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
      </div>
    </div>
  ));

  const onSubmit = () => {
    props.callBackToParent(encoded);
  }

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);

 


  return (
    <section className="container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      <aside style={thumbsContainer}>
        {files && thumbs}
      </aside>
      <Button
        leftIcon={
          props.addMediaImagesLoading && (
            <Progress size="xs" isIndeterminate />
          )}
        width="full" onClick={onSubmit}>Submit</Button>
    </section>
  );
}

export default Uploader;
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  specializations: [],
  specializationIsLoading: false,
  error: "",
};

const getAllSpecializationsSlice = createSlice({
  name: "specialization",
  initialState,
  reducers: {
    getAllSpecializationsPending: (state) => {
      state.specializationIsLoading = true;
    },
    getAllSpecializationsSuccess: (state, { payload }) => {
      state.specializationIsLoading = false;
      state.specializations = payload;
      state.error = "";
    },
    getAllSpecializationsFail: (state, { payload }) => {
      state.specializationIsLoading = false;
      state.error = payload;
    },
  },
});

const { reducer, actions } = getAllSpecializationsSlice;
export const { getAllSpecializationsPending, getAllSpecializationsSuccess, getAllSpecializationsFail } =
  actions;

export default reducer;

import {
    Button,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    FormControl,
    FormLabel,
    Input,
    Progress,
    Stack,
    useColorModeValue,
    useToast,
  } from '@chakra-ui/react';
  import React from 'react';
  import { useDispatch, useSelector } from 'react-redux';
  import { AiOutlineSave, AiOutlineReload } from 'react-icons/ai';
  
  import {
    addQualificationPending,
    addQualificationSuccess,
    addQualificationFail,
  } from '../../../../../services/slice/addQualificationSlice';
  
  import {
    updateQualificationPending,
    updateQualificationSuccess,
    updateQualificationFail,
  } from '../../../../../services/slice/updateQualificationSlice';
  
  import {
    addQualificationApi,
    updateQualificationApi,
  } from '../../../../../services/api/qualification-api';
  import { useForm } from 'react-hook-form';
  
  const SideDrawer = props => {
    console.log("🚀 ~ file: QualificationSidePanel.js:40 ~ SideDrawer ~ props:", props)
    
    const {
      register,
      handleSubmit,
      // eslint-disable-next-line no-unused-vars
      formState: { errors },
    } = useForm();
    const dispatch = useDispatch();
  
    const {
        addQualificationIsLoading,
    } = useSelector(state => state.addQualification);
  
    const {
      updateQualificationSuccessful,
      updateQualificationIsLoading,
    } = useSelector(state => state.updateQualification);
  
    const toast = useToast();
  
    const onSubmit = async data => {
      
  
      dispatch(addQualificationPending());
  
      try {
        const addQualification = await addQualificationApi(data);
  
        await dispatch(addQualificationSuccess(addQualification));
        toast({
          title: `Add Qualification ${addQualification}`,
          position: 'top-right',
          status: 'success',
          isClosable: true,
        });
        props.onClose();
      } catch (error) {
        
        dispatch(addQualificationFail(error));
        toast({
          title: `${error}`,
          position: 'top-right',
          status: 'error',
          isClosable: true,
        });
      }
    };
  
    const onEditSubmit = async data => {
      
        data.id = props.data.id;
  
      dispatch(updateQualificationPending());
  
      try {
        const updateProduct = await updateQualificationApi(data);
        dispatch(updateQualificationSuccess(updateProduct), () => {
          console.log(updateQualificationSuccessful);
        });
  
        toast({
          title: `${updateProduct}`,
          position: 'top-right',
          status: 'success',
          isClosable: true,
        });
  
        props.onClose();
      } catch (error) {
        dispatch(updateQualificationFail(error));
        toast({
          title: `${error}`,
          position: 'top-right',
          status: 'error',
          isClosable: true,
        });
      }
    };
  
    const color = useColorModeValue('#ae0000', 'white');
    return (
      <>
        <Drawer onClose={props.onClose} isOpen={props.isOpen} size={'lg'}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader>
              {props.type === 'add' ? 'Add Qualification' : 'Edit Qualification'}
            </DrawerHeader>
            <Drawer />
            <DrawerBody>
              <form
                onSubmit={
                  props.type === 'add'
                    ? handleSubmit(onSubmit)
                    : handleSubmit(onEditSubmit)
                }
              >
                <Stack spacing="6">
                  <FormControl id="qualification_id">
                    <FormLabel>Qualification Level ID</FormLabel>
                    <Input
                      name="qualification_id"
                      isDisabled
                      {...register('id', { required: false })}
                      type="text"
                      defaultValue={props.data.id}
                    />
                  </FormControl>
                  <FormControl id="qualification_type">
                    <FormLabel>Type</FormLabel>
                    <Input
                      name="qualification_type"
                      {...register('type', { required: true })}
                      type="text"
                      autoComplete="text"
                      required
                      defaultValue={props.data.type}
                    />
                  </FormControl>
                  
                 
                  <Button
                    type="submit"
                    leftIcon={
                        addQualificationIsLoading || updateQualificationIsLoading ? (
                        <Progress size="xs" isIndeterminate />
                      ) : (
                        <AiOutlineSave
                          color={color}
                        />
                      )
                    }
                    width='full'
                  >
                    Submit
                  </Button>
                  <Button
                    variant={'link'}
                    leftIcon={
                      <AiOutlineReload
                        color={color}
                      />
                    }
                    width='full'
                  >
                    {props.type === 'add' ? 'Reset' : 'Cancel'}
                  </Button>
                </Stack>
              </form>
              {addQualificationIsLoading && <Progress size="xs" isIndeterminate />}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    );
  };
  
  export default SideDrawer;
  
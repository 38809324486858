import axios from 'axios';
import {
  ADD_ITEM_TO_WISHLIST_API, GET_WISHLIST_ITEM_BY_USER_ID_VIEW_API
} from './generic-api';

export const getWishlistItemByIdApi = payload => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        GET_WISHLIST_ITEM_BY_USER_ID_VIEW_API, payload,
        {
          headers: {
            session_id: sessionStorage.getItem('session_id'),
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      reject(error.message);
    }
  });
};

// export const getAllCartItemApi = () => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const response = await axios.get(GET_ALL_CART_ITEM_API, {
//         headers: {
//           session_id: sessionStorage.getItem('session_id'),
//         },
//       });
//       resolve(response.data);
//     } catch (error) {
//       reject(error.message);
//     }
//   });
// };

export const addItemToWhishlist = payload => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(ADD_ITEM_TO_WISHLIST_API, payload, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
        },
      });
      resolve(response.data);
    } catch (error) {
      reject(error.message);
    }
  });
};
// export const updateCartApi = payLoadData => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const response = await axios.patch(UPDATE_CART_API, payLoadData, {
//         headers: {
//           session_id: sessionStorage.getItem('session_id'),
//           'Access-Control-Allow-Origin': '*',
//           withCredentials: false,
//         },
//       });

//       resolve(response.data);
//       // }
//     } catch (error) {
//       reject(error.message);
//     }
//   });
// };

// export const deleteAttributeApi = payLoadData => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const response = await axios.delete(DELETE_ATTRIBUTE_API, payLoadData, {
//         headers: {
//           session_id: sessionStorage.getItem('session_id'),
//           'Content-type': 'application/json',
//         },
//       });
//       resolve(response.data);
//     } catch (error) {
//       reject(error.message);
//     }
//   });
// };

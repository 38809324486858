import {
    Button,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    FormControl,
    FormLabel,
    Input,
    Progress,
    Stack,
    useColorModeValue,
    useToast,
  } from '@chakra-ui/react';
  import React from 'react';
  import { useDispatch, useSelector } from 'react-redux';
  import { AiOutlineSave, AiOutlineReload } from 'react-icons/ai';
  
  import {
    addBrandPending,
    addBrandSuccess,
    addBrandFail,
  } from '../../../../../services/slice/addBrandSlice';
  
  import {
    updateBrandPending,
    updateBrandSuccess,
    updateBrandFail,
  } from '../../../../../services/slice/updateBrandSlice';
  
  import {
    addBrandApi,
    updateBrandApi,
  } from '../../../../../services/api/brand-api';
  import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
  
  const SideDrawer = props => {
    const navigate = useNavigate();
    const {
      register,
      handleSubmit,
      // eslint-disable-next-line no-unused-vars
      formState: { errors },
    } = useForm();
    const dispatch = useDispatch();
  
    const {
      addBrandLoading,
    } = useSelector(state => state.addBrand);
  
    const {
      updateBrandSuccessful,
      updateBrandIsLoading,
    } = useSelector(state => state.updateBrand);
  
    const toast = useToast();
  
    const onSubmit = async data => {
      
  
      dispatch(addBrandPending());
  
      try {
        const addBrand = await addBrandApi(data);
       
  
        await dispatch(addBrandSuccess(addBrand));
        toast({
          title: `Add Brand ${addBrand}`,
          position: 'top-right',
          status: 'success',
          isClosable: true,
        });
        props.onClose();
      } catch (error) {
        if (error.message === "Request failed with status code 401") {
          sessionStorage.clear();
        navigate('/signin', { replace: true })
      }
        dispatch(addBrandFail(error));
        toast({
          title: `${error}`,
          position: 'top-right',
          status: 'error',
          isClosable: true,
        });
      }
    };
  
    const onEditSubmit = async data => {
      
  
      dispatch(updateBrandPending());
  
      try {
        const updateProduct = await updateBrandApi(data);
        dispatch(updateBrandSuccess(updateProduct), () => {
          console.log(updateBrandSuccessful);
        });
  
        toast({
          title: `${updateProduct}`,
          position: 'top-right',
          status: 'success',
          isClosable: true,
        });
  
        props.onClose();
      } catch (error) {
        if (error.message === "Request failed with status code 401") {
        navigate('/signin', { replace: true })
      }
        dispatch(updateBrandFail(error));
        toast({
          title: `${error}`,
          position: 'top-right',
          status: 'error',
          isClosable: true,
        });
      }
    };
  
    const color = useColorModeValue('#ae0000', 'white')
    return (
      <>
        <Drawer onClose={props.onClose} isOpen={props.isOpen} size={'lg'}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader>
              {props.type === 'add' ? 'Add Brand' : 'Edit Brand'}
            </DrawerHeader>
            <Drawer />
            <DrawerBody>
              <form
                onSubmit={
                  props.type === 'add'
                    ? handleSubmit(onSubmit)
                    : handleSubmit(onEditSubmit)
                }
              >
                <Stack spacing="6">
                  {props.type !== "add" && <FormControl id="brand_id">
                    <FormLabel>Brand ID</FormLabel>
                    <Input
                      name="brand_id"
                      {...register('brand_id', { required: true })}
                      type="text"
                      autoComplete="text"
                      defaultValue={props.data.brand_id}
                    />
                  </FormControl>}
                  <FormControl id="brand_name">
                    <FormLabel>Brand Name</FormLabel>
                    <Input
                      name="brand_name"
                      {...register('brand_name', { required: true })}
                      type="text"
                      autoComplete="text"
                      required
                      defaultValue={props.data.brand_name}
                    />
                  </FormControl>
                  
                  <Button
                    type="submit"
                    leftIcon={
                      addBrandLoading || updateBrandIsLoading ? (
                        <Progress size="xs" isIndeterminate />
                      ) : (
                        <AiOutlineSave
                          color={color}
                        />
                      )
                    }
                    width='full'
                  >
                    Submit
                  </Button>
                  <Button
                    variant={'link'}
                    leftIcon={
                      <AiOutlineReload
                        color={useColorModeValue('#ae0000', 'white')}
                      />
                    }
                    width='full'
                  >
                    {props.type === 'add' ? 'Reset' : 'Cancel'}
                  </Button>
                </Stack>
              </form>
              {addBrandLoading && <Progress size="xs" isIndeterminate />}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    );
  };
  
  export default SideDrawer;
  
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addItemToCartSuccessful: false,
  addItemToCartLoading: false,
  addItemToCartError: "",
  addItemToCartMessage:"",
};

const addItemToCartSlice = createSlice({
  name: "addItemToCart",
  initialState,
  reducers: {
    addItemToCartPending: (state) => {
      state.addItemToCartLoading = true;
    },
    addItemToCartSuccess: (state, { payload }) => {
      state.addItemToCartLoading = false;
      state.addItemToCartSuccessful = true;
      state.addItemToCartError = "";
      state.addItemToCartMessage = payload;
    },
    addItemToCartFail: (state, { payload }) => {
      state.addItemToCartLoading = false;
      state.addItemToCartError = payload;
    },
  },
});

const { reducer, actions } = addItemToCartSlice;
export const { addItemToCartPending, addItemToCartSuccess, addItemToCartFail } =
  actions;

export default reducer;

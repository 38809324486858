import React from 'react';
import {
  chakra,
  Box,
  Flex,
  useColorModeValue,
  Button,
  IconButton,
} from '@chakra-ui/react';

import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineUnorderedList,
} from 'react-icons/ai';

import { CgAttribution } from 'react-icons/cg';
// import { Course } from '../../Pages/client/Courses/Course';
const ProductQuickCards = ({
  data,
  action,
  deleteProductCard,
  type,
  openAttributes,
}) => {
  
  const { product_id, product_name, category_level_id } = data;
  const boxShadow = useColorModeValue('dark-lg', 'outline');
  return (
    <Flex
      // bg={useColorModeValue('#F9FAFB', 'gray.600')}
      p={2}
      m={{ base: 2, md: 4 }}
      w={{ base: 200, md: 330 }}
      alignItems="center"
      justifyContent="flex-start"
      rounded={'md'}
      boxShadow={boxShadow}
    >
      <Box
        key={product_id + product_name}
        w="full"
        maxW="sm"
        mx="auto"
        px={2}
        py={2}
        bg={useColorModeValue('white', 'gray.800')}
        // shadow="md"
        rounded="md"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <chakra.span
            fontSize="sm"
            color={useColorModeValue('gray.800', 'gray.400')}
          >
            {type === 'category'
              ? data.category_level_id
              : type === 'brand'
              ? data.brand_id
              : type === 'users'
              ? data.phone
              : type === 'group'
              ? data.id
              : type === 'batch'
              ? data.course_id
              : product_id}
          </chakra.span>
          <Flex>
            {type !== 'brand' && type !== 'group' && type !=='qualification' && type !=='specialization'  ? (
              <chakra.span
                bg={'teal.400'}
                color={'whiteAlpha.900'}
                px={3}
                py={1}
                rounded="full"
                textTransform="uppercase"
                fontSize="xs"
              >
                {type === 'category'
                  ? `Parent Group ID: ${data.parent_group_id}`
                  : type === 'users'
                  ? `Group ID: ${data.group_id}`
                  : type === 'batch'
                  ? `${data.schedule}`
                  : `Category: ${category_level_id}`}
              </chakra.span>
            ) : null}
            <IconButton
              aria-label="Search database"
              onClick={() => action(data, 'edit')}
              variant="outline"
              background={useColorModeValue('white', 'gray.800')}
              color={useColorModeValue('gray.800', 'white')}
              height={'26px'}
              borderRadius={20}
              icon={<AiOutlineEdit />}
            />
          </Flex>
        </Flex>

        <Box>
          <chakra.h1
            fontSize="lg"
            fontWeight="bold"
            mt={2}
            color={useColorModeValue('gray.800', 'white')}
          >
            {type === 'category'
              ? data.category_name
              : type === 'brand'
              ? data.brand_name
              : type === 'users'
              ? `${data.first_name} ${data.last_name}`
              : type === 'group'
              ? data.name
              : type === 'batch'
              ? data.name
              : type === 'qualification' ? data.type : type === 'specialization' ? data.specialization :  product_name}
          </chakra.h1>
          {/*<chakra.p
            fontSize="sm"
            mt={2}
            color={useColorModeValue('gray.600', 'gray.300')}
          >
            {description}
          </chakra.p>*/}
        </Box>

        <Box>
          <Flex
            alignItems="center"
            justify={'space-between'}
            mt={2}
            color={useColorModeValue('gray.700', 'gray.200')}
          >
            {type === 'product' && (
              <Button
              isDisabled
                variant={'ghost'}
                onClick={() => action(data, 'edit')}
                leftIcon={<AiOutlineUnorderedList />}
              >
                Curriculum
              </Button>
            )}
            {type === 'product' && (
              <Button
                variant={'ghost'}
                onClick={() => openAttributes(data, 'attributesUpdate')}
                leftIcon={<CgAttribution />}
              >
                Attributes
              </Button>
            )}

            <Button
              variant={'outline'}
              onClick={() => deleteProductCard(data)}
              leftIcon={<AiOutlineDelete />}
            >
              Delete
            </Button>
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};

export default ProductQuickCards;

/* eslint-disable no-unused-vars */
import {
  Box,
  Flex,
  Stack,
  useColorMode,
  useColorModeValue as mode,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { BiHome } from 'react-icons/bi';
import { RiProductHuntLine } from 'react-icons/ri';


import { GrStackOverflow } from 'react-icons/gr';
import { SiBrandfolder } from 'react-icons/si';
import { GiMeepleGroup, GiAchievement } from 'react-icons/gi';
import { TiGroup } from 'react-icons/ti';
import { ImUsers } from 'react-icons/im';
import { Link } from 'react-router-dom';
import { AccountSwitcher } from './AccountSwitcher';
import { NavGroup } from './NavGroup';
import { NavItem } from './NavItem';
import { Outlet, useLocation } from 'react-router-dom';

const SideNav = () => {
  const { toggleColorMode: toggleMode } = useColorMode();
  const [activeTab] = useState('false');
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const color = useColorModeValue("gray.600", "gray.300");

  return (
    <Box height="100vh" overflow="hidden" position="relative">
      <Flex h="full" id="app-container">
        <Box
          w="64"
          bg={useColorModeValue('gray.900')}
          color="white"
          fontSize="sm"
        >
          <Flex h="full" direction="column" px="4" py="4">
            <AccountSwitcher  />
            <Stack spacing="8" flex="1" overflow="auto" pt="8">
              <Stack spacing="1">
                <NavItem icon={<BiHome />} label="Dashboard" />
              </Stack>
              <NavGroup label="Products">
                <Link to="/admindashboard/allproducts">
                  <NavItem
                    icon={<RiProductHuntLine />}
                    active={
                      location.pathname === '/admindashboard/allproducts'
                        ? activeTab
                        : ''
                    }
                    label="All Products"
                  />
                </Link>
                <Link to="/admindashboard/categories">
                  <NavItem
                    active={
                      location.pathname === '/admindashboard/categories'
                        ? activeTab
                        : ''
                    }
                    icon={<GrStackOverflow />}
                    label="Categories"
                  />
                </Link>
                <Link to="/admindashboard/brand">
                  <NavItem
                    active={
                      location.pathname === '/admindashboard/brand'
                        ? activeTab
                        : ''
                    }
                    icon={<SiBrandfolder />}
                    label="Brand"
                  />
                </Link>
                <Link to="/admindashboard/batch">
                <NavItem
                  icon={<GiMeepleGroup />}
                  active={
                    location.pathname === '/admindashboard/batch'
                      ? activeTab
                      : ''
                  }
                  label="Batch"
                />
              </Link>

              </NavGroup>

              

              <NavGroup label="User Governance">

                <Link to="/admindashboard/group">
                  <NavItem
                    icon={<TiGroup />}
                    label="Groups"
                    active={
                      location.pathname === '/admindashboard/group'
                        ? activeTab
                        : ''
                    }
                  />
                </Link>
               
                <Link to="/admindashboard/user">
                  <NavItem
                    icon={<ImUsers />}
                    active={
                      location.pathname === '/admindashboard/user'
                        ? activeTab
                        : ''
                    }
                    label="Users"
                  />
                </Link>
              </NavGroup>

              <NavGroup label="Groups & Permissons">

              <Link to="/admindashboard/features">
                <NavItem
                  icon={<TiGroup />}
                  label="Features"
                  active={
                    location.pathname === '/admindashboard/features'
                      ? activeTab
                      : ''
                  }
                />
              </Link>
                <Link to="/admindashboard/roles">
                  <NavItem
                    icon={<TiGroup />}
                    label="Roles"
                    active={
                      location.pathname === '/admindashboard/roles'
                        ? activeTab
                        : ''
                    }
                  />
                </Link>

               
                <Link to="/admindashboard/user">
                  <NavItem
                    icon={<ImUsers />}
                    active={
                      location.pathname === '/admindashboard/user'
                        ? activeTab
                        : ''
                    }
                    label="Permissions"
                  />
                </Link>
              </NavGroup>

              <NavGroup label="Qualification Config.">

                <Link to="/admindashboard/qualification">
                  <NavItem
                    icon={<GiAchievement />}
                    active={
                      location.pathname === '/admindashboard/qualification'
                        ? activeTab
                        : ''
                    }
                    label="Qualifications"
                  />
                </Link>

                <Link to="/admindashboard/specialization">
                  <NavItem
                    icon={<GiAchievement />}
                    active={
                      location.pathname === '/admindashboard/specialization'
                        ? activeTab
                        : ''
                    }
                    label="Specialization"
                  />
                </Link>

              </NavGroup>
            </Stack>
            {/*<Box>
              <Divider />
              <Stack spacing="1">
                <Button
                  size="md"
                  fontSize="lg"
                  aria-label={`Switch to ${text} mode`}
                  variant="outline"
                  // color="current"
                  my={3}
                  py={3}
                  ml={{ base: '0', md: '3' }}
                  onClick={toggleMode}

                >
                  {SwitchIcon}
                </Button>
                <NavItem subtle icon={<BiCog />} label="Settings" />
                <NavItem
                  subtle
                  icon={<BiBuoy />}
                  label="Help & Support"
                  endElement={<Circle size="2" bg="blue.400" />}
                />
              </Stack>
                  </Box> */}
          </Flex>
        </Box>
        <Box bg={mode('white', 'gray.800')} flex="1" p="6">
          <Box
            w="full"
            h="full"
            rounded="lg"
            border="3px dashed currentColor"
            color={mode('gray.200', 'gray.700')}
            overflowY="scroll"
          >
            <Outlet />
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};
export default SideNav;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  productsView: [],
  isProductsViewLoading: false,
  error: "",
};

const getAllProductsViewSlice = createSlice({
  name: "productsView",
  initialState,
  reducers: {
    getAllProductsViewPending: (state) => {
      state.isProductsViewLoading = true;
    },
    getAllProductsViewSuccess: (state, { payload }) => {
      state.isProductsViewLoading = false;
      state.productsView = payload;
      state.error = "";
    },
    getAllProductsViewFail: (state, { payload }) => {
      state.isProductsViewLoading = false;
      state.error = payload;
    },
  },
});

const { reducer, actions } = getAllProductsViewSlice;
export const { getAllProductsViewPending, getAllProductsViewSuccess, getAllProductsViewFail } =
  actions;

export default reducer;

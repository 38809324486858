import { Box, Flex, Heading, Img, Text } from '@chakra-ui/react'
import React from 'react'
import CertificateImageSample from '../../../../assets/images/example.jpeg';
import { colorConfig } from '../../../../../colorConfig';
import CustomList from '../../../../common/CustomList';

const CertificateSection = () => {
    return (
        <Box>
            <Box paddingTop={2} paddingBottom={2} marginTop={3} marginBottom={3} maxW={{ base: 200, md: 500, lg: 750 }}>
                <Heading marginTop={2} marginBottom={2}>Your Certificate</Heading>
            </Box>
            <Flex>
                <Img margin={2} borderColor={colorConfig.primary} borderWidth={1} border={'solid'} src={CertificateImageSample} minW={200} width={{ base: 200, md: 280 }} />
                <Flex padding={2} margin={2} flexDirection={'column'} rowGap={3}>
                    <Text as={'b'} letterSpacing={3} fontSize={'lg'}>redhood.ai Certificate</Text>
                    <Box maxW={400}>
                        <CustomList />
                    </Box>
                </Flex>
            </Flex>

        </Box >
    )
}

export default CertificateSection
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  batches: [],
  getAllBatchLoading: false,
  getAllBatchError: "",
};

const getAllBatchSlice = createSlice({
  name: "Batch",
  initialState,
  reducers: {
    getAllBatchPending: (state) => {
      state.getAllBatchLoading = true;
    },
    getAllBatchSuccess: (state, { payload }) => {
      state.getAllBatchLoading = false;
      state.batches = payload;
      state.getAllBatchError = "";
    },
    getAllBatchFail: (state, { payload }) => {
      state.getAllBatchLoading = false;
      state.getAllBatchError = payload;
    },
  },
});

const { reducer, actions } = getAllBatchSlice;
export const { getAllBatchPending, getAllBatchSuccess, getAllBatchFail } =
  actions;

export default reducer;

import { Box, HStack } from '@chakra-ui/react'
import * as React from 'react'
import { Step } from './Step'

const StepComponent = ({ current }) => {
	var noOfSteps = [{ label: "Add Product", value: "addProduct" },
	{ label: "Add Attributes", value: "addAttribute" },
	// { label: "Add Curriculum", value: "addCurriculum" }
];

	console.log("🚀 ~ file: StepComponent.js.jsx:6 ~ props:", current)
	return <Box
		mx="full"
		maxW="full"
		py="10"
		px={{
			base: '6',
			md: '8',
		}}
	>
		<nav aria-label="Progress steps">
			<HStack as="ol" listStyleType="none" spacing="0">
				{
					noOfSteps.map((step, index) => <Step key={step.value + index} isCurrent={current === step.value ? true : false}>{step.label}</Step>)

				}
				{/*<Step isCurrent = {props.current === "addProduct" ? true : false}>Add Product</Step>
        	<Step isCurrent = {props.current === "addAttribute" ? true : false}>Add Attributes</Step>
    	<Step isCurrent = {props.current === "addAttribute" ? true : false}>Add Curriculum</Step>*/}
			</HStack>
		</nav>
	</Box>
}
export default StepComponent;
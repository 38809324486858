import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  group_id: sessionStorage.getItem('group_id') ? +sessionStorage.getItem('group_id') : null,
  isAuth: sessionStorage.getItem("session_id") ? true : false,
  error: "",
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginPending: (state) => {
      state.isLoading = true;
    },
    loginSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.group_id = payload;
      state.isAuth = true;
      state.error = "";
    },
    loginFailed: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

const { reducer, actions } = loginSlice;
export const { loginPending, loginSuccess, loginFailed } = actions;
export default reducer;

import React, { useState } from 'react';
import {
  Image,
  // Box,
  // useColorModeValue,
  Stack,
  Heading,
  Text,
  Divider,
  ButtonGroup,
  Button,
  HStack,
  Card,
  CardBody,
  CardFooter,
  Skeleton,
  Flex,
  Tag,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import { PriceTag } from './PriceTag';
import { FavouriteButton } from './FavouriteButton';
import { colorConfig } from '../../../../colorConfig';

export const ProductCard = (props) => {
  const navigate = useNavigate();
  // const boxShadow = useColorModeValue('dark-lg', 'outline');
  const { product } = props;
  const { product_name, description, attributes } = product;
  const [selectedAttribute, setSelectedAttribute] = useState(attributes ? attributes[0] : {});

  // console.log("🚀 ~ file: ProductCard.js:31 ~ ProductCard ~ attributes:", attributes)
  // console.log("🚀 ~ file: ProductCard.js:31 ~ ProductCard ~ selectedAttribute.mrp:", selectedAttribute?.media[0])
  return (
    <Card maxW="sm">
      <CardBody colorscheme="blue" style={{ cursor: 'pointer' }}>
        <Image
          onClick={() => navigate(`/productdetails/${product.product_id}`, { state: product })}
          src={selectedAttribute?.media[0] ? selectedAttribute?.media[0] : null}
          alt={product_name}
          borderRadius="lg"
          fallback={<Skeleton />}
        />
        <Stack mt="6" spacing="3">
          <Heading size="md" onClick={() => navigate(`/productdetails/${product.product_id}`, { state: product })}>{product_name}</Heading>
          <Text noOfLines={[1, 2, 3]}>
            {description}
          </Text>
          <HStack mt="6" spacing="3">
            {attributes?.map((element, index) => {
              return (
                <Tag size={'sm'} key={index + element.value} variant={selectedAttribute?.attribute_id === element.attribute_id ? 'solid' : 'outline'}
                  color={selectedAttribute?.attribute_id === element.attribute_id ? colorConfig.primaryBackgroundColor : colorConfig.textColorDark} backgroundColor={selectedAttribute?.attribute_id === element.attribute_id ? 'grey.200' : 'grey.700'} onClick={() => { setSelectedAttribute(element) }}>
                  {element.value}
                </Tag>
              )
            })
            }
          </HStack>

          <HStack justify={'stretch'} spacing="3">

            <PriceTag price={selectedAttribute?.mrp} salePrice={''} currency="INR" />
            <FavouriteButton position="relative" right="1" aria-label={`Add ${product_name} to your favourites`} />
          </HStack>
        </Stack>
      </CardBody>
      <Divider />
      <CardFooter>
        <Flex justify={'space-between'}>
          <ButtonGroup spacing="4">
            <Button background={colorConfig.primary} color={colorConfig.primaryBackgroundColor} _hover={{ background: colorConfig.secondary }} onClick={() => navigate(`/productdetails/${product.product_id}`, { state: product })} variant="solid" >
              Buy now
            </Button>
            <Button onClick={() => navigate(`/productdetails/${product.product_id}`, { state: product })} colorscheme={colorConfig.secondary} style={{ cursor: 'pointer' }} variant="outline" >
              Add to cart
            </Button>
          </ButtonGroup>
        </Flex>
      </CardFooter>
    </Card>
  );
};

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateAttributeSuccessful: false,
  updateAttributeIsLoading: false,
  updateAttributeError: "",
  updateAttributeMessage:"",
};

const updateAttributeSlice = createSlice({
  name: "updateAttribute",
  initialState,
  reducers: {
    updateAttributePending: (state) => {
      state.updateAttributeIsLoading = true;
    },
    updateAttributeSuccess: (state, { payload }) => {
      state.updateAttributeIsLoading = false;
      state.updateAttributeSuccessful = true;
      state.updateAttributeError = "";
      state.updateAttributeMessage = payload;
    },
    updateAttributeFail: (state, { payload }) => {
    
      state.updateAttributeIsLoading = false;
      state.updateAttributeMessage = payload;
      state.updateAttributeError = true;
    },
  },
});

const { reducer, actions } = updateAttributeSlice;
export const { updateAttributePending, updateAttributeSuccess, updateAttributeFail } =
  actions;

export default reducer;

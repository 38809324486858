import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addSpecializationSuccessful: false,
  addSpecializationIsLoading: false,
  addSpecializationError: "",
  addSpecializationMessage:"",
};

const addSpecializationSlice = createSlice({
  name: "addSpecialization",
  initialState,
  reducers: {
    addSpecializationPending: (state) => {
      state.addSpecializationIsLoading = true;
    },
    addSpecializationSuccess: (state, { payload }) => {
      state.addSpecializationIsLoading = false;
      state.addSpecializationSuccessful = true;
      state.addSpecializationError = "";
      state.addSpecializationMessage = payload.message;
    },
    addSpecializationFail: (state, { payload }) => {
      state.addSpecializationIsLoading = false;
      state.addSpecializationError = payload.message;
    },
  },
});

const { reducer, actions } = addSpecializationSlice;
export const { addSpecializationPending, addSpecializationSuccess, addSpecializationFail } =
  actions;

export default reducer;

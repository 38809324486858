import { Box, Flex, HStack, Input, InputGroup, InputLeftElement, Spacer, Stack, Tab, TabList, Tabs, chakra, useColorModeValue } from '@chakra-ui/react'
import React from 'react'
import { AiOutlineSearch } from 'react-icons/ai'
import { colorConfig } from '../../../colorConfig'
import { useScroll } from 'framer-motion'
import { urlData } from './url-data';
import { NavLink } from 'react-router-dom'



// const NavLink = (props) => {
//     const { children } = props
//     return (
//         <Box
//             as="a"
//             px={2}
//             py={1}
//             rounded={'md'}
//             _hover={{
//                 textDecoration: 'none',
//                 bg: colorConfig.secondary,
//                 color: colorConfig.textColor
//             }}
//             href={'#'}>
//             {children}
//         </Box>
//     )
// }

const SecondaryNavigation = () => {

    const ref = React.useRef();
    const [y, setY] = React.useState(0);
    const { scrollY } = useScroll();
    const bg = useColorModeValue('white', 'gray.800');
    const { height = 0 } = ref.current ? ref.current.getBoundingClientRect() : {};
    React.useEffect(() => {
        return scrollY.onChange(() => setY(scrollY.get()));
    }, [scrollY]);
    return (
        <nav style={{ top: 0, position: 'sticky', width: "100%", backgroundColor: '#ffffff', zIndex: '10000' }}>
            <chakra.header

                ref={ref}
                shadow={y > height ? 'lg' : undefined}
                transition="box-shadow 0.2s"
                bg={bg}
                w="full"
                overflowY="hidden"
                zIndex={800}
                dropShadow={'lg'}
            >
                <chakra.div h="4.5rem" mx="auto" maxW="1200px">
                    <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        mx={2}
                        borderWidth={0}
                        direction={'row'}
                        overflowX="auto"
                    >

                        <Box pb={2}>
                            <Flex h={16} alignItems={'center'} gap={5}>
                                {urlData.map((link) => (
                                    <NavLink style={{ pointer: 'coursor' }} to={`${link.slug}`} key={link.pageName}>{link.pageName}</NavLink>
                                ))}
                            </Flex>
                        </Box>
                    </Flex>
                </chakra.div>
            </chakra.header>
        </nav>
    )
}

export default SecondaryNavigation
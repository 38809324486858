import { Button, Divider, Flex, Heading, Text, useColorModeValue } from '@chakra-ui/react'
import React, { useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import SidePanel from "./SidePanel/SidePanel"


const Roles = () => {
    const [openPanel, setOpenPanel] = useState(false);
    const [typeOfPanel, setTypeOfPanel] = useState('');
    const [dataForPanel, setDataForPanel] = useState([]);
    const openSidePanel = (data = [], type) => {
        if (openPanel) {
            // fetchAll();
        }
        setTypeOfPanel(type);
        setDataForPanel(data);
        setOpenPanel(!openPanel);
    }
    const closeSidePanel = (data = [], type) => {
        if (openPanel) {
        //   fetchAllProducts();
          // setOpenAttributePanel(true);
        }
        setOpenPanel(!openPanel);
        setTypeOfPanel(type);
        setDataForPanel(data);
      };
    return (
        <Flex m={{ base: 3, md: 6 }} direction={['column']}>
            <Flex direction="row" justifyContent={'space-between'}>
                <Heading color={useColorModeValue('gray.700', 'white')}>
                    Roles
                </Heading>
                <Button
                    variant={'outline'}
                    rightIcon={
                        <AiOutlinePlus color={useColorModeValue('#ae0000', 'white')} />
                    }
                    rounded="full"
                    onClick={() => openSidePanel([], 'add')}
                    color={useColorModeValue('gray.700', 'white')}
                >
                    <Text fontWeight={'bold'}>Add Feature</Text>
                </Button>
            </Flex>
            <Divider marginY={{ base: 3, md: 5 }} />
            {openPanel && (
                <SidePanel
                  isOpen={openPanel}
                  onClose={() => closeSidePanel()}
                  type={typeOfPanel}
                  data={dataForPanel}
                />
              )}
        </Flex>
    )
}

export default Roles
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Grid,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Stack,
  Text,
  Checkbox,
  CheckboxGroup,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { Select } from '@chakra-ui/react';
import { FiSearch } from 'react-icons/fi';

import React, { useState, useEffect } from 'react';

import { MobileFilter } from './MobileFilter';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ProductCard } from '../../Pages/client/Courses/ProductCard';

import {
  getAllProductsViewPending,
  getAllProductsViewSuccess,
  getAllProductsViewFail,
} from '../../services/slice/getAllProductsViewSlice';
import {
  getAllCategoryPending,
  getAllCategorySuccess,
  getAllCategoryFail,
} from '../../services/slice/getAllCategorySlice';

import {
  getAllMediaPending,
  getAllMediaSuccess,
  getAllMediaFail,
} from '../../services/slice/getAllMediaSlice';

import {
  getAllAttributesPending,
  getAllAttributesSuccess,
  getAllAttributesFail,
} from '../../services/slice/getAllAttributeSlice';

import { getAllProductViewApi } from '../../services/api/product-api';
import { getAllCategoryApi } from '../../services/api/category-api';
import { getAllAttributeApi } from '../../services/api/attribute-api';
import { getAllMediaApi } from '../../services/api/media-api';
import CustomSpinner from '../CustomSpinner';
import { colorConfig } from '../../../colorConfig';

const Filter = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  console.log(
    '🚀 ~ file: Filter.js:66 ~ Filter ~ params:',
    params.search_term,
    location
  );
  const [searchInput, setSearchInput] = useState(
    location.pathname.split('/')[2] === 'search' ? params.search_term : ''
  );
  const [combiedArrayData, setCombiedArrayData] = useState([]);
  const [isMerging, setIsMerging] = useState(false);
  const [sortOrder, setSortOrder] = useState('low-to-high');

  const { productsView, isProductsViewLoading, error } = useSelector(
    state => state.getAllProductsView
  );
  const { categories, getAllCategoryLoading } = useSelector(
    state => state.category
  );

  const dispatch = useDispatch();

  var filteredData = combiedArrayData?.filter(element => {
    const { product_id, product_name, description, category_level_id } =
      element;
    return (
      product_name?.toLowerCase().indexOf(searchInput?.toLowerCase()) >= 0 ||
      product_id?.toString().indexOf(searchInput) >= 0 ||
      description?.toLowerCase().indexOf(searchInput?.toLowerCase()) >= 0 ||
      category_level_id?.toString().indexOf(searchInput) >= 0
    );
  });

  const getProducts = async () => {
    dispatch(getAllProductsViewPending());
    try {
      const getAllProduct = await getAllProductViewApi();

      dispatch(getAllProductsViewSuccess(getAllProduct));
    } catch (error) {
      if (error.message === 'Request failed with status code 401') {
        navigate('/signin', { replace: true });
      }
      dispatch(getAllProductsViewFail(error));
    }
  };

  const fetchAllCategories = async () => {
    dispatch(getAllCategoryPending());
    try {
      const getAllCategories = await getAllCategoryApi();

      dispatch(getAllCategorySuccess(getAllCategories));
    } catch (error) {
      if (error.message === 'Request failed with status code 401') {
        navigate('/signin', { replace: true });
      }
      dispatch(getAllCategoryFail(error));
    }
  };

  const fetchAllAttribute = async () => {
    dispatch(getAllAttributesPending());
    try {
      const getAllAtributes = await getAllAttributeApi();

      dispatch(getAllAttributesSuccess(getAllAtributes));
    } catch (error) {
      if (error.message === 'Request failed with status code 401') {
        navigate('/signin', { replace: true });
      }
      dispatch(getAllAttributesFail(error));
    }
  };

  const fetchAllMedia = async () => {
    dispatch(getAllMediaPending());
    try {
      const getAllMedia = await getAllMediaApi();

      dispatch(getAllMediaSuccess(getAllMedia));
    } catch (error) {
      if (error.message === 'Request failed with status code 401') {
        navigate('/signin', { replace: true });
      }
      dispatch(getAllMediaFail(error));
    }
  };

  const mergeArrayByProductIdAsync = async () => {
    setIsMerging(true);
    const mergedProducts = {};

    await Promise.all(
      productsView.map(async product => {
        const productId = product.product_id;
        if (!mergedProducts[productId]) {
          mergedProducts[productId] = {
            product_id: product.product_id,
            product_name: product.product_name,
            description: product.description,
            category_level_id: product.category_level_id,
            attributes: [
              {
                attribute_id: product.attribute_id,
                product_id: product.product_id,
                brand_id: product.brand_id,
                attribute_description: product.description,
                key: product.key,
                value: product.value,
                colour: product.colour,
                cost_price: product.cost_price,
                mrp: product.mrp,
                units: product.units,
                media: [product.media_path],
              },
            ],
          };
        } else {
          mergedProducts[productId].attributes.push({
            attribute_id: product.attribute_id,
            product_id: product.product_id,
            brand_id: product.brand_id,
            attribute_description: product.description,
            key: product.key,
            value: product.value,
            colour: product.colour,
            cost_price: product.cost_price,
            mrp: product.mrp,
            units: product.units,
            media: [product.media_path],
          });
        }
      })
    );

    const finalMergedProducts = Object.values(mergedProducts);
    setIsMerging(false);
    setCombiedArrayData(finalMergedProducts);
  };

  // const categoryFilteration = () => {
  //   setCategoryMapped()
  // }

  useEffect(() => {
    getProducts();
    fetchAllCategories();
  }, []);

  useEffect(() => {
    mergeArrayByProductIdAsync();
  }, [productsView]);

  const [checkedItems, setCheckedItems] = React.useState(
    categories.map(() => true)
  );

  // useEffect(() => {
  //   categoryFilteration()
  // }, [checkedItems])

  const sortByOptions = {
    defaultValue: 'best-seller',
    options: [
      {
        label: 'Price: Low to High',
        value: 'low-to-high',
      },
      {
        label: 'Price: High to Low',
        value: 'high-to-low',
      },
    ],
  };

  const allChecked = checkedItems.every(Boolean);
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;

  const onChangeHandler = (e, index) => {
    const newCategories = [...checkedItems];
    newCategories[index] = !newCategories[index];
    setCheckedItems(newCategories);
  };

  const handleSortChange = event => {
    setSortOrder(event.target.value);
  };

  const handleSelectAll = () => {
    const allSelected = checkedItems.every(category => category);
    const newCategories = categories.map(() => !allSelected);
    setCheckedItems(newCategories);
  };
  // const priceRangeChanger = (value) => {

  // }

  const sortedProducts = [...filteredData].sort((a, b) => {
    if (sortOrder === 'low-to-high') {
      return a.attributes[0]?.mrp - b.attributes[0]?.mrp;
    } else {
      return b.attributes[0]?.mrp - a.attributes[0]?.mrp;
    }
  });

  return (
    <Box
      maxW={'full'}
      mx={'auto'}
      px={{
        base: '4',
        md: '8',
        lg: '12',
      }}
      py={{
        base: '6',
        md: '8',
        lg: '12',
      }}
    >
      {(isProductsViewLoading || getAllCategoryLoading || isMerging) && (
        <CustomSpinner />
      )}

      <Box
        mt={{
          base: '8',
          md: '16',
        }}
      >
        <Grid
          templateColumns={{
            base: '1fr',
            md: '240px 1fr',
          }}
          gap="5"
        >
          <Stack
            padding={5}
            position={'sticky'}
            top={'4.5rem'}
            // boxShadow={'10px 0 5px -2px #888'}
            // borderRight={`1px solid ${colorConfig.primary}`}
            height="100vh"
            spacing="10"
            zIndex={100000}
            width="240px"
            // boxSizing='border-box'
            // position={'fixed'}
            display={{
              base: 'none',
              md: 'flex',
            }}
          >
            {/*<CheckboxFilter
              spacing="3"
              options={categories}
              label="Categories"
              showSearch={false}
              takeActionOnChange={categoryFilteration}
          />*/}
            <Stack as="fieldset" spacing={3}>
              <Checkbox
                isChecked={allChecked}
                isIndeterminate={isIndeterminate}
                colorScheme="red"
                color={mode(colorConfig.secondary, colorConfig.primary)}
                fontWeight={'semibold'}
                onChange={handleSelectAll}
              >
                All Categories
              </Checkbox>
              <CheckboxGroup>
                {categories.map((option, index) => {
                  return (
                    <Checkbox
                      key={
                        option.category_name + option.category_level_id + index
                      }
                      // value={option.category_level_id}
                      isChecked={checkedItems[index]}
                      colorScheme="red"
                      color={mode(colorConfig.secondary, colorConfig.primary)}
                      fontWeight={'semibold'}
                      onChange={e => onChangeHandler(e, index)}
                    >
                      <span>{option.category_name}</span>
                    </Checkbox>
                  );
                })}
              </CheckboxGroup>
            </Stack>
            {/*<SizePicker {...sizeFilter} label="Size" />
          <ColorPicker {...colorFilter} label="Color" />*/}
            <InputGroup size="md" pb="1">
              <Input
                placeholder={
                  location.pathname.split('/')[2] === 'search'
                    ? params.search_term
                    : 'Search'
                }
                rounded="md"
                focusBorderColor={mode(colorConfig.primary)}
                onChange={event => setSearchInput(event.target.value)}
              />
              <InputRightElement
                pointerEvents="none"
                color={colorConfig.secondary}
                fontSize="lg"
              >
                <FiSearch />
              </InputRightElement>
            </InputGroup>
            {/* <Stack spacing="5">
              <label>Price range</label>
              <PriceRangePicker onChange={value => priceRangeChanger(value)} min={100} max={10000} defaultValue={[100, 10000]} step={1} />
              <HStack spacing="6">
                <Input type="number" placeholder="100" />
                <Input type="number" placeholder="10000" />
              </HStack>
        </Stack>*/}
          </Stack>

          <Box width="full">
            <Stack
              spacing={{
                base: '6',
                md: '4',
              }}
              direction={{
                base: 'column',
                md: 'row',
              }}
              justify="space-between"
              align="flex-start"
              width="full"
            >
              <Stack
                direction={{
                  base: 'column',
                  md: 'row',
                }}
                align="baseline"
              >
                <Heading size="md" fontSize="2xl">
                  Courses
                </Heading>
              </Stack>
              <MobileFilter
                categories={categories}
                searchText={event => setSearchInput(event.target.value)}
              />
              <HStack
                display={{
                  base: 'none',
                  md: 'flex',
                }}
              >
                <Text
                  flexShrink={0}
                  color="gray.500"
                  fontSize="sm"
                  fontWeight={'extrabold'}
                >
                  Sort by
                </Text>
                <Select
                  size="sm"
                  aria-label="Sort by"
                  defaultValue={sortByOptions.defaultValue}
                  focusBorderColor={mode(colorConfig.primary)}
                  rounded="md"
                  onChange={handleSortChange}
                >
                  {sortByOptions.options.map(option => (
                    <option key={option.value} value={option.value}>
                      <Text fontStyle={'italic'}>{option.label}</Text>
                    </option>
                  ))}
                </Select>
              </HStack>
            </Stack>

            <Box
              maxW="full"
              mx={'auto'}
              py={{
                base: '6',
                md: '8',
                lg: '12',
              }}
            >
              <SimpleGrid
                spacing={4}
                templateColumns="repeat(auto-fill, minmax(250px, 1fr))"
              >
                {sortedProducts?.map((product, index) => {
                  const urlLocation = location.pathname.split('/')[2];

                  if (urlLocation === 'all' || urlLocation === 'search') {
                    const correspondingCategory = categories.find(category => {
                      return (
                        category.category_level_id === product.category_level_id
                      );
                    });

                    if (
                      correspondingCategory &&
                      checkedItems[correspondingCategory.category_level_id]
                    ) {
                      return (
                        <ProductCard
                          key={product?.product_id + '_' + index}
                          id={product.product_id + '_' + index}
                          product={product}
                        />
                      );
                    }
                  } else if (
                    urlLocation === product?.category_level_id.toString()
                  ) {
                    return (
                      <ProductCard
                        key={product?.product_id + '_' + index}
                        id={product.product_id + '_' + index}
                        product={product}
                      />
                    );
                  } else {
                    return;
                  }
                })}
              </SimpleGrid>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};
export default Filter;

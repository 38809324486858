import { Box, useColorModeValue, chakra, Flex } from '@chakra-ui/react'
import React from 'react'

const WishlistCard = (props) => {
  console.log("🚀 ~ file: WishlistCard.js:5 ~ WishlistCard ~ props:", props.data)
  const { product_name, description, mrp, attribute_id, media_path } = props.data;

  return (
    <Box
      bg={useColorModeValue('white', 'gray.800')}
      mx={{ lg: 8 }}
      display={{ lg: 'flex' }}
      maxW={{ lg: '8xl' }}
      width={{ lg: '4xl' }}
      shadow={{ lg: 'lg' }}
      rounded={{ lg: 'lg' }}
    >
      <Box w={{ lg: '50%' }}>
        <Box
          h={{ base: 64, lg: 'full' }}
          rounded={{ lg: 'lg' }}
          w={'full'}
          bgSize="cover"
          style={{
            backgroundImage:
              `url(${media_path})`,
          }}
        ></Box>
      </Box>

      <Box py={12} px={6} maxW={{ base: 'xl', lg: '5xl' }} w={{ lg: '50%' }}>
        <chakra.h1
          fontSize="2xl"
          fontWeight="bold"
          color={useColorModeValue('gray.800', 'white')}
        >
          {product_name}
        </chakra.h1>

        <chakra.p
          mt={2}
          fontSize="sm"
          color={useColorModeValue('gray.600', 'gray.400')}
        >
          {description}
        </chakra.p>

        {/*<HStack spacing={1} display="flex" alignItems="center" mt={2}>
        <StarIcon color={useColorModeValue("gray.700", "gray.300")} />
        <StarIcon color={useColorModeValue("gray.700", "gray.300")} />
        <StarIcon color={useColorModeValue("gray.700", "gray.300")} />
        <StarIcon color="gray.500" />
        <StarIcon color="gray.500" />
        </HStack>*/}

        <Flex mt={3} alignItems="center" justifyContent="space-between">
          <chakra.h1 color="black" fontWeight="bold" fontSize="lg">
            {mrp}
          </chakra.h1>
          <chakra.button
            px={2}
            py={1}
            bg="white"
            fontSize="xs"
            color="gray.900"
            fontWeight="bold"
            rounded="lg"
            textTransform="uppercase"
            _hover={{
              bg: 'gray.200',
            }}
            _focus={{
              bg: 'gray.400',
            }}
          >
            Add to cart
          </chakra.button>
        </Flex>
      </Box>
    </Box>
  )
}

export default WishlistCard
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  deleteBatchSuccessful: false,
  deleteBatchIsLoading: false,
  deleteBatchError: "",
  deleteBatchMessage:"",
};

const deleteBatchSlice = createSlice({
  name: "deleteBatch",
  initialState,
  reducers: {
    deleteBatchPending: (state) => {
      state.deleteBatchIsLoading = true;
    },
    deleteBatchSuccess: (state, { payload }) => {
      state.deleteBatchIsLoading = false;
      state.addBatchSuccessful = true;
      state.addBatchError = "";
      state.addBatchMessage = payload;
    },
    deleteBatchFail: (state, { payload }) => {
      state.deleteBatchIsLoading = false;
      state.addBatchError = payload;
    },
  },
});

const { reducer, actions } = deleteBatchSlice;
export const { deleteBatchPending, deleteBatchSuccess, deleteBatchFail } =
  actions;

export default reducer;

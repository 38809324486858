import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addMediaImagesSuccessful: false,
  addMediaImagesLoading: false,
  addMediaImagesError: "",
  addMediaImagesMessage:"",
};

const addMediaImagesSlice = createSlice({
  name: "addMediaImages",
  initialState,
  reducers: {
    addMediaImagesPending: (state) => {
      state.addMediaImagesLoading = true;
    },
    addMediaImagesSuccess: (state, { payload }) => {
      state.addMediaImagesLoading = false;
      state.addMediaImagesSuccessful = true;
      state.addMediaImagesError = "";
      state.addMediaImagesMessage = payload;
    },
    addMediaImagesFail: (state, { payload }) => {
      state.addMediaImagesLoading = false;
      state.addMediaImagesError = payload;
    },
  },
});

const { reducer, actions } = addMediaImagesSlice;
export const { addMediaImagesPending, addMediaImagesSuccess, addMediaImagesFail } =
  actions;

export default reducer;

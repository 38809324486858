import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  brands: [],
  getAllBrandLoading: false,
  getAllBrandError: "",
};

const getAllBrandSlice = createSlice({
  name: "Brand",
  initialState,
  reducers: {
    getAllBrandPending: (state) => {
      state.getAllBrandLoading = true;
    },
    getAllBrandSuccess: (state, { payload }) => {
      state.getAllBrandLoading = false;
      state.brands = payload;
      state.getAllBrandError = "";
    },
    getAllBrandFail: (state, { payload }) => {
      state.getAllBrandLoading = false;
      state.getAllBrandError = payload;
    },
  },
});

const { reducer, actions } = getAllBrandSlice;
export const { getAllBrandPending, getAllBrandSuccess, getAllBrandFail } =
  actions;

export default reducer;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  AbsoluteCenter,
  Box,
  Button,
  chakra,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Stack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useNavigationType } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

//import from local files/components
import CustomSpinner from '../../../../common/CustomSpinner';
import {
  loginFailed,
  loginPending,
  loginSuccess,
} from '../../../../services/slice/loginSlice';
import { userLogin } from '../../../../services/api/authApi';
import { colorConfig } from '../../../../../colorConfig';

export const LoginForm = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, isAuth } = useSelector(state => state.login);
  const navigationType = useNavigationType();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { isOpen, onToggle } = useDisclosure();
  const inputRef = React.useRef(null);

  const onSubmit = async data => {
    dispatch(loginPending());
    var userData = new URLSearchParams();
    userData.append('username', data.email);
    userData.append('password', data.password);

    try {
      const isAuthenticated = await userLogin(userData);
      dispatch(loginSuccess(isAuthenticated.group_id));
      isAuthenticated.group_id === 4
        ? (navigationType === "REPLACE" ? navigate(-1) : navigate('/', { replace: true }))
        : navigate('/admindashboard', { replace: true });
    } catch (error) {
      dispatch(loginFailed(error.message));
    }
  };

  useEffect(()  => {
    if (isAuth) {
    parseInt(sessionStorage.getItem('group_id')) === 4
        ? navigate('/', { replace: true })
        : navigate('/admindashboard', { replace: true });
    }
  }, []);

  return (
    <chakra.form onSubmit={handleSubmit(onSubmit)} {...props}>
      {isLoading && (
        <AbsoluteCenter>
          <CustomSpinner />
        </AbsoluteCenter>
      )}
      <Stack spacing="6">
        <FormControl id="email">
          <FormLabel>Email address</FormLabel>
          <Input
            name="email"
            {...register('email', { required: true })}
            type="email"
            autoComplete="email"
            required
            focusBorderColor={colorConfig.primary}
          />
        </FormControl>
        <FormControl id="password">
          <Flex justify="space-between">
            <FormLabel>Password</FormLabel>
            <Box
              as="a"
             
              color={useColorModeValue(colorConfig.primary)}
              fontWeight="semibold"
              fontSize="sm"
            >
              Forgot Password?
            </Box>
          </Flex>
          <InputGroup>
            <Input
            focusBorderColor={colorConfig.primary}
              name="password"
              type={isOpen ? 'text' : 'password'}
              autoComplete="current-password"
              {...register('password', { required: true })}
              required
              {...props}
            />
          </InputGroup>
        </FormControl>
        <Button
          type="submit"
          backgroundColor={colorConfig.primary}
          color={'white'}
          size="lg"
          isLoading={isLoading}
          fontSize="md"
          _hover={{backgroundColor: colorConfig.textColorDark, color:colorConfig.primaryBackgroundColor, size:"300px"}}
        >
          Sign in
        </Button>
      </Stack>
    </chakra.form>
  );
};

import axios from 'axios';
import {
  ADD_BATCH_API,
  UPDATE_BATCH_API,
  GET_ALL_BATCH_API,
  DELETE_BATCH_API,
} from './generic-api';

export const getAllBatchApi = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(GET_ALL_BATCH_API, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
        },
      });
      
      resolve(response.data);
    } catch (error) {
      console.log("🚀 ~ file: batch-api.js:20 ~ returnnewPromise ~ error:", error)
      reject(error);
    }
  });
};
export const addBatchApi = payload => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(ADD_BATCH_API, payload, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
        },
      });
      
      
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};
export const updateBatchApi = payLoadData => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.patch(UPDATE_BATCH_API, payLoadData, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
          'Access-Control-Allow-Origin': '*',
          withCredentials: false,
        },
      });
      resolve(response.data);
    } catch (error) {
    
      
      reject(error);
    }
  });
};

export const deleteBatchApi = payLoadData => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(DELETE_BATCH_API, payLoadData, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
          'Access-Control-Allow-Origin': '*',
        },
      });
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

import { List, ListIcon, ListItem } from '@chakra-ui/react'
import React from 'react'
import { MdCheckCircle, MdSettings } from 'react-icons/md'
import { colorConfig } from '../../../colorConfig'

const CustomList = () => {
    return (
        <List spacing={3} >
            <ListItem>
                <ListIcon as={MdCheckCircle} color={colorConfig.primary} />
                Feel Proud of yourself you can only achive this by completing the assesment for the course.
            </ListItem>
            <ListItem>
                <ListIcon as={MdCheckCircle} color={colorConfig.primary} />
                Share this on LinkedIn or Use it for your resume.
            </ListItem>
            <ListItem>
                <ListIcon as={MdCheckCircle} color={colorConfig.primary} />
                Show off your skills.
            </ListItem>
            {/* You can also use custom icons from react-icons */}

        </List>
    )
}

export default CustomList
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userDetail: [],
  getUserByIdLoading: false,
  getUserByIdError: "",
};

const getUserByIdSlice = createSlice({
  name: "UserDetails",
  initialState,
  reducers: {
    getUserByIdPending: (state) => {
      state.getUserByIdLoading = true;
    },
    getUserByIdSuccess: (state, { payload }) => {
      state.getUserByIdLoading = false;
      state.userDetail = payload;
      state.getUserByIdError = "";
    },
    getUserByIdFail: (state, { payload }) => {
      state.getUserByIdLoading = false;
      state.getUserByIdError = payload;
    },
  },
});

const { reducer, actions } = getUserByIdSlice;
export const { getUserByIdPending, getUserByIdSuccess, getUserByIdFail } =
  actions;

export default reducer;

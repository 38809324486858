export const urlData = [
    {
        "pageName": "Consultation",
        "slug": "/seo-services/consultation",
        "id": 1
    },
    {
        "pageName": "Monthly Service",
        "slug": "/seo-services/monthly-service",
        "id": 2
    },
    {
        "pageName": "Website Audit",
        "slug": "/seo-services/website-audit",
        "id": 3
    },
    {
        "pageName": "Website Migration Redesign",
        "slug": "/seo-services/website-migration-redesign",
        "id": 4
    },
    {
        "pageName": "Local",
        "slug": "/seo-services/local",
        "id": 5
    },
    {
        "pageName": "International",
        "slug": "/seo-services/international",
        "id": 6
    },
    {
        "pageName": "E-commerce",
        "slug": "/seo-services/e-commerce",
        "id": 7
    },
    {
        "pageName": "Content",
        "slug": "/seo-services/content",
        "id": 8
    },
    {
        "pageName": "FAQ's",
        "slug": "/seo-services/faqs",
        "id": 9
    }
];

import { AbsoluteCenter, Box, Center, Modal, ModalBody, ModalContent, ModalOverlay, Spinner, useDisclosure } from '@chakra-ui/react';
import React from 'react';

const CustomSpinner = () => {
  // const { isOpen, onOpen, onClose } = useDisclosure()

  const OverlayOne = () => (
    <ModalOverlay
      bg='teal.700'

      backdropFilter="auto" backdropBlur="6px"
    />
  )
  return (


    <Modal isCentered isOpen={true}>
      {OverlayOne}
      <ModalContent h="59px" w="58px" borderRadius={"100px"}>
        <Center>
          <ModalBody>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="#ae0000"
              size="xl"
            />
          </ModalBody>
        </Center>
      </ModalContent>
    </Modal>

  );
};

export default CustomSpinner;



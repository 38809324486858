import axios from "axios";
import {
  GET_ALL_USER_API,
  ADD_USER_API,
  GET_ALL_GROUPS_API,
  DELETE_USER_API,
  ADD_GROUP_API,
  DELETE_GROUP_API,
  UPDATE_GROUP_API,
  UPDATE_USER_API,
  GET_USER_BY_ID_API
} from "./generic-api";

export const getAllUsersApi = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(GET_ALL_USER_API, {
        headers: {
          session_id: sessionStorage.getItem("session_id"),
        },
      });
      
      resolve(response.data);
    } catch (error) {
      reject(error.message);
    }
  });
};

export const getUserById = payload => {
  console.log("🚀 ~ file: users-api.js:30 ~ getUserById ~ payload:", payload)
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        GET_USER_BY_ID_API + payload + '/select',
        {
          headers: {
            session_id: sessionStorage.getItem('session_id'),
          },
        }
      );

      resolve(response.data);
    } catch (error) {
      reject(error.message);
    }
  })
}

export const addUserApi = (payLoadData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(ADD_USER_API, payLoadData, {
        headers: {
          session_id: sessionStorage.getItem("session_id"),
          "Access-Control-Allow-Origin": "*",
        },
      });
      
      resolve(response.data);
    } catch (error) {
      reject(error.message);
    }
  });
};

export const getAllGroupApi = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(GET_ALL_GROUPS_API, {
        headers: {
          session_id: sessionStorage.getItem("session_id"),
        },
      });
     
      resolve(response.data);
    } catch (error) {
      reject(error.message);
    }
  });
};

export const addGroupApi = (payLoadData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(ADD_GROUP_API, payLoadData, {
        headers: {
          session_id: sessionStorage.getItem("session_id"),
        },
      });
      resolve(response.data);
    } catch (error) {
      reject(error.message);
    }
  });
};

export const deleteUserApi = (payLoadData) => {
  
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(DELETE_USER_API, payLoadData, {
        headers: {
          session_id: sessionStorage.getItem("session_id"),
          "Access-Control-Allow-Origin": "*",
        },
      });
      resolve(response);
    } catch (error) {
      reject(error.message);
    }
  });
};

export const deleteGroupApi = (payLoadData) => {
  
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(DELETE_GROUP_API, payLoadData, {
        headers: {
          session_id: sessionStorage.getItem("session_id"),
          "Access-Control-Allow-Origin": "*",
        },
      });
      resolve(response);
    } catch (error) {
      reject(error.message);
    }
  });
};

export const updateUserApi = payLoadData => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.patch(UPDATE_USER_API, payLoadData, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
          'Access-Control-Allow-Origin': '*',
          withCredentials: false,
        },
      });

      resolve(response.data);
      // }
    } catch (error) {
      reject(error.message);
    }
  });
};

export const updateGroupApi = (payLoadData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.patch(UPDATE_GROUP_API, payLoadData, {
        headers: {
          session_id: sessionStorage.getItem("session_id"),
          "Access-Control-Allow-Origin": "*",
        },
      });

      resolve(response);
      // }
    } catch (error) {
      reject(error.message);
    }
  });
};

import {
  Checkbox,
  CheckboxGroup,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import React, {useEffect} from 'react';
import { FiSearch } from 'react-icons/fi';
import { colorConfig } from '../../../colorConfig';

export const CheckboxFilter = props => {
  const {
    options,
    label,
    hideLabel,
    spacing = '2',
    showSearch,
    ...rest
  } = props;
  // const mockData = ["label", "hideLabel", "spacing", "showSearch"];
  
  const [checkedItems, setCheckedItems] = React.useState(
    options.map(() => true)
  );
  
  useEffect(() => {
    let filteredCategory = options.filter((option, index) => {
      if(checkedItems[index] === true){
        return option;
      }
    })
    
    props.takeActionOnChange(checkedItems)
  }, [checkedItems, options, props])
  
  const allChecked = checkedItems.every(Boolean);
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;
  
  const onChangeHandler = (e, index) => {
    setCheckedItems([
      ...checkedItems.slice(0, index),
      e.target.checked,
      ...checkedItems.slice(index + 1)
    ], );
    
    
  }

  return (
    <Stack as="fieldset" spacing={spacing}>
      {!hideLabel && (
        <FormLabel fontWeight="bold" fontSize={'xl'} as="legend" mb="0">
          {label}
        </FormLabel>
      )}
      {showSearch && (
        <InputGroup size="md" pb="1">
          <Input
            placeholder="Search..."
            rounded="md"
            focusBorderColor={mode('blue.500', 'blue.200')}
          />
          <InputRightElement
            pointerEvents="none"
            color="gray.400"
            fontSize="lg"
          >
            <FiSearch />
          </InputRightElement>
        </InputGroup>
      )}
      <Checkbox
        isChecked={allChecked}
        isIndeterminate={isIndeterminate}
        colorScheme='red'
      color={mode(colorConfig.secondary, colorConfig.primary)}
            fontWeight={'semibold'}
        onChange={(e) =>
          setCheckedItems(options.map(() => e.target.checked))
        }
      >
        All Categories
      </Checkbox>
      <CheckboxGroup {...rest}>
        {options.map((option, index) => {
          return (
            <Checkbox
              key={option.category_name + option.category_level_id + index}
              // value={option.category_level_id}
              isChecked={checkedItems[index]}
              colorScheme="red"
              color={mode(colorConfig.secondary, colorConfig.primary)}
            fontWeight={'semibold'}

            onChange={(e) => onChangeHandler(e, index)}
            >
              <span>{option.category_name}</span>
            </Checkbox>
            
          )
        })}
      </CheckboxGroup>
    </Stack>
  );
};


// const data = {
//   users: [
//     { id: "user-1", name: "User 1" },
//     { id: "user-2", name: "User 2" },
//     { id: "user-3", name: "User 3" },
//     { id: "user-4", name: "User 4" },
//     { id: "user-5", name: "User 5" },
//     { id: "user-6", name: "User 6" },
//     { id: "user-7", name: "User 7" },
//     { id: "user-8", name: "User 8" }
//   ]
// };

// export default function App() {
//   const [checkedItems, setCheckedItems] = React.useState(
//     data.users.map(() => false)
//   );

//   const allChecked = checkedItems.every(Boolean);
//   const isIndeterminate = checkedItems.some(Boolean) && !allChecked;

//   return (
//     <Stack pt={3} pl={3}>
//       <Checkbox
//         isChecked={allChecked}
//         isIndeterminate={isIndeterminate}
//         onChange={(e) =>
//           setCheckedItems(data.users.map(() => e.target.checked))
//         }
//       >
//         Parent Checkbox
//       </Checkbox>

//       <Stack pl={6} spacing={1}>
//         {data.users.map((user, index) => (
//           <Checkbox
//             key={user.id}
//             isChecked={checkedItems[index]}
//             onChange={(e) =>
//               setCheckedItems([
//                 ...checkedItems.slice(0, index),
//                 e.target.checked,
//                 ...checkedItems.slice(index + 1)
//               ])
//             }
//           >
//             {user.name}
//           </Checkbox>
          
//         ))}
//       </Stack>
//     </Stack>
//   );
// }

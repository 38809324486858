import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import {
  AbsoluteCenter,
  Button,
  Divider,
  Flex,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { AiOutlinePlus } from 'react-icons/ai';
import { HiOutlineSearch } from 'react-icons/hi';
import SideDrawer from './UserSideDrawer/SideDrawer';
import ProductQuickCards from '../../../../common/ProductCard/ProductQuickCards';

import CustomSpinner from '../../../../common/CustomSpinner';
import {
  deleteUserApi,
  getAllUsersApi,
} from '../../../../services/api/users-api';
import {
  getAllUsersPending,
  getAllUsersSuccess,
  getAllUsersFail,
} from '../../../../services/slice/userSlice';

import {
  deleteUserPending,
  deleteUserSuccess,
  deleteUserFail,
} from '../../../../services/slice/deleteUserSlice';

const User = () => {
  // const [searchText, setSearchText] = useState('');
  const {
    getAllUserLoading,
    users,
    // getAllUserError,
  } = useSelector(state => state.users);
  // const {
  // deleteUserSuccessful,
  // deleteUserIsLoading,
  // deleteUserError,
  // deleteUserMessage,
  // } = useSelector(state => state.deleteUser);

  const [openPanel, setOpenPanel] = useState(false);
  const [typeOfPanel, setTypeOfPanel] = useState('');
  const [dataForPanel, setDataForPanel] = useState([]);

  const [searchInput, setSearchInput] = useState('');
  const toast = useToast();

  const dispatch = useDispatch();
  // const setSeacrhText = val => {
  //   setSearchText(val);
  // };

  const fetchAllUsers = async () => {
    dispatch(getAllUsersPending());
    try {
      const getAllUsers = await getAllUsersApi();
      dispatch(getAllUsersSuccess(getAllUsers));
    } catch (error) {
      dispatch(getAllUsersFail(error));
    }
  };

  const openSidePanel = (data = [], type) => {
    console.log(
      '🚀 ~ file: User.js ~ line 59 ~ openSidePanel ~ type',
      type
    );
    console.log(
      '🚀 ~ file: User.js ~ line 59 ~ openSidePanel ~ data',
      data
    );

    if (openPanel) {
      fetchAllUsers();
    }
    setOpenPanel(!openPanel);
    setTypeOfPanel(type);
    setDataForPanel(data);
  };

  const filteredData = users.filter(element => {
    const { id, first_name, last_name } = element;

    return (
      first_name.toLowerCase().indexOf(searchInput.toLowerCase()) >= 0 ||
      last_name.toLowerCase().indexOf(searchInput.toLowerCase()) >= 0 ||
      id.toString().indexOf(searchInput) >= 0
    );
  });

  const deleteUser = async deleteData => {
    console.log(
      '🚀 ~ file: User.js ~ line 87 ~ User ~ deleteData',
      deleteData
    );
 
    dispatch(deleteUserPending());
    try {
      const deleteUser = await deleteUserApi(deleteData);

      dispatch(deleteUserSuccess(deleteUser));
      toast({
        title: `${deleteUser}`,
        position: 'top-right',
        status: 'success',
        isClosable: true,
      });

      fetchAllUsers();
    } catch (error) {
      console.log(
        '🚀 ~ file: groups.js ~ line 66 ~ onDeleteClick ~ error',
        error
      );
      dispatch(deleteUserFail(error));
      toast({
        title: `${error}`,
        position: 'top-right',
        status: 'error',
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex m={{ base: 3, md: 6 }} direction={['column']}>
      <Flex direction="row" justifyContent={'space-between'}>
        <Heading color={useColorModeValue('gray.700', 'white')}>
          All Users
        </Heading>
        <Button
          variant={'outline'}
          rightIcon={
            <AiOutlinePlus color={useColorModeValue('#ae0000', 'white')} />
          }
          rounded="full"
          onClick={() => openSidePanel([], 'add')}
          color={useColorModeValue('gray.700', 'white')}
        >
          <Text fontWeight={'bold'}>Add User</Text>
        </Button>
      </Flex>

      <Divider marginY={{ base: 3, md: 5 }} />
      <InputGroup w={{ base: 'full', md: 'md' }} marginY={3}>
        <InputRightElement>
          <IconButton
            color="#ae0000"
            aria-label="Call Segun"
            size="sm"
            borderRadius={'full'}
            icon={<HiOutlineSearch />}
          />
        </InputRightElement>
        <Input
          id="searchData"
          placeholder="search here.."
          name="searchData"
          type={'text'}
          autoComplete="current-searchData"
          required
          variant={'flushed'}
          onChange={event => setSearchInput(event.target.value)}
        />
      </InputGroup>

      <Flex
        direction={{ base: 'column', md: 'row' }}
        justifyContent="flex-start"
        wrap={'wrap'}
      >
        {(getAllUserLoading) && (
          <AbsoluteCenter>
            <CustomSpinner />
          </AbsoluteCenter>
        )}
        {filteredData.map((element, index) => {
          return (
            <ProductQuickCards
              key={index + element.id}
              action={element => openSidePanel(element, 'edit')}
              data={element}
              type={'users'}
              deleteProductCard={element => deleteUser(element)}
            />
          );
        })}
      </Flex>

      {openPanel && (
        <SideDrawer
          isOpen={openPanel}
          onClose={() => openSidePanel()}
          type={typeOfPanel}
          data={dataForPanel}
        />
      )}
    </Flex>
  );
};

export default User;

export const members = [
  {
    role: '',
    image:
      'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MzN8fGxhZHklMjBzbWlsaW5nfGVufDB8fDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
    name: 'Jayanth Venkatesh',
    twitter: 'jayanth',
    linkedin: 'https://www.linkedin.com/in/jayanth-venkatesh-seo/',
    github: '#',
    description: 'Specialties: Technical SEO audit, data analysis, on and off-page SEO strategy, gap analysis, and UX/UI.',
  },
  {
    role: '',
    image:
      'https://images.unsplash.com/photo-1573007974656-b958089e9f7b?ixid=MXwxMjA3fDB8MHxzZWFyY2h8Nnx8Z3V5JTIwc21pbGluZ3xlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
    name: 'Chethan V',
    twitter: 'chethan',
    linkedin: 'https://www.linkedin.com/in/chethanve/',
    github: 'https://github.com/ChethanVe',
    description: 'Red Hat® Certified Engineer (RHCE®) Red Hat® Certified Openstack Administrator (RHCA®)',
  },
  {
    role: '',
    image:
      'https://images.unsplash.com/photo-1521296797187-726205347ca9?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NTd8fGxhZHklMjBzbWlsaW5nfGVufDB8fDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
    name: 'Swagat Panda',
    twitter: 'swagat',
    linkedin: 'https://www.linkedin.com/in/swagat-panda576/',
    github: 'https://github.com/swagat-panda',
    description: "I have a diverse set of skills ranging from web-development, cloud, infrastructure management all the way to DevOps, Machine Learning and cognitive science."
  },
  {
    role: '',
    image:
      'https://images.unsplash.com/photo-1521296797187-726205347ca9?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NTd8fGxhZHklMjBzbWlsaW5nfGVufDB8fDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60',
    name: 'Samir Ahmad',
    twitter: 'samir',
    linkedin: 'https://www.linkedin.com/in/samir-ahmad-7b598614b/',
    github: 'https://github.com/codebysam',
    description: "Full stack web developer with creative problem solving skills, analytical and critical thinking. "
  }
]

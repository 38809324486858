import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  deleteBrandSuccessful: false,
  deleteBrandIsLoading: false,
  deleteBrandError: "",
  deleteBrandMessage:"",
};

const deleteBrandSlice = createSlice({
  name: "deleteBrand",
  initialState,
  reducers: {
    deleteBrandPending: (state) => {
      state.deleteBrandIsLoading = true;
    },
    deleteBrandSuccess: (state, { payload }) => {
      state.deleteBrandIsLoading = false;
      state.addBrandSuccessful = true;
      state.addBrandError = "";
      state.addBrandMessage = payload;
    },
    deleteBrandFail: (state, { payload }) => {
      state.deleteBrandIsLoading = false;
      state.addBrandError = payload;
    },
  },
});

const { reducer, actions } = deleteBrandSlice;
export const { deleteBrandPending, deleteBrandSuccess, deleteBrandFail } =
  actions;

export default reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateBatchSuccessful: false,
  updateBatchIsLoading: false,
  updateBatchError: "",
  updateBatchMessage:"",
};

const updateBatchSlice = createSlice({
  name: "updateBatch",
  initialState,
  reducers: {
    updateBatchPending: (state) => {
      state.updateBatchIsLoading = true;
    },
    updateBatchSuccess: (state, { payload }) => {
      state.updateBatchIsLoading = false;
      state.updateBatchSuccessful = true;
      state.updateBatchError = "";
      state.updateBatchMessage = payload;
    },
    updateBatchFail: (state, { payload }) => {
    
      state.updateBatchIsLoading = false;
      state.updateBatchMessage = payload;
      state.updateBatchError = true;
    },
  },
});

const { reducer, actions } = updateBatchSlice;
export const { updateBatchPending, updateBatchSuccess, updateBatchFail } =
  actions;

export default reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  deleteQualificationSuccessful: false,
  deleteQualificationIsLoading: false,
  deleteQualificationError: "",
  deleteQualificationMessage:"",
};

const deleteQualificationSlice = createSlice({
  name: "deleteQualification",
  initialState,
  reducers: {
    deleteQualificationPending: (state) => {
      state.deleteQualificationIsLoading = true;
    },
    deleteQualificationSuccess: (state, { payload }) => {
      state.deleteQualificationIsLoading = false;
      state.addQualificationSuccessful = true;
      state.addQualificationError = "";
      state.addQualificationMessage = payload;
    },
    deleteQualificationFail: (state, { payload }) => {
      state.deleteQualificationIsLoading = false;
      state.addQualificationError = payload;
    },
  },
});

const { reducer, actions } = deleteQualificationSlice;
export const { deleteQualificationPending, deleteQualificationSuccess, deleteQualificationFail } =
  actions;

export default reducer;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineSave, AiOutlineReload } from 'react-icons/ai';

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Progress,
  Select,
  Stack,
  Textarea,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  addAttributeApi,
  updateAttributeApi,
} from '../../services/api/attribute-api';

import { deleteMediaImagesApi } from '../../services/api/media-api';
import {
  addAttributePending,
  addAttributeSuccess,
  addAttributeFail,
} from '../../services/slice/addAttributeSlice';

import {
  deleteMediaImagesPending,
  deleteMediaImagesSuccess,
  deleteMediaImagesFail,
} from '../../services/slice/deleteMediaImagesSlice';

import {
  updateAttributePending,
  updateAttributeSuccess,
  updateAttributeFail,
} from '../../services/slice/updateAttributeSlice';
import Uploader from '../Uploader/Uploader';
import UploadModal from './UploadModal';
import { useNavigate } from 'react-router';

const AttributeForm = props => {
  const { product_id, type, dataValues } = props;
  const brandColor = useColorModeValue('#ae0000', 'white');
  const { isOpen, onToggle, onClose } = useDisclosure()

  const dispatch = useDispatch();
  const [attributeResponse, setAttributeResponse] = useState([{}]);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [image64, setImage64] = useState([]);

  const {
    addAttributeLoading,
    addAttributeSuccessful,
    addAttributeError,
    addAttributeMessage,
  } = useSelector(state => state.addAttribute);

  const {
    updateAttributeSuccessful,
    updateAttributeIsLoading,
    updateAttributeError,
    updateAttributeMessage,
  } = useSelector(state => state.updateAttribute);

  const {
    getAllBrandLoading,
    brands,
    // getAllBrandError,
  } = useSelector(state => state.getAllBrand);
  const {
    reset,
    register,
    handleSubmit,
    // eslint-disable-next-line no-unused-vars
    formState: { errors },
  } = useForm({

    defaultValues: {
      "attribute_id": dataValues.attribute_id,
      "product_id": dataValues.product_id,
      "brand_id": dataValues.brand_id,
      "attribute_description": dataValues.attribute_description,
      "key": dataValues.key,
      "value": dataValues.value,
      "colour": dataValues.colour,
      "cost_price": dataValues.cost_price,
      "mrp": dataValues.mrp,
      "units": dataValues.units,
    }, dataValues
  });
  console.log("🚀 ~ file: AttributeForm.js:87 ~ dataValues:", dataValues)

  const toast = useToast();
  const navigate = useNavigate();

  const onSubmit = async data => {
    console.log("🚀 ~ file: AttributeForm.js:74 ~ onSubmit ~ data:", data)

    data.images = image64;
    data.colour = "";
    dispatch(addAttributePending());

    try {
      const addAttribute = await addAttributeApi(data);


      dispatch(addAttributeSuccess('inserted'));
      toast({
        title: `Add Attribute ${addAttribute}`,
        position: 'top-right',
        status: 'success',
        isClosable: true,
      });
    } catch (error) {
      if (error.message === "Request failed with status code 401") {
        navigate('/signin', { replace: true })
      }

      dispatch(addAttributeFail(error.message));
      toast({
        title: `${addAttributeMessage}`,
        position: 'top-right',
        status: 'error',
        isClosable: true,
      });
      // props.onClose();
    }
  };
  console.log("🚀 ~ file: AttributeForm.js:62 ~ AttributeForm ~ brands:", brands)


  const onEditSubmit = async data => {
    data.colour = ""
    data.attribute_id = dataValues.attribute_id;
    dispatch(updateAttributePending());

    try {
      const updateAttribute = await updateAttributeApi(data);


      dispatch(addAttributeSuccess('inserted'));
      toast({
        title: `Add Attribute ${updateAttribute}`,
        position: 'top-right',
        status: 'success',
        isClosable: true,
      });
    } catch (error) {
      if (error.message === "Request failed with status code 401") {
        navigate('/signin', { replace: true })
      }

      dispatch(updateAttributeFail(error.message));
      toast({
        title: `${updateAttributeMessage}`,
        position: 'top-right',
        status: 'error',
        isClosable: true,
      });
      // props.onClose();
    }
  };

  const deleteAllMedia = async () => {
    dispatch(deleteMediaImagesPending());

    console.log("🚀 ~ file: AttributeForm.js:161 ~ deleteAllMedia ~ attribute_id:", dataValues.attribute_id)
    try {
      const updateAttribute = await deleteMediaImagesApi(dataValues.attribute_id);


      dispatch(deleteMediaImagesSuccess('Deleted Successfully'));
      toast({
        title: `Add Attribute ${updateAttribute}`,
        position: 'top-right',
        status: 'success',
        isClosable: true,
      });
    } catch (error) {

      if (error.message === "Request failed with status code 401") {
        navigate('/signin', { replace: true })
      }
      dispatch(deleteMediaImagesFail("Deletion Failed due to some error"));
      toast({
        title: "Deletion Failed due to some error",
        position: 'top-right',
        status: 'error',
        isClosable: true,
      });
      // props.onClose();
    }
  }

  const modalOpen = (event) => {
    event.preventDefault();
    setUploadModalOpen(!modalOpen);
  }

  // useEffect(() => { reset(props.data) }, [])

  return (
    <form
      onSubmit={
        (type === 'addNewAttribute')
          ? handleSubmit(onSubmit)
          : handleSubmit(onEditSubmit)
      }
    >
      <Stack spacing="6">
        {modalOpen && <UploadModal isOpen={isOpen} onClose={onClose} attributeId={product_id} />}
        <Box w={{ base: '100%', md: '100%' }}>
          <Flex direction={{ base: 'column', md: 'row' }}>
            <FormControl id="product_id" py="0" px="5">
              <FormLabel>Product ID</FormLabel>
              <Input
                type="text"

                isReadOnly
                name="product_id"
                {...register('product_id', { required: true })}
              />
            </FormControl>

            <FormControl id="brand_id" isRequired py="0" px="5">
              <FormLabel>Brand</FormLabel>
              <Select
                {...register('brand_id', { required: true })}
                placeholder="Select Brand"
                name="brand_id"

              >
                {brands.map((element, item) => {
                  // console.log("🚀 ~ file: AttributeForm.js:160 ~ {brands.map ~ element:", element)
                  return (
                    <option
                      key={item + element.brand_name}
                      value={element.brand_id}
                    >
                      {element.brand_name}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </Flex>
        </Box>
        <Box w={{ base: '100%', md: '100%' }}>
          <Flex direction={{ base: 'column', md: 'row' }}>
            <FormControl id="cost_price" py="0" px="5">
              <FormLabel>Cost Price</FormLabel>
              <Input
                name="cost_price"
                {...register('cost_price', { required: true })}
                type="number"
                required
                placeholder="Cost Price"

              />
            </FormControl>
            <FormControl id="mrp" py="0" px="5">
              <FormLabel>MRP</FormLabel>
              <Input
                name="mrp"
                {...register('mrp', { required: true })}
                type="number"
                autoComplete="number"
                required
                placeholder="number only"

              />
            </FormControl>
          </Flex>
        </Box>
        <Box w={{ base: '100%', md: '100%' }}>
          <Flex direction={{ base: 'column', md: 'row' }}>
            <FormControl id="key" isRequired py="0" px="5">
              <FormLabel>Key</FormLabel>
              <Input
                type="text"

                {...register('key', { required: true })}
              />
              <FormHelperText>Tags or key.</FormHelperText>
            </FormControl>

            <FormControl id="units" py="0" px="5">
              <FormLabel>Units</FormLabel>
              <Input
                name="units"
                {...register('units', { required: true })}
                type="number"
                required

              />
            </FormControl>
          </Flex>
        </Box>
        <FormControl id="value" isRequired py="0" px="5">
          <FormLabel>Value</FormLabel>
          <Input
            type="text"

            {...register('value', { required: true })}
          />
          <FormHelperText>give some string values.</FormHelperText>
        </FormControl>

        <FormControl id="attribute_description" isRequired py="0" px="5">
          <FormLabel>Attribute Description</FormLabel>
          <Textarea
            type="text"

            {...register('attribute_description', { required: true })}
          />
          <FormHelperText>give some string values.</FormHelperText>
        </FormControl>

        <FormControl id="value" py="0" px="5">
          <FormLabel>Add Images</FormLabel>
          <Button width='full'
            onClick={onToggle}
          >
            Upload
          </Button>

          <FormHelperText>upload some images.</FormHelperText>
        </FormControl>
        <FormControl id="value" py="0" px="5">
          <FormLabel>Delete Images</FormLabel>
          <Button width='full' colorScheme='red'
            onClick={deleteAllMedia}
          >
            Delete Media/Images
          </Button>

          <FormHelperText>delete all the images.</FormHelperText>
        </FormControl>


        <Button
          type="submit"
          leftIcon={
            addAttributeLoading ? (
              <Progress size="xs" isIndeterminate />
            ) : (
              <AiOutlineSave color={brandColor} />
            )
          }
          width='full'
        >
          Submit
        </Button>
        <Button
          type="submit"
          leftIcon={
            <AiOutlineReload color={brandColor} />
          }
          width='full'
        >
          {props.type === 'attributesUpdate' ? 'Reset' : 'Cancel'}
        </Button>
      </Stack>
    </form>
  );
};

export default AttributeForm;

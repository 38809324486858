import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addBatchSuccessful: false,
  addBatchLoading: false,
  addBatchError: "",
  addBatchMessage:"",
};

const addBatchSlice = createSlice({
  name: "addBatch",
  initialState,
  reducers: {
    addBatchPending: (state) => {
      state.addBatchLoading = true;
    },
    addBatchSuccess: (state, { payload }) => {
      state.addBatchLoading = false;
      state.addBatchSuccessful = true;
      state.addBatchError = "";
      state.addBatchMessage = payload;
    },
    addBatchFail: (state, { payload }) => {
      state.addBatchLoading = false;
      state.addBatchError = payload;
    },
  },
});

const { reducer, actions } = addBatchSlice;
export const { addBatchPending, addBatchSuccess, addBatchFail } =
  actions;

export default reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addQualificationSuccessful: false,
  addQualificationIsLoading: false,
  addQualificationError: "",
  addQualificationMessage:"",
};

const addQualificationSlice = createSlice({
  name: "addQualification",
  initialState,
  reducers: {
    addQualificationPending: (state) => {
      state.addQualificationIsLoading = true;
    },
    addQualificationSuccess: (state, { payload }) => {
      state.addQualificationIsLoading = false;
      state.addQualificationSuccessful = true;
      state.addQualificationError = "";
      state.addQualificationMessage = payload.message;
    },
    addQualificationFail: (state, { payload }) => {
      state.addQualificationIsLoading = false;
      state.addQualificationError = payload.message;
    },
  },
});

const { reducer, actions } = addQualificationSlice;
export const { addQualificationPending, addQualificationSuccess, addQualificationFail } =
  actions;

export default reducer;

import { ColorModeScript } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

import { ChakraProvider, theme } from '@chakra-ui/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { store } from './components/app/store';
import { Provider } from 'react-redux';
import ScrollToTop from './ScrollToTop';

ReactDOM.render(
  <Router>
    <ChakraProvider theme={theme}>
      <ColorModeScript />
      <Provider store={store}>
      <ScrollToTop />
        <App />
      </Provider>
    </ChakraProvider>
  </Router>,
  document.getElementById('root')
);
serviceWorker.unregister();
reportWebVitals();

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  groups: [],
  getAllGroupsLoading: false,
  getAllGroupsError: "",
};

const getAllGroupsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    getAllGroupsPending: (state) => {
      state.getAllGroupsLoading = true;
    },
    getAllGroupsSuccess: (state, { payload }) => {
      state.getAllGroupsLoading = false;
      state.groups = payload;
      state.getAllGroupsError = "";
    },
    getAllGroupsFail: (state, { payload }) => {
      state.getAllGroupsLoading = false;
      state.getAllGroupsError = payload;
    },
  },
});

const { reducer, actions } = getAllGroupsSlice;
export const { getAllGroupsPending, getAllGroupsSuccess, getAllGroupsFail } =
  actions;

export default reducer;

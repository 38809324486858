import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categories: [],
  getAllCategoryLoading: false,
  getAllCategoryError: "",
};

const getAllCategorySlice = createSlice({
  name: "Category",
  initialState,
  reducers: {
    getAllCategoryPending: (state) => {
      state.getAllCategoryLoading = true;
    },
    getAllCategorySuccess: (state, { payload }) => {
      state.getAllCategoryLoading = false;
      state.categories = payload;
      state.getAllCategoryError = "";
    },
    getAllCategoryFail: (state, { payload }) => {
      state.getAllCategoryLoading = false;
      state.getAllCategoryError = payload;
    },
  },
});

const { reducer, actions } = getAllCategorySlice;
export const { getAllCategoryPending, getAllCategorySuccess, getAllCategoryFail } =
  actions;

export default reducer;

import { Box, Button, chakra, Container, Flex, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { colorConfig } from '../../../../colorConfig';
import SecondaryNavigation from '../../../common/SecondaryNavigation/SecondaryNavigation';
import { OtherSectionIntroWrapper, StyledContainer, Wrapper } from '../LandingPage/styles';
import seoTteam from '../../../assets/images/seo-team.svg';

const OtherServices = () => {
    useEffect(() => {

    }, [])
    return (
        <Box pos="" overflow="auto">
            <Container maxW="container.xl">

                <SecondaryNavigation />
                {/* <Header /> */}
                <OtherSectionIntroWrapper as={StyledContainer}>
                    <Box>
                        <Box maxW="7xl" >
                            <Box
                                pos="relative"
                                pb={{
                                    base: 8,
                                    sm: 16,
                                    md: 20,
                                    lg: 28,
                                    xl: 32,
                                }}
                                w="full"
                                border="solid 1px transparent"
                            >
                                <Box display={'flex'} px={8} py={24} mx="auto">
                                    <Box
                                        w={{ base: "full", md: 11 / 12, xl: 9 / 12 }}
                                        mx="auto"
                                        textAlign={{ base: "left", md: "center" }}
                                    >
                                        <chakra.h1
                                            mb={6}
                                            fontSize={{ base: "4xl", md: "6xl" }}
                                            fontWeight="bold"
                                            lineHeight="none"
                                            letterSpacing={{ base: "normal", md: "tight" }}
                                            color="gray.900"
                                            _dark={{ color: "gray.100" }}
                                        >
                                            Do you need professional SEO services to boost your {" "}
                                            <Text
                                                display={{ base: "block", lg: "inline" }}
                                                w="full"
                                                bgClip="text"
                                                bgGradient={`linear(to-r, ${colorConfig.primary}, ${colorConfig.secondary})`}
                                                fontWeight="extrabold"
                                            >
                                                website's search engine
                                            </Text>{" "}
                                            rankings?
                                        </chakra.h1>
                                    </Box>
                                </Box>
                            </Box>

                        </Box>
                        {/*<SecondaryNavigation />*/}
                    </Box>
                </OtherSectionIntroWrapper>
                <Wrapper>
                    <Flex

                        w="full"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Box

                            bg="white"
                            _dark={{ bg: "gray.800" }}
                            px={8}
                            py={20}
                            mx="auto"
                        >
                            <SimpleGrid
                                alignItems="start"
                                columns={{ base: 1, md: 2 }}
                                mb={24}
                                spacingY={{ base: 10, md: 32 }}
                                spacingX={{ base: 10, md: 24 }}
                            >
                                <Box>
                                    {/*<chakra.h2
                                    mb={4}
                                    fontSize={{ base: "2xl", md: "4xl" }}
                                    fontWeight="extrabold"
                                    letterSpacing="tight"
                                    textAlign={{ base: "center", md: "left" }}
                                    color="gray.900"
                                    _dark={{ color: "gray.400" }}
                                    lineHeight={{ md: "shorter" }}
                                    textShadow="2px 0 currentcolor"
                                >
                                    Clear overview for efficient tracking
                                </chakra.h2>*/}
                                    <chakra.p
                                        mb={5}
                                        textAlign={{ base: "center", sm: "left" }}
                                        color="gray.600"
                                        _dark={{ color: "gray.400" }}
                                        fontSize={{ md: "lg" }}
                                    >
                                        Our dedicated SEO team in Bengaluru offers top-quality SEO solutions with measurable results. With a deep understanding of search engine algorithms, we can customize SEO strategies to meet your specific goals and improve your visibility on the search engine page results
                                    </chakra.p>
                                    <Button
                                        w={{ base: "full", sm: "auto" }}
                                        size="lg"
                                        bg="gray.900"
                                        _dark={{ bg: "gray.700" }}
                                        _hover={{ bg: "gray.700", _dark: { bg: "gray.600" } }}
                                        color="gray.100"
                                        as="a"
                                    >
                                        Learn More
                                    </Button>
                                </Box>
                                <Box
                                    w="full"
                                    h="full"
                                    bg="gray.200"
                                    _dark={{ bg: "gray.700" }}
                                ><img src={'https://images.pexels.com/photos/7688336/pexels-photo-7688336.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'} alt="WE PROVIDE EVERYTHING!" /></Box>
                            </SimpleGrid>
                            <SimpleGrid
                                alignItems="start"
                                columns={{ base: 1, md: 2 }}
                                flexDirection="column-reverse"
                                mb={24}
                                spacingY={{ base: 10, md: 32 }}
                                spacingX={{ base: 10, md: 24 }}
                            >
                                <Box order={{ base: "initial", md: 2 }}>
                                    {/*<chakra.h2
                                    mb={4}
                                    fontSize={{ base: "2xl", md: "4xl" }}
                                    fontWeight="extrabold"
                                    letterSpacing="tight"
                                    textAlign={{ base: "center", md: "left" }}
                                    color="gray.900"
                                    _dark={{ color: "gray.400" }}
                                    lineHeight={{ md: "shorter" }}
                                    textShadow="2px 0 currentcolor"
                                >
                                    Clear overview for efficient tracking
                                </chakra.h2>*/}
                                    <chakra.p
                                        mb={5}
                                        textAlign={{ base: "center", sm: "left" }}
                                        color="gray.600"
                                        _dark={{ color: "gray.400" }}
                                        fontSize={{ md: "lg" }}
                                    >
                                        We have extensive experience working with various industries, including healthcare, BFSI, legal, travel, hospitality, education, automotive, manufacturing, and technology. Search engines do not treat every industry and website equally, and we can help your business stand out from your competitors.
                                    </chakra.p>
                                    <Button
                                        w={{ base: "full", sm: "auto" }}
                                        size="lg"
                                        bg="gray.900"
                                        _dark={{ bg: "gray.700" }}
                                        _hover={{ bg: "gray.700", _dark: { bg: "gray.600" } }}
                                        color="gray.100"
                                        as="a"
                                    >
                                        Learn More
                                    </Button>
                                </Box>
                                <Box
                                    w="full"
                                    h="full"
                                    bg="gray.200"
                                    _dark={{ bg: "gray.700" }}
                                ><img src={'https://images.pexels.com/photos/3183179/pexels-photo-3183179.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'} alt="WE PROVIDE EVERYTHING!" /></Box>
                            </SimpleGrid> <SimpleGrid
                                alignItems="start"
                                columns={{ base: 1, md: 2 }}
                                mb={24}
                                spacingY={{ base: 10, md: 32 }}
                                spacingX={{ base: 10, md: 24 }}
                            >
                                <Box>
                                    {/*<chakra.h2
                                    mb={4}
                                    fontSize={{ base: "2xl", md: "4xl" }}
                                    fontWeight="extrabold"
                                    letterSpacing="tight"
                                    textAlign={{ base: "center", md: "left" }}
                                    color="gray.900"
                                    _dark={{ color: "gray.400" }}
                                    lineHeight={{ md: "shorter" }}
                                    textShadow="2px 0 currentcolor"
                                >
                                    Clear overview for efficient tracking
                                </chakra.h2>*/}
                                    <chakra.p
                                        mb={5}
                                        textAlign={{ base: "center", sm: "left" }}
                                        color="gray.600"
                                        _dark={{ color: "gray.400" }}
                                        fontSize={{ md: "lg" }}
                                    >
                                        We offer a wide range of services, including SEO consultations, monthly SEO services, website audits, assistance with website migrations, local SEO, international SEO services, and more. We ensure that all your SEO needs are covered.

                                    </chakra.p>
                                    <Button
                                        w={{ base: "full", sm: "auto" }}
                                        size="lg"
                                        bg="gray.900"
                                        _dark={{ bg: "gray.700" }}
                                        _hover={{ bg: "gray.700", _dark: { bg: "gray.600" } }}
                                        color="gray.100"
                                        as="a"
                                    >
                                        Learn More
                                    </Button>
                                </Box>
                                <Box
                                    w="full"
                                    h="full"
                                    bg="gray.200"
                                    _dark={{ bg: "gray.700" }}
                                ><img src={'https://images.pexels.com/photos/7688460/pexels-photo-7688460.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'} alt="WE PROVIDE EVERYTHING!" /></Box>
                            </SimpleGrid>
                        </Box>
                    </Flex>

                </Wrapper>
            </Container>
        </Box>
    )
}

export default OtherServices
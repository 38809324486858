import { Box, Heading, Text } from '@chakra-ui/react'
import React from 'react'
import CustomAccordion from '../../../../common/CustomAccordion/CustomAccordion'

const CourseModule = () => {
    return (
        <Box>
            <Box paddingTop={2} paddingBottom={2} marginTop={3} marginBottom={3} maxW={{ base: 200, md: 500, lg: 750 }}>
                <Heading marginTop={2} marginBottom={2}>Course Modules</Heading>
            </Box>
            <Box>
                <CustomAccordion />
            </Box>
        </Box >
    )
}

export default CourseModule
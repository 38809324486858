/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import Wave from 'react-wavify'

//importing libraries
import { Button, Heading, Container } from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import { Center } from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';
import { getDefaultMiddleware } from '@reduxjs/toolkit';
// importing components
import {
  Wrapper,
  IntroWrapper,
  Details,
  Thumbnail,
  StyledContainer,
} from './styles.js';
// import CardTable from '../../common/CardTable/CardTable';
// import ProductCard from '../../common/ProductCard';
import { bestCourses, recentlyAdded } from './Data';
import teach from '../../../assets/images/studing.svg';
import Card from '../../../common/QuickCards';
import Features from '../../../common/Features.js';
import Search from '../../../common/Search.js';
import { Team } from '../../../common/teams/Team.js';
import Contact from '../ContactMe/Contact.js';
import { getAllProductViewApi } from '../../../services/api/product-api';
import { getAllCategoryApi } from '../../../services/api/category-api';

import {
  getAllProductsViewPending,
  getAllProductsViewSuccess,
  getAllProductsViewFail,
} from '../../../services/slice/getAllProductsViewSlice';

import {
  getAllProductsFail,
  getAllProductsPending,
  getAllProductsSuccess,
} from '../../../services/slice/getAllProductsSlice';

import {
  getAllCategoryPending,
  getAllCategorySuccess,
  getAllCategoryFail,
} from '../../../services/slice/getAllCategorySlice';

import {
  getAllMediaPending,
  getAllMediaSuccess,
  getAllMediaFail,
} from '../../../services/slice/getAllMediaSlice.js';

import { getAllMediaApi } from '../../../services/api/media-api.js';
import { getAllAttributeApi } from '../../../services/api/attribute-api.js';
import { getAllAttributesFail, getAllAttributesPending, getAllAttributesSuccess } from '../../../services/slice/getAllAttributeSlice.js';
import { useState } from 'react';
import TextLoop from '@lossdev/react-text-loop';
import { colorConfig } from '../../../../colorConfig.js';
import CustomSpinner from '../../../common/CustomSpinner.js';

const LandingPage = () => {
  const navigate = useNavigate();

  const customizedMiddleware = getDefaultMiddleware({
    serializableCheck: false
  })

  const [isMerging, setIsMerging] = useState(false);

  const [combiedArrayData, setCombiedArrayData] = useState([]);
  const { allAttributes, getAllAttributesLoading } = useSelector(state => state.getAllAttribute);
  const { allMedia, getAllMediaLoading } = useSelector(state => state.getAllMedia);

  const { productsView, isProductsViewLoading, error } = useSelector(state => state.getAllProductsView);

  console.log("🚀 ~ file: LandingPage.js:79 ~ LandingPage ~ productsView:", productsView)

  const { getAllCategoryLoading, categories } = useSelector(state => state.category);

  const dispatch = useDispatch();

  const fetchAllProducts = async () => {
    dispatch(getAllProductsPending());
    try {
      const getAllProduct = await getAllProductViewApi();

      dispatch(getAllProductsViewSuccess(getAllProduct));
    } catch (error) {

      if (error.message === "Request failed with status code 401") {
        sessionStorage.clear();
        navigate('/signin', { replace: true })
      }
      dispatch(getAllProductsViewFail(error.message));
    }
  };

  const fetchAllCategories = async () => {
    dispatch(getAllCategoryPending());
    try {
      const getAllCategories = await getAllCategoryApi();
      dispatch(getAllCategorySuccess(getAllCategories));
    } catch (error) {
      if (error.message === "Request failed with status code 401") {
        navigate('/signin', { replace: true })
      }
      dispatch(getAllCategoryFail(error));
    }
  };

  const fetchAllMedia = async () => {
    dispatch(getAllMediaPending());
    try {
      const getAllMedia = await getAllMediaApi();

      dispatch(getAllMediaSuccess(getAllMedia));
    } catch (error) {
      if (error.message === "Request failed with status code 401") {
        sessionStorage.clear();
        navigate('/signin', { replace: true })
      }
      dispatch(getAllMediaFail(error));
    }
  };

  const fetchAllAttribute = async () => {
    dispatch(getAllAttributesPending());
    try {
      const getAllAtributes = await getAllAttributeApi();

      dispatch(getAllAttributesSuccess(getAllAtributes));
    } catch (error) {
      if (error.message === "Request failed with status code 401") {
        sessionStorage.clear();
        navigate('/signin', { replace: true })
      }
      dispatch(getAllAttributesFail(error));
    }
  };

  // const mergeResponses = async () => {


  //   const combinedArray = await Promise.all(
  //     productsView.map(async (product) => {
  //       const productAttributes = allAttributes
  //         .filter((attribute) => attribute.product_id === product.product_id)
  //         .map((attribute) => ({
  //           ...attribute,
  //           media: allMedia?.filter((m) => {
  //             console.log("🚀 ~ file: Filter.js:143 ~ .map ~ m:", m, attribute);
  //             return m.attribute_id === attribute.attribute_id
  //           })
  //             .map((m) => m.media_path),
  //         }));

  //       return {
  //         ...product,
  //         attributes: productAttributes,
  //       };
  //     })
  //   );
  //   setCombiedArrayData(combinedArray);

  // }

  const mergeArrayByProductIdAsync = async () => {
    setIsMerging(true);
    const mergedProducts = {};
    console.log("🚀 ~ file: LandingPage.js:158 ~ productsView?.map ~ productsView:", productsView)

    await Promise.all(
      productsView?.map(async (product) => {
        const productId = product.product_id;
        if (!mergedProducts[productId]) {
          mergedProducts[productId] = {
            product_id: product.product_id,
            product_name: product.product_name,
            description: product.description,
            category_level_id: product.category_level_id,
            attributes: [
              {
                attribute_id: product.attribute_id,
                product_id: product.product_id,
                brand_id: product.brand_id,
                attribute_description: product.description,
                key: product.key,
                value: product.value,
                colour: product.colour,
                cost_price: product.cost_price,
                mrp: product.mrp,
                units: product.units,
                media: [product.media_path],
              },
            ],
          };
        } else {
          mergedProducts[productId].attributes.push({
            attribute_id: product.attribute_id,
            product_id: product.product_id,
            brand_id: product.brand_id,
            attribute_description: product.description,
            key: product.key,
            value: product.value,
            colour: product.colour,
            cost_price: product.cost_price,
            mrp: product.mrp,
            units: product.units,
            media: [product.media_path],
          });
        }
      }));
    const finalMergedProducts = Object.values(mergedProducts);
    setIsMerging(false);
    setCombiedArrayData(finalMergedProducts);
  }


  useEffect(() => {
    fetchAllProducts();
    fetchAllCategories();
    fetchAllMedia();
    fetchAllAttribute();
  }, []);

  useEffect(() => {
    mergeArrayByProductIdAsync();
  }, [productsView, categories, allAttributes, allMedia])
  return (
    <div>
      <Wrapper>
        {(isProductsViewLoading || getAllCategoryLoading || isMerging) && <CustomSpinner />}
        {/* <Header /> */}
        <IntroWrapper as={StyledContainer}>
          <Details>
            <h1 bgGradient={`linear(to-a, ${colorConfig.primary} , ${colorConfig.secondary})`}>Welcome to redhood.ai</h1>
            <h4>

              <span>Your perfect place to get started with</span>


              {" "}
              <TextLoop springConfig={{ stiffness: 100, damping: 10 }}
                adjustingSpeed={200}>
                <span style={{ color: colorConfig.secondary, fontWeight: 'bold' }}>AI</span>
                <span style={{ color: colorConfig.secondary, fontWeight: 'bold' }}>Programming</span>
                <span style={{ color: colorConfig.secondary, fontWeight: 'bold' }}>Data Structure</span>
                <span style={{ color: colorConfig.secondary, fontWeight: 'bold' }}>Cloud</span>
                <span style={{ color: colorConfig.secondary, fontWeight: 'bold' }}>DevOps</span>
                <span style={{ color: colorConfig.secondary, fontWeight: 'bold' }}>Database</span>
                <span style={{ color: colorConfig.secondary, fontWeight: 'bold' }}>Operating System</span>
                <span style={{ color: colorConfig.secondary, fontWeight: 'bold' }}>Networking</span>
              </TextLoop>{" "}


            </h4>

            <Search />
          </Details>
          <Thumbnail>
            <img src={teach} alt="WE PROVIDE EVERYTHING!" />
          </Thumbnail>
        </IntroWrapper>
        {/*<Wave fill={colorConfig.primary}
          paused={false}
          style={{ display: 'flex' }}
          options={{
            height: 20,
            amplitude: 20,
            speed: 0.15,
            points: 2
          }}
        />*/}



      </Wrapper>


      <Flex direction={['column']}  >
        <Center py={7}>
          <Heading>Recently added.</Heading>
        </Center>
        <Container maxW="container.xl" >
          <Flex wrap={'wrap'} justify={'space-between'}>
            <Text fontSize="xl">Latest courses.</Text>
            <Link to="/allcourses/all">
              <Button
                rightIcon={<AiOutlineArrowRight />}
                color={colorConfig.primary}
                variant="link"
                to="/allcourses/all"
              >
                See all courses
              </Button>
            </Link>
          </Flex>
        </Container>

        <Card data={combiedArrayData} type="product" />
      </Flex>

      <Flex direction={['column']}>
        <Center py={7}>
          <Heading>Browse via Catagories.</Heading>
        </Center>
        <Container maxW="container.xl">
          <Flex wrap={'wrap'} justify={'space-between'}>
            <Text fontSize="xl">
              Not sure what you are looking for? Browse the category to get started.
            </Text>
            <Link to="/all-category">
              <Button
                rightIcon={<AiOutlineArrowRight />}
                color={colorConfig.primary}
                variant="link"
              >
                See all catagories
              </Button>
            </Link>
          </Flex>
        </Container>

        <Card data={categories} type="category" />
      </Flex>

      <Container id="about" maxW="container.xl">
        <Features />
      </Container>
      <Container id="our-team" maxW="container.xl">
        <Team />
      </Container>
      <Container id="contact" maxW="container.xl">
        <Contact />
      </Container>
    </div>
  );
};
export default LandingPage;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateBrandSuccessful: false,
  updateBrandIsLoading: false,
  updateBrandError: "",
  updateBrandMessage:"",
};

const updateBrandSlice = createSlice({
  name: "updateBrand",
  initialState,
  reducers: {
    updateBrandPending: (state) => {
      state.updateBrandIsLoading = true;
    },
    updateBrandSuccess: (state, { payload }) => {
      state.updateBrandIsLoading = false;
      state.updateBrandSuccessful = true;
      state.updateBrandError = "";
      state.updateBrandMessage = payload;
    },
    updateBrandFail: (state, { payload }) => {
    
      state.updateBrandIsLoading = false;
      state.updateBrandMessage = payload;
      state.updateBrandError = true;
    },
  },
});

const { reducer, actions } = updateBrandSlice;
export const { updateBrandPending, updateBrandSuccess, updateBrandFail } =
  actions;

export default reducer;

import React from 'react';

import { useSelector } from 'react-redux';
import Filter from '../../../common/Filter/Filter';


const Course = () => {
  // const [allProducts, setAllProducts] = useState([]);
  // const dispatch = useDispatch();

  // const getProducts = async () => {
  //   dispatch(getAllProductsPending());
  //   try {
  //     const getAllProduct = await getAllProductApi();
  //     setAllProducts(getAllProduct);
  //     dispatch(getAllProductsSuccess(getAllProduct));
  //   } catch (error) {
  //     dispatch(getAllProductsFail(error));
  //   }
  // };

  // useEffect(() => {
  //   getProducts();
  // },[]);

  // eslint-disable-next-line no-unused-vars
  const { products, isLoading } = useSelector(state => state.products);
  return (
    <Filter />
  )
}

export default Course;
/* eslint-disable react-hooks/rules-of-hooks */
import {
    Box,
    Button,
    Checkbox,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Progress,
    Select,
    Stack,
    Table,
    TableContainer,
    Tbody,
    Td,
    Textarea,
    Tfoot,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useToast
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineSave, AiOutlineReload } from 'react-icons/ai';

//   import {
//     addProductFail,
//     addProductPending,
//     addProductSuccess,
//   } from '../../services/slice/addProductSlice';

//   import {
//     updateProductPending,
//     updateProductSuccess,
//     updateProductFail,
//   } from '../../services/slice/updateProductSlice';

//   import {
//     addProductApi,
//     updateProductApi,
//   } from '../../services/api/product-api';
import { useForm } from 'react-hook-form';
import { colorConfig } from '../../../../../../colorConfig';

const SidePanel = props => {


    const { reset,
        register,
        handleSubmit,
        // eslint-disable-next-line no-unused-vars
        formState: { errors },
    } = useForm();

    const dispatch = useDispatch();

    // const {
    //   addProductLoading,
    //   // addProductSuccessful,
    //   // addProductError,
    //   addProductMessage,
    // } = useSelector(state => state.addProduct);

    // const {
    //   updateProductSuccessful,
    //   // updateProductIsLoading,
    //   // updateProductError,
    //   updateProductMessage,
    // } = useSelector(state => state.updateProduct);

    const toast = useToast();

    const onSubmit = async data => {


        //   dispatch(addProductPending());

        //   try {
        //     const addProduct = await addProductApi(data);
        //     console.log(
        //       '🚀 ~ file: SideDrawer.js ~ line 46 ~ addProduct',
        //       addProduct
        //     );
        //     dispatch(addProductSuccess('inserted'));
        //     toast({
        //       title: `Add Product ${addProductMessage}`,
        //       position: 'top-right',
        //       status: 'success',
        //       isClosable: true,
        //     })
        //     props.onClose();

        //   } catch (error) {

        //     dispatch(addProductFail(error.message));
        //     toast({
        //       title: `${addProductMessage}`,
        //       position: 'top-right',
        //       status: 'error',
        //       isClosable: true,
        //     })
        //     props.onClose();
        //   }
    };

    const onEditSubmit = async data => {

        //   dispatch(updateProductPending());

        //   try {
        //     const updateProduct = await updateProductApi(data);
        //     console.log(
        //       '🚀 ~ file: SideDrawer.js ~ line 89 ~ updateProduct',
        //       updateProduct
        //     );

        //     dispatch(updateProductSuccess('updated'));
        //     if (updateProductSuccessful) {
        //       toast({
        //         title: `${updateProductMessage}`,
        //         position: 'top-right',
        //         status: 'success',
        //         isClosable: true,
        //       })

        //     }
        //   } catch (error) {
        //     dispatch(updateProductFail(error));
        //     toast({
        //       title: `${error}`,
        //       position: 'top-right',
        //       status: 'error',
        //       isClosable: true,
        //     })

        //   }
    };

    // useEffect(() => {
    //     reset(props.data);
    // })

    return (
        <div>
            <Drawer onClose={props.onClose} isOpen={props.isOpen} size={'xl'}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerHeader>
                        {props.type === 'add' ? 'Add Role' : 'Edit Role'}
                    </DrawerHeader>
                    <Drawer />
                    <DrawerBody>
                        <form
                            onSubmit={
                                props.type === 'add'
                                    ? handleSubmit(onSubmit)
                                    : handleSubmit(onEditSubmit)
                            }
                        >
                            <Stack spacing="6">
                                <Flex direction={{ base: 'column', md: 'row' }} gap={4}>
                                    <FormControl id="app_name" isRequired>
                                        <FormLabel>App Name</FormLabel>
                                        <Select
                                            {...register('app_name', { required: true })}
                                            placeholder="Please select a App Name"
                                            defaultValue={props.data.parent_group_id}
                                        >
                                            {/*groups.map((element, item) => {
                                    return (
                                      <option
                                        key={item + element.id}
                                        value={element.id}
                                      >
                                        {element.name}
                                      </option>
                                    );
                                  })*/}
                                            <option
                                                key={1}
                                                value={'HR'}
                                            >
                                                HR
                                            </option>
                                            <option
                                                key={2}
                                                value={'UG'}
                                            >
                                                UG
                                            </option>
                                            <option
                                                key={3}
                                                value={'OPS'}
                                            >
                                                OPS
                                            </option>

                                        </Select>

                                    </FormControl>
                                    <FormControl id="feature_name" isRequired>
                                        <FormLabel>App Name</FormLabel>
                                        <Select
                                            {...register('feature_name', { required: true })}
                                            placeholder="Please select a Feature Name"
                                            defaultValue={props.data.feature_name}
                                        >
                                            {/*groups.map((element, item) => {
                                    return (
                                      <option
                                        key={item + element.id}
                                        value={element.id}
                                      >
                                        {element.name}
                                      </option>
                                    );
                                  })*/}
                                            <option
                                                key={1}
                                                value={'ALLOWANCE'}
                                            >
                                                ALLOWANCE
                                            </option>
                                            <option
                                                key={2}
                                                value={'ASSET'}
                                            >
                                                ASSET
                                            </option>
                                            <option
                                                key={3}
                                                value={'ATTENDANCE'}
                                            >
                                                ATTENDANCE
                                            </option>

                                        </Select>

                                    </FormControl>
                                </Flex>
                                <Box style={{border: '2px solid #ae0000', borderRadius: 10, height: 300, overflowY: 'scroll'}}>
                                    <TableContainer>
                                        <Table size='sm'>
                                            <Thead >
                                                <Tr position="sticky" top={0}  bg={'#ae0000'}>
                                                    <Th color={'#FFFFFF'}>Feature</Th>
                                                    <Th color={'#FFFFFF'}>Read</Th>
                                                    <Th color={'#FFFFFF'}>Write</Th>
                                                    <Th color={'#FFFFFF'}>Update</Th>
                                                    <Th color={'#FFFFFF'}>All</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                <Tr>
                                                    <Td>Add User</Td>
                                                    <Td><Checkbox />4</Td>
                                                    <Td><Checkbox />2</Td>
                                                    <Td><Checkbox />1</Td>
                                                    <Td><Checkbox />7</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td>Add User</Td>
                                                    <Td><Checkbox />4</Td>
                                                    <Td><Checkbox />2</Td>
                                                    <Td><Checkbox />1</Td>
                                                    <Td><Checkbox />7</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td>Add User</Td>
                                                    <Td><Checkbox /></Td>
                                                    <Td><Checkbox /></Td>
                                                    <Td><Checkbox /></Td>
                                                    <Td><Checkbox /></Td>
                                                </Tr>
                                                <Tr>
                                                    <Td>Add User</Td>
                                                    <Td><Checkbox />4</Td>
                                                    <Td><Checkbox />2</Td>
                                                    <Td><Checkbox />1</Td>
                                                    <Td><Checkbox />7</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td>Add User</Td>
                                                    <Td><Checkbox />4</Td>
                                                    <Td><Checkbox />2</Td>
                                                    <Td><Checkbox />1</Td>
                                                    <Td><Checkbox />7</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td>Add User</Td>
                                                    <Td><Checkbox />4</Td>
                                                    <Td><Checkbox />2</Td>
                                                    <Td><Checkbox />1</Td>
                                                    <Td><Checkbox />7</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td>Add User</Td>
                                                    <Td><Checkbox />4</Td>
                                                    <Td><Checkbox />2</Td>
                                                    <Td><Checkbox />1</Td>
                                                    <Td><Checkbox />7</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td>Add User</Td>
                                                    <Td><Checkbox />4</Td>
                                                    <Td><Checkbox />2</Td>
                                                    <Td><Checkbox />1</Td>
                                                    <Td><Checkbox />7</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td>Add User</Td>
                                                    <Td><Checkbox />4</Td>
                                                    <Td><Checkbox />2</Td>
                                                    <Td><Checkbox />1</Td>
                                                    <Td><Checkbox />7</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td>Add User</Td>
                                                    <Td><Checkbox />4</Td>
                                                    <Td><Checkbox />2</Td>
                                                    <Td><Checkbox />1</Td>
                                                    <Td><Checkbox />7</Td>
                                                </Tr>
                                                
                                                

                                            </Tbody>
                                            
                                        </Table>
                                    </TableContainer>
                                </Box>
                                <Button
                                    type="submit"
                                    // leftIcon={ addProductLoading ? ( <Progress size="xs" isIndeterminate /> ) : (
                                    //     <AiOutlineSave
                                    //       color={useColorModeValue(colorConfig.primary, 'white')}
                                    //     />
                                    //   )
                                    // }
                                    width='full'
                                >
                                    Submit
                                </Button>
                                <Button
                                    type="submit"
                                    leftIcon={
                                        <AiOutlineReload
                                            color={useColorModeValue(colorConfig.primary, 'white')}
                                        />
                                    }
                                    width='full'
                                >
                                    {props.type === 'add' ? 'Reset' : 'Cancel'}
                                </Button>
                            </Stack>
                        </form>
                        {/*addProductLoading && <Progress size="xs" isIndeterminate />*/}
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </div>
    );
};

export default SidePanel;

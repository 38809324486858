import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  deleteUserSuccessful: false,
  deleteUserIsLoading: false,
  deleteUserError: "",
  deleteUserMessage:"",
};

const deleteUserSlice = createSlice({
  name: "deleteUser",
  initialState,
  reducers: {
    deleteUserPending: (state) => {
      state.deleteUserIsLoading = true;
    },
    deleteUserSuccess: (state, { payload }) => {
      state.deleteUserIsLoading = false;
      state.addUserSuccessful = true;
      state.addUserError = "";
      state.addUserMessage = payload;
    },
    deleteUserFail: (state, { payload }) => {
      state.deleteUserIsLoading = false;
      state.addUserError = payload;
    },
  },
});

const { reducer, actions } = deleteUserSlice;
export const { deleteUserPending, deleteUserSuccess, deleteUserFail } =
  actions;

export default reducer;

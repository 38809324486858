import {
  Box,
  Divider,
  Flex,
  HStack,
  Icon,
  Input,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
  useColorModeValue as mode,
  Checkbox,
  CheckboxGroup,
} from '@chakra-ui/react'
import * as React from 'react'
import { MdFilterList } from 'react-icons/md'
import { CheckboxFilter } from './CheckboxFilter'
// import { ColorPicker } from './ColorPicker'
import { FilterDrawer } from './FilterDrawer'
import { PriceRangePicker } from './PriceRangePicker'
// import { SizePicker } from './SizePicker'
import { SortbySelect } from './SortBySelect'
import { colorConfig } from '../../../colorConfig'


export const MobileFilter = ({categories, searchText}) => {
  const [checkedItems, setCheckedItems] = React.useState(
    categories.map(() => true)
  );
  const allChecked = checkedItems.every(Boolean);
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;
  const [sortOrder, setSortOrder] = React.useState('low-to-high');

  const onChangeHandler = (e, index) => {
    const newCategories = [...checkedItems];
    newCategories[index] = !newCategories[index];
    setCheckedItems(newCategories);

  }

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };

  const handleSelectAll = () => {
    const allSelected = checkedItems.every((category) => category);
    const newCategories = categories.map(() => !allSelected);
    setCheckedItems(newCategories);
  }
  const { isOpen, onClose, onOpen } = useDisclosure()
  return (
    <React.Fragment>
      <Flex
        width="full"
        justify="space-between"
        display={{
          base: 'flex',
          md: 'none',
        }}
      >
        <HStack
          as="button"
          fontSize="sm"
          type="button"
          px="3"
          py="1"
          onClick={onOpen}
          borderWidth="1px"
          rounded="md"
        >
          <Icon as={MdFilterList} />
          <Text>Filters</Text>
        </HStack>
        <SortbySelect width="120px" defaultValue="23" placeholder="Sort" />
      </Flex>
      <FilterDrawer isOpen={isOpen} onClose={onClose}>
        <Stack spacing="6" divider={<StackDivider />}>
        <Input
            placeholder="Search..."
            rounded="md"
            focusBorderColor={mode('blue.500', 'blue.200')}
            onChange={event => searchText}
          />
          <Stack as="fieldset" spacing={3}>

          <Checkbox
            isChecked={allChecked}
            isIndeterminate={isIndeterminate}
            colorScheme='red'
            color={mode(colorConfig.secondary, colorConfig.primary)}
            fontWeight={'semibold'}
            onChange={handleSelectAll}
          >
            All Categories
          </Checkbox>
          <CheckboxGroup>
            {categories.map((option, index) => {
              return (
                <Checkbox
                  key={option.category_name + option.category_level_id + index}
                  // value={option.category_level_id}
                  isChecked={checkedItems[index]}
                  colorScheme="red"
                  color={mode(colorConfig.secondary, colorConfig.primary)}
                  fontWeight={'semibold'}

                  onChange={(e) => onChangeHandler(e, index)}
                >
                  <span>{option.category_name}</span>
                </Checkbox>

              )
            })}
          </CheckboxGroup>
        </Stack>
          
        </Stack>
      </FilterDrawer>
    </React.Fragment>
  )
}
const MobilePriceFilter = () => {
  return (
    <Box>
      <Box fontWeight="semibold" mb="2">
        Price range
      </Box>
      <Box marginStart="2">
        <PriceRangePicker defaultValue={[0, 50]} />
      </Box>
      <HStack spacing="4" mt="4">
        <HStack spacing="4">
          <Text color="gray.500" fontSize="sm">
            min
          </Text>
          <Input aria-label="Minimum price" type="number" defaultValue={10} />
        </HStack>
        <Divider width="8" opacity={1} />
        <HStack spacing="4">
          <Text color="gray.500" fontSize="sm">
            max
          </Text>
          <Input aria-label="Maximum price" type="number" defaultValue={50} />
        </HStack>
      </HStack>
    </Box>
  )
}


import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addItemToWhishlistSuccessful: false,
  addItemToWhishlistLoading: false,
  addItemToWhishlistError: "",
  addItemToWhishlistMessage:"",
};

const addItemToWhishlistSlice = createSlice({
  name: "addItemToWhishlist",
  initialState,
  reducers: {
    addItemToWhishlistPending: (state) => {
      state.addItemToWhishlistLoading = true;
    },
    addItemToWhishlistSuccess: (state, { payload }) => {
      state.addItemToWhishlistLoading = false;
      state.addItemToWhishlistSuccessful = true;
      state.addItemToWhishlistError = "";
      state.addItemToWhishlistMessage = payload;
    },
    addItemToWhishlistFail: (state, { payload }) => {
      state.addItemToWhishlistLoading = false;
      state.addItemToWhishlistError = payload;
    },
  },
});

const { reducer, actions } = addItemToWhishlistSlice;
// console.log("🚀 ~ file: addItemToWishlistSlice.js:31 ~ addItemToWhishlistSlice:", addItemToWhishlistSlice)
export const { addItemToWhishlistPending, addItemToWhishlistSuccess, addItemToWhishlistFail } =
  actions;

export default reducer;

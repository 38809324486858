import { Rating } from './Rating';
import {
  background,
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import * as React from 'react'
import { ReviewItem } from './ReviewItem'
import { reviews } from './data'
import { colorConfig } from '../../../colorConfig';

export const Reviews = () => (

  <Box
    maxWidth={750}
    py={{ base: '3', md: '5', lg: '7' }}
  >
    <Stack spacing="12">
      <Stack
        direction={{
          base: 'column',
          md: 'row',
        }}
        spacing="4"
        justifyContent="space-between"
        alignItems={{
          base: 'start',
          md: 'center',
        }}
      >
        <Stack>
          <Heading
            fontSize={{
              base: '1.25rem',
              md: '1.5rem',
            }}
            fontWeight="semibold"
            color={useColorModeValue('black', 'white')}
          >
            Customer reviews
          </Heading>
          <HStack>
            <Rating defaultValue={5} size="sm" />
            <Text color={useColorModeValue(colorConfig.secondary, colorConfig.primary)}>Based on 14 reviews on Google</Text>
          </HStack>
        </Stack>
        {/* <Button size="lg" background={colorConfig.primary} color={colorConfig.textColor} _hover={{ background: colorConfig.secondary }}>
          Write a review
        </Button>*/}
      </Stack>
      <Divider
        display={{
          base: 'none',
          md: 'flex',
        }}
      />
      <SimpleGrid
        columns={{
          base: 1,
          md: 2,
        }}
        columnGap="12"
        rowGap={{
          base: '10',
          md: '12',
        }}
      >
        {reviews.map((review) => (
          <ReviewItem key={review.id} review={review} />
        ))}
      </SimpleGrid>
      {/*<Button size="lg" variant="outline" alignSelf="center" px="16">
        Load more
        </Button>*/}
    </Stack>
  </Box>
);

import axios from 'axios';
import {
  ADD_PRODUCTS_API,
  DELETE_PRODUCT_API,
  GET_ALL_PRODUCTS_API,
  UPDATE_PRODUCT_API,
  GET_PRODUCT_BY_ID_API,
  GET_ALL_PRODUCTS_VIEW_API
} from './generic-api';
import { useNavigate } from 'react-router';


export const getAllProductApi = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(GET_ALL_PRODUCTS_API, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
        },
      });
      console.log(response);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getAllProductViewApi = () => {

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(GET_ALL_PRODUCTS_VIEW_API, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
        },
      });

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
}

export const getProductByIdApi = payload => {
  console.log("🚀 ~ file: product-api.js:28 ~ getProductByIdApi ~ payload:", payload)
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(
        GET_PRODUCT_BY_ID_API + payload + '/select',
        {
          headers: {
            session_id: sessionStorage.getItem('session_id'),
          },
        }
      );
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const addProductApi = payLoadData => {
  return new Promise(async (resolve, reject) => {
    console.log("🚀 ~ file: product-api.js:69 ~ returnnewPromise ~ payLoadData:", payLoadData)
    try {
      const response = await axios.post(ADD_PRODUCTS_API, payLoadData, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
          'Access-Control-Allow-Origin': '*',
        },
      });
      console.log(response);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateProductApi = payLoadData => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.patch(UPDATE_PRODUCT_API, payLoadData, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
          'Access-Control-Allow-Origin': '*',
        },
      });

      resolve(response);
      // }
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteProductApi = payLoadData => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(DELETE_PRODUCT_API, payLoadData, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
          'Access-Control-Allow-Origin': '*',
        },
      });
      resolve(response);
      // }
    } catch (error) {
      reject(error);
    }
  });
};

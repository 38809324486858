import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  productDetails: [],
  getProductByIdLoading: false,
  getProductByIdError: "",
};

const getProductByIdSlice = createSlice({
  name: "ProductDetails",
  initialState,
  reducers: {
    getProductByIdPending: (state) => {
      state.getProductByIdLoading = true;
    },
    getProductByIdSuccess: (state, { payload }) => {
      state.getProductByIdLoading = false;
      state.productDetails = payload;
      state.getProductByIdError = "";
    },
    getProductByIdFail: (state, { payload }) => {
      state.getProductByIdLoading = false;
      state.getProductByIdError = payload;
    },
  },
});

const { reducer, actions } = getProductByIdSlice;
export const { getProductByIdPending, getProductByIdSuccess, getProductByIdFail } =
  actions;

export default reducer;

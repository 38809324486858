import axios from "axios";
import { LOGIN_API, LOG_OUT_API } from "./generic-api";

export const userLogin = (formData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(LOGIN_API, formData);
      console.log(response);
      console.log("userLogin", response.headers.valueOf("session_id"));
      sessionStorage.setItem("session_id", response.data.session_id);
      sessionStorage.setItem("group_id", response.data.group_id);
      sessionStorage.setItem("user_id", response.data.user_id);
      localStorage.setItem("user_name", response.data.user_name);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};
export const userLogout = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(LOG_OUT_API, {
        headers: {
          session_id: sessionStorage.getItem("session_id"),
        },
      });

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

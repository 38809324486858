import {
  Box,
  Flex,
  Heading,
  SimpleGrid,
  Stack
} from '@chakra-ui/react';
import * as React from 'react';

import { useSelector } from 'react-redux';
import { CategoryCard } from '../../../common/CategoryCards/CatetgoryCard';

const Category = () => {
  // eslint-disable-next-line no-unused-vars
  const { getAllCategoryLoading, categories } = useSelector(
    state => state.category
  );
  console.log('🚀 ~ file: Category.js:20 ~ Category ~ categories', categories);
  return (
    <Box
      maxW="7xl"
      mx="auto"
      px={{
        base: '4',
        md: '8',
        lg: '12',
      }}
      py={{
        base: '6',
        md: '8',
        lg: '12',
      }}
    >
      <Stack
        spacing={{
          base: '6',
          md: '8',
          lg: '12',
        }}
      >
        <Flex
          justify="space-between"
          align={{
            base: 'start',
            md: 'center',
          }}
          direction={{
            base: 'column',
            md: 'row',
          }}
        >
          <Heading
            size="lg"
            mb={{
              base: '3',
              md: '0',
            }}
          >
            Shop by Categories
          </Heading>
        </Flex>
        <SimpleGrid
          columns={{
            base: 1,
            md: 3,
            lg: 4,
          }}
          gap={{
            base: '4',
            md: '6',
            lg: '8',
          }}
        >
          {categories.map(category => (
            <CategoryCard key={category.category_name} category={category} />
          ))}
        </SimpleGrid>
      </Stack>
    </Box>
  );
};
export default Category;

import React, { useEffect, useState } from 'react';
import {
  chakra,
  HStack,
  Flex,
  IconButton,
  useColorModeValue,
  useDisclosure,
  CloseButton,
  VStack,
  Button,
  useColorMode,
  Img,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
} from '@chakra-ui/react';
import { useScroll } from 'framer-motion';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
  AiFillHome,
  AiOutlineMenu,
  AiOutlineShoppingCart,
  AiFillProfile,
  AiOutlineLogout,
  AiOutlineHeart,
} from 'react-icons/ai';
import { NavLink } from 'react-router-dom';

import { FaMoon, FaSun, FaLayerGroup } from 'react-icons/fa';
import { SiGoogleclassroom } from 'react-icons/si';
import DarkLogo from '../../assets/images/redhood-logo.png';
import LightLogo from '../../assets/images/redhood-logo-light.png';
import { colorConfig } from '../../../colorConfig';

export default function Header() {
  const location = useLocation();
  const navigate = useNavigate();

  const { toggleColorMode: toggleMode } = useColorMode();
  const { isAuth, group_id } = useSelector(state => state.login);

  const text = useColorModeValue('dark', 'light');
  const textColor = useColorModeValue('black', 'white');
  const SwitchIcon = useColorModeValue(FaMoon, FaSun);
  const bg = useColorModeValue('white', 'gray.800');
  const ref = React.useRef();
  const [y, setY] = React.useState(0);
  const { height = 0 } = ref.current ? ref.current.getBoundingClientRect() : {};

  const [avatarKey, setAvatarKey] = useState(Math.random());

  const { scrollY } = useScroll();

  React.useEffect(() => {
    return scrollY.onChange(() => setY(scrollY.get()));
  }, [scrollY]);

  const cl = useColorModeValue('gray.800', 'white');
  const mobileNav = useDisclosure();
  const brandColor = useColorModeValue(colorConfig.primary, 'white');
  const MobileNavContent = (
    <VStack
      pos="absolute"
      top={0}
      left={0}
      right={0}
      display={mobileNav.isOpen ? 'flex' : 'none'}
      flexDirection="column"
      p={2}
      pb={4}
      m={2}
      bg={bg}
      spacing={3}
      rounded="md"
      shadow="lg"
      zIndex={900}
    >
      <CloseButton
        aria-label="Close menu"
        justifySelf="self-start"
        onClick={mobileNav.onClose}
      />
      <NavLink to="/">
        <Button
          w="full"
          color={brandColor}
          variant="ghost"
          leftIcon={<AiFillHome />}
        >
          Home
        </Button>
      </NavLink>
      <NavLink to="/allcourses/all">
        <Button
          w="full"
          variant="ghost"
          color={brandColor}
          leftIcon={<FaLayerGroup />}
        >
          Courses
        </Button>
      </NavLink>
      {(isAuth === true && group_id !== undefined) ? (
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={
              <Avatar
                size="sm"
                name={localStorage.getItem('user_name')}
              />
            }
            variant="outline"
          />
          <MenuList>
            <MenuItem icon={<AiFillProfile />}>Profile</MenuItem>
            <MenuItem icon={<SiGoogleclassroom />}>My Enrollments</MenuItem>
            <MenuItem icon={<AiOutlineShoppingCart />}>Your Cart</MenuItem>
            <MenuItem icon={<AiOutlineLogout />}>Logout</MenuItem>
          </MenuList>
        </Menu>
      ) : (
        <NavLink to="/signin">
          <Button colorscheme="#ae0000" variant="solid" size="sm" _hover={{ color: '#000000' }}>
            Sign in
          </Button>
        </NavLink>
      )}
    </VStack>
  );

  const logoutClicked = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate('/', { replace: true });
    window.location.reload();
  }
  useEffect(() => {
    setAvatarKey(Math.random());
    if (localStorage.getItem('group_id') === 0) {
      return;
    }
  }, [isAuth]);

  return (
    <React.Fragment>
      <nav style={{ top: 0, position: 'sticky', width: "100%", backgroundColor: '#ffffff', zIndex: '1000000' }}>
        <chakra.header

          ref={ref}
          shadow={y > height ? 'lg' : undefined}
          transition="box-shadow 0.2s"
          bg={bg}
          w="full"
          overflowY="hidden"
          zIndex={900}
          dropShadow={'lg'}
        >
          <chakra.div h="4.5rem" mx="auto" maxW="1200px">
            <Flex
              w="full"
              h="full"
              px="6"
              alignItems="center"
              justifyContent="space-between"
            >
              <Flex align="flex-start">
                <NavLink to="/">
                  <HStack>
                    <Img
                      w="30"
                      h="20"
                      minW="30"
                      objectFit="contain"
                      src={useColorModeValue(LightLogo, DarkLogo)}
                      alt={useColorModeValue(LightLogo, DarkLogo)}
                    />
                  </HStack>
                </NavLink>
              </Flex>
              <Flex justify="flex-end" align="center" color="gray.400">
                <HStack spacing="1" display={{ base: 'none', md: 'flex' }}>
                  <NavLink to="/seo-services">
                    <Button
                      bg={bg}
                      color={cl}
                      display="inline-flex"
                      alignItems="center"
                      fontSize="md"
                      _hover={{ color: cl }}
                      _focus={{ boxShadow: 'none' }}
                    >
                      SEO
                    </Button>
                  </NavLink>
                  <NavLink to="/allcourses/all">
                    <Button
                      bg={bg}
                      color={cl}
                      display="inline-flex"
                      alignItems="center"
                      fontSize="md"
                      _hover={{ color: cl }}
                      _focus={{ boxShadow: 'none' }}
                    >
                      Courses
                    </Button>
                  </NavLink>

                  <NavLink to={`/contact`}>
                    <Button
                      bg={bg}
                      color={cl}
                      display="inline-flex"
                      alignItems="center"
                      fontSize="md"
                      _hover={{ color: cl }}
                      _focus={{ boxShadow: 'none' }}
                      onClick={() => {
                        if (location.pathname !== '/') {
                          navigate('/contact', { replace: true });
                        } else {

                          let contactUs = document.getElementById("contact");
                          contactUs && contactUs.scrollIntoView({ behavior: "smooth", block: "start" });
                        }
                      }}
                    >
                      Contact
                    </Button>
                  </NavLink>

                  <NavLink to="/about" onClick={() => {
                    let aboutUs = document.getElementById("about");
                    aboutUs && aboutUs.scrollIntoView({ behavior: "smooth", block: "start" });
                  }}>
                    <Button
                      bg={bg}
                      color={cl}
                      display="inline-flex"
                      alignItems="center"
                      fontSize="md"
                      _hover={{ color: cl }}
                      _focus={{ boxShadow: 'none' }}
                    >
                      About
                    </Button>
                  </NavLink>
                </HStack>
                <HStack
                  key={avatarKey}
                  spacing="3"
                  display={{ base: 'none', md: 'flex' }}
                  variant="outline"
                >
                  {isAuth === true && group_id !== undefined ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        flexDirection: 'row',
                      }}
                    >
                      <IconButton
                        rounded={'full'}
                        size="md"
                        fontSize="lg"
                        aria-label={`Switch to ${text} mode`}
                        variant="outline"
                        color={brandColor}
                        ml={{ base: '0', md: '3' }}
                        onClick={() =>
                          navigate(`/cart/${sessionStorage.getItem('user_id')}`)
                        }
                        icon={<AiOutlineShoppingCart />}
                      />
                      <Menu>
                        <MenuButton
                          rounded={'full'}
                          as={IconButton}
                          aria-label="Options"
                          icon={
                            <Avatar
                              size="sm"
                              name={localStorage.getItem('user_name')}
                            // src="https://bit.ly/prosper-baba"
                            />
                          }
                          variant="ghost"
                        />
                        <MenuList>
                          <MenuItem
                            onClick={() =>
                              navigate(
                                `/user-profile/${sessionStorage.getItem(
                                  'user_id'
                                )}`
                              )
                            }
                            icon={
                              <AiFillProfile
                                color={brandColor}
                              />
                            }
                          >
                            <Text color={textColor}>
                              {localStorage.getItem('user_name')}
                            </Text>
                          </MenuItem>
                          {isAuth === true && group_id !== 4 && (<MenuItem
                            onClick={() =>
                              navigate(
                                `/admindashboard`
                              )
                            }
                            icon={
                              <AiFillProfile
                                color={brandColor}
                              />
                            }
                          >
                            <Text color={textColor}>
                              Admin Dashboard
                            </Text>
                          </MenuItem>)}
                          <MenuItem
                            onClick={() =>
                              navigate(
                                `/user-profile/${sessionStorage.getItem(
                                  'user_id'
                                )}`
                              )
                            }
                            icon={
                              <SiGoogleclassroom
                                color={brandColor}
                              />
                            }
                          >
                            <Text color={textColor}>
                              My Enrollments
                            </Text>
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              navigate(
                                `/wishlist/${sessionStorage.getItem('user_id')}`
                              )
                            }
                            icon={
                              <AiOutlineHeart
                                color={brandColor}
                              />
                            }
                          >
                            <Text color={textColor}>
                              Wishlist
                            </Text>
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              navigate(
                                `/cart/${sessionStorage.getItem('user_id')}`
                              )
                            }
                            icon={
                              <AiOutlineShoppingCart
                                color={brandColor}
                              />
                            }
                          >
                            <Text color={textColor}>
                              Cart
                            </Text>
                          </MenuItem>
                          <MenuItem
                            onClick={logoutClicked}
                            icon={
                              <AiOutlineLogout
                                color={brandColor}
                              />
                            }
                          >
                            <Text color={textColor}>
                              Logout
                            </Text>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                      <IconButton
                        size="md"
                        fontSize="lg"
                        aria-label={`Switch to ${text} mode`}
                        variant="ghost"
                        color="current"
                        ml={{ base: '0', md: '3' }}
                        onClick={toggleMode}
                        icon={<SwitchIcon />}
                      />
                      <IconButton
                        display={{ base: 'flex', md: 'none' }}
                        aria-label="Open menu"
                        fontSize="20px"
                        // color={useColorModeValue('gray.800', 'inherit')}
                        variant="ghost"
                        icon={<AiOutlineMenu />}
                        onClick={mobileNav.onOpen}
                      />
                    </div>
                  ) : (
                    <NavLink to="/signin">
                      <Button colorscheme="#ae0000" backgroundColor={'#ae0000'}
                        color={'#ffffff'} variant="solid" size="sm"
                        _hover={{ backgroundColor: '#000000', color: '#ffffff' }}>
                        Sign in
                      </Button>
                    </NavLink>
                  )}
                </HStack>

              </Flex>
            </Flex>
            {MobileNavContent}
          </chakra.div>
        </chakra.header>
      </nav>
    </React.Fragment>
  );
}

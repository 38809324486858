import { AbsoluteCenter, Box, Button, Center, Flex, Text } from '@chakra-ui/react'

import React from 'react'
import { Thumbnail } from '../../Pages/public/LandingPage/styles'
import emptyCart from '../../assets/images/emptyCart.svg'

import { colorConfig } from '../../../colorConfig'
import { Link } from 'react-router-dom'

const EmptySpace = () => {
    return (
        <Box boxSize='sm'>
            <Flex>
                <img background='rgba(76, 175, 80, 0.3)' src={emptyCart} onBlur={10} alt="its empty" />
            </Flex>
            <Center paddingTop='5'><Text color={colorConfig.primary} as='i'>Add some <span><Link to="/allcourses/all"><strong>courses</strong></Link></span> to the whishlist</Text></Center>

        </Box>
    )
}

export default EmptySpace
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Stack,
  Text,
  Flex,
  VStack,
  Button,
  Heading,
  SimpleGrid,
  StackDivider,
  useColorModeValue,
  List,
  ListItem,
  useToast,
  Image,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Spacer,
} from '@chakra-ui/react';

import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { Radio, RadioGroup } from '@chakra-ui/react';
// import { FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
import { MdLocalShipping } from 'react-icons/md';
import { colorConfig } from '../../../../colorConfig';
import "./ProductDetail.css";
import { Link, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDefaultMiddleware } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router';
import { getAttributeByIdApi } from '../../../services/api/attribute-api';
import { getProductByIdApi } from '../../../services/api/product-api';
import { addItemToCartApi } from '../../../services/api/cart-api';
import { Reviews } from '../../../common/Reviews';
import Faq from '../../public/Faq';
import {
  getProductByIdPending,
  getProductByIdSuccess,
  getProductByIdFail,
} from '../../../services/slice/getProductById';

import {
  getAttributeByIdPending,
  getAttributeByIdSuccess,
  getAttributeByIdFail,
} from '../../../services/slice/getAttributeById';

import {
  addItemToCartPending,
  addItemToCartSuccess,
  addItemToCartFail,
} from '../../../services/slice/addItemToCartSlice';

import {
  addItemToWhishlistPending,
  addItemToWhishlistSuccess,
  addItemToWhishlistFail,
} from '../../../services/slice/addItemToWishlistSlice';

import CustomAccordion from '../../../common/CustomAccordion/CustomAccordion';
import ImageGalary from '../../../common/ImageGalary';
import { FaTruckLoading } from 'react-icons/fa';
import CourseOverview from './Overview/CourseOverview';
import CourseModule from './Course';
import CertificateSection from './CertificateSection';

const ProductDetail = props => {
  console.log("🚀 ~ ProductDetail ~ localStorage.getItem('user_name'):", localStorage.getItem('user_name'))

  const toast = useToast();
  const { state } = useLocation();
  const location = useLocation();
  console.log("🚀 ~ ProductDetail ~ location:", location)

  const { attributes = null, description = null, product_name = null, product_id = null } = state;

  const [attributeResponse, setAttributeResponse] = useState();
  const [selectedAttribute, setSelectedAttribute] = useState(attributes[0] || null);
  const [attribute_id, setAttribute_id] = useState('');
  const [activeIndex, setActiveIndex] = useState(0)
  const navigate = useNavigate();
  const dispatch = useDispatch();



  const pageSections = [{ id: 1, sectionName: 'About', hyperLink: 'about' }]


  function scrollingEvent(e) {
    var header = document.getElementById("myHeader");
    console.log("🚀 ~ ProductDetail ~ header:", header)
    var sticky = header.offsetTop;
    if (window?.pageYOffset > sticky) {
      header.classList.add("sticky");
    } else {
      header.classList.remove("sticky");
    }
  }

  const customizedMiddleware = getDefaultMiddleware({
    serializableCheck: false
  })

  // const { attributes, getAttributeByIdLoading } = useSelector(
  //   state => state.getAttributeById
  // );

  const { productDetails, getProductByIdLoading } = useSelector(
    state => state.getProductById
  );



  // const { }

  const { isAuth, group_id, error } = useSelector(state => state.login);

  const buttonClicked = element => {
    setSelectedAttribute(element);
  };

  const fetchAllMediaByAttribute = async () => {
    console.log('fetch Product')
    dispatch(getProductByIdPending());
    console.log("🚀 ~ file: ProductDetail.js:100 ~ fetchProductById ~ product_id:", product_id)
    try {
      const getProductById = await getProductByIdApi(product_id);
      dispatch(getProductByIdSuccess(getProductById));
    } catch (error) {
      console.log("🚀 ~ file: ProductDetail.js:103 ~ fetchProductById ~ error:", error)
      setAttributeResponse({});
      dispatch(getAttributeByIdFail(error));
    }
  };

  const fetchProductById = async () => {
    console.log('fetch Product')
    dispatch(getProductByIdPending());
    console.log("🚀 ~ file: ProductDetail.js:100 ~ fetchProductById ~ product_id:", product_id)
    try {
      const getProductById = await getProductByIdApi(product_id);
      dispatch(getProductByIdSuccess(getProductById));
    } catch (error) {
      console.log("🚀 ~ file: ProductDetail.js:103 ~ fetchProductById ~ error:", error)
      setAttributeResponse({});
      dispatch(getAttributeByIdFail(error));
    }
  };

  const getAttributeById = async () => {
    dispatch(getAttributeByIdPending());
    try {
      const getAttributeById = await getAttributeByIdApi(product_id);
      dispatch(getAttributeByIdSuccess(getAttributeById));
      console.log("🚀 ~ file: ProductDetail.js:130 ~ getAttributeById ~ getAttributeById:", getAttributeById)
      setSelectedAttribute(getAttributeById[0]);
      setAttributeResponse(getAttributeById);
    } catch (error) {
      setAttributeResponse({});
      dispatch(getAttributeByIdFail(error));
    }
  };

  const addToCart = async () => {
    if (!isAuth) {
      navigate('/signin', { replace: false });
      return;
    }
    const payloadData = {
      cart_id: 0,
      user_id: sessionStorage.getItem('user_id'),
      attribute_id: selectedAttribute.attribute_id,
      unit: '1',
    };

    dispatch(addItemToCartPending());
    try {
      const addItemToCart = await addItemToCartApi(payloadData);

      if (addItemToCart.inserted_id) {
        dispatch(addItemToCartSuccess(addItemToCart));
        toast({
          title: `Product added to your cart successfully`,
          position: 'top-right',
          status: 'success',
          isClosable: true,
        });
      } else {
        addItemToCartFail();
        toast({
          title: `There was an error adding the product to your cart`,
          position: 'top-right',
          status: 'error',
          isClosable: true,
        });
      }
    } catch (errors) {
      dispatch(addItemToCartFail());
      toast({
        title: `There was an error adding the product to your cart`,
        position: 'top-right',
        status: 'error',
        isClosable: true,
      });
      console.log(
        '🚀 ~ file: ProductDetail.js ~ line 136 ~ addToCart ~ errors',
        errors
      );
    }
  };

  const addToWishList = async () => {
    if (!isAuth) {
      navigate('/signin', { replace: false });
      return;
    }
    const payloadData = {
      cart_id: 0,
      user_id: sessionStorage.getItem('user_id'),
      attribute_id: selectedAttribute.attribute_id,
      unit: '1',
    };
    dispatch(addItemToWhishlistPending());
    try {
      const addItemToCart = await addItemToCartApi(payloadData);
      console.log(
        '🚀 ~ file: ProductDetail.js ~ line 137 ~ addToCart ~ addItemToCart.inserted_id',
        addItemToCart.inserted_id
      );
      if (addItemToCart.inserted_id) {
        dispatch(addItemToWhishlistSuccess(addItemToCart));
        toast({
          title: `Product added to your wishlist successfully`,
          position: 'top-right',
          status: 'success',
          isClosable: true,
        });
      } else {
        dispatch(addItemToWhishlistFail());
        toast({
          title: `There was an error adding the product to your wishlist`,
          position: 'top-right',
          status: 'error',
          isClosable: true,
        });
      }
    } catch (errors) {
      dispatch(addItemToWhishlistFail());
      toast({
        title: `There was an error adding the product to your wishlist`,
        position: 'top-right',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const getEnrolled = async () => {
    !isAuth && navigate('/signin', { replace: false });
    const payloadData = {
      cart_id: '',
      user_id: sessionStorage.getItem('user_id'),
      attribute_id: selectedAttribute.attribute_id,
      unit: '1',
    };
    console.log(
      '🚀 ~ file: ProductDetail.js ~ line 137 ~ getEnrolled ~ payloadData',
      payloadData
    );
    navigate(`/cart/${sessionStorage.getItem('user_id')}`);
  };

  useEffect(() => {
    // fetchProductById();
    // getAttributeById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Box bg={colorConfig.primary} minH={'70vh'} style={{
        background: '#000000',
        background: '-webkit-linear-gradient(105deg, #000000 0%, #ae0000 100%)',
        background: 'linear-gradient(105deg, #000000 0%, #ae0000 100%)'
      }}>
        <Box maxW={1200} position={'relative'} paddingRight={15} paddingLeft={15} marginRight={'auto'} marginLeft={'auto'} width={'100%'}>
          <Flex position={'relative'} flexDirection={{ base: 'column', md: 'row' }} justify={'space-between'} paddingTop={'4rem'} paddingBottom={'2rem'}>
            <Box width={'66%'} flex={'0 0 66%'} color={colorConfig.textColor}>
              <Breadcrumb>
                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to='/' fontWeight={'bold'} >
                    Home
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <BreadcrumbLink as={Link} to='/all-category' fontWeight={'bold'}>
                    Category
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem isCurrentPage>
                  <BreadcrumbLink as={Link} to='/allcourses/all' fontWeight={'bold'}>Courses</BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>
              <Heading
                lineHeight={1.1}
                fontWeight={600}
                fontSize={{ base: '2xl', sm: '4xl', lg: '5xl' }}
                paddingTop={'2rem'}
              >
                {product_name === undefined ? 'Loading...' : product_name}
              </Heading>

              <Text fontSize={'lg'} paddingTop={'2rem'} >
                {description === undefined ? 'Loading..' : description}
              </Text>
              <Heading
                lineHeight={1.1}
                fontWeight={600}
                fontSize={{ base: '2xl', sm: '2xl', lg: '2xl' }}
                paddingTop={'2rem'}
              >
                By this course @ {selectedAttribute ? selectedAttribute.mrp : 'Loading...'}
              </Heading>
            </Box>
            <Box width={'33%'} flex={'0 0 33%'} top={0} >
              <Box borderRadius={'md'} shadow={'lg'} position={'fixed'} bg={colorConfig.secondaryBackgroundColor} padding={'1rem'} zIndex={999}>
                <Box width={300}>
                  <Text as={'b'} my={'2rem'}>Get this course for $<span fontWeight={'bold'}>{selectedAttribute ? selectedAttribute.mrp : 'Loading...'}</span></Text>
                  <Flex flexDirection={{ base: 'column' }}>
                    <Button
                      rounded={'none'}
                      w={'full'}

                      size={'sm'}
                      py={'6'}
                      bg={useColorModeValue('gray.900', 'gray.50')}
                      color={useColorModeValue('white', 'gray.900')}
                      textTransform={'uppercase'}
                      _hover={{
                        transform: 'translateY(2px)',
                        boxShadow: 'lg',
                      }}
                      onClick={addToWishList}
                    >
                      Add To WishList
                    </Button>
                    <Button
                      rounded={'none'}
                      w={'full'}

                      size={'sm'}
                      my={2}
                      py={'6'}
                      bg={useColorModeValue('gray.900', 'gray.50')}
                      color={useColorModeValue('white', 'gray.900')}
                      textTransform={'uppercase'}
                      _hover={{
                        transform: 'translateY(2px)',
                        boxShadow: 'lg',
                      }}
                      onClick={addToCart}
                    >
                      Add To Cart
                    </Button>
                    <Button
                      rounded={'none'}
                      w={'full'}
                      size={'sm'}
                      py={'6'}
                      bg={useColorModeValue('gray.900', 'gray.50')}
                      color={useColorModeValue('white', 'gray.900')}
                      textTransform={'uppercase'}
                      _hover={{
                        transform: 'translateY(2px)',
                        boxShadow: 'lg',
                      }}
                      onClick={getEnrolled}
                    >
                      Get Enrolled Now
                    </Button>
                  </Flex>
                </Box>
              </Box>
            </Box>
          </Flex>
        </Box>
      </Box>
      <Box shadow="md"
        id='myHeader'
        onScroll={e => scrollingEvent(e)}
        transition={'margin 0.25 ease'}
        zIndex={99}
        top={0}
      >
        <Flex
          justifyContent="space-between"
          borderWidth={0}
          overflowX="auto"
        >
          <Box maxW={1200} width={1200} paddingRight={15} paddingLeft={15} marginLeft={'auto'} marginRight={'auto'}>
            <Tabs borderBottomColor="transparent" position={'relative'}>
              <TabList>
                {pageSections.map((section, index) => {
                  return (
                    <Tab
                      key={index + section.sectionName + section.id}
                      py={4}
                      m={1}

                      onClick={() => setActiveIndex(section.id)}
                      _focus={{
                        boxShadow: "none",
                      }}
                    >
                      <a href={`#${section.hyperLink}`}>{section.sectionName}</a>
                    </Tab>
                  )
                })}
              </TabList>
            </Tabs>
          </Box>
        </Flex >
      </Box >
      <Box maxW={1200} width={1200} paddingRight={15} paddingLeft={15} marginLeft={'auto'} rowGap={2} marginRight={'auto'}>
        <CourseOverview name='course' />
        <CourseModule name='about' />
        <CertificateSection name='certificate' />
        <Reviews name='reviews' />
      </Box>
    </>
  );
};

export default ProductDetail;

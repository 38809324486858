import {
  Box,
  Heading,
  Image,
  Stack,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

export const Stat = props => {
  const navigate = useNavigate();
  const { label, value, data, type, ...boxProps } = props;


  return (
    <Box
      key={label + value}
      px={{
        base: '4',
        md: '6',
      }}
      py={{
        base: '5',
        md: '6',
      }}
      bg="bg-surface"
      borderRadius="lg"
      boxShadow={useColorModeValue('md', 'lg')}
      {...boxProps}
      onClick={() => { type === 'product' ? navigate(`/productdetails/${value}`, { state: data }) : navigate(`/allcourses/${value}`, { state: data }) }}
    >
      <Stack key={value}>
        {type === 'product' ? (
          <Image
            rounded="lg"
            width="full"
            height="250px"
            fit="contain"
            src={data?.attributes[0]?.media[0]}
            alt={label}
            draggable="false"
            loading="lazy"
          />
        ) : null}

        <Heading
          size={useBreakpointValue({
            base: 'sm',
            md: 'md',
          })}
        >
          {label}
        </Heading>
      </Stack>
    </Box>
  );
};

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  deleteProductApi,
  getAllProductApi,
} from '../../../../services/api/product-api';

import {
  getAllProductsFail,
  getAllProductsPending,
  getAllProductsSuccess,
} from '../../../../services/slice/getAllProductsSlice';

import {
  getAllAttributesPending,
  getAllAttributesSuccess,
  getAllAttributesFail,
} from '../../../../services/slice/getAllAttributeSlice';

import {
  deleteProductPending,
  deleteProductSuccess,
  deleteProductFail,
} from '../../../../services/slice/deleteProductSlice';

import { useSelector, useDispatch } from 'react-redux';
import {
  AbsoluteCenter,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { AiOutlinePlus } from 'react-icons/ai';
import { HiOutlineSearch } from 'react-icons/hi';
import ProductQuickCards from '../../../../common/ProductCard/ProductQuickCards';
import SideDrawer from '../../../../common/SideDrawer/SideDrawer';
import CustomSpinner from '../../../../common/CustomSpinner';
import AttributeSideDrawer from '../../../../common/SideDrawer/AttributeSideDrawer';

const Products = () => {
  // const [searchText, setSearchText] = useState('');
  const { isLoading, products, error } = useSelector(state => state.products);
  console.log("🚀 ~ file: Product.js:51 ~ Products ~ products:", products)
  const {
    // deleteProductSuccessful,
    // deleteProductIsLoading,
    // deleteProductError,
    deleteProductMessage,
  } = useSelector(state => state.deleteProduct);
  const [openPanel, setOpenPanel] = useState(false);
  const [typeOfPanel, setTypeOfPanel] = useState('');
  const [dataForPanel, setDataForPanel] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [openAttributePanel, setOpenAttributePanel] = useState(false)
  const dispatch = useDispatch();
  const toast = useToast();

  // const setSeacrhText = val => {
  //   setSearchText(val);
  // };

 
  

  const fetchAllProducts = async () => {
    dispatch(getAllProductsPending());
    try {
      const getAllProduct = await getAllProductApi();
      console.log("🚀 ~ file: Product.js:78 ~ fetchAllProducts ~ getAllProduct:", getAllProduct)
      dispatch(getAllProductsSuccess(getAllProduct));
    } catch (error) {
      dispatch(getAllProductsFail(error));
    }
  };

  const openSidePanel = (data = [], type) => {
    

    if (openPanel) {
      fetchAllProducts();
    }
    setTypeOfPanel(type);
    setDataForPanel(data);
    setOpenPanel(!openPanel);
  };

  const openAttributeSidePanel = (data = [], type) => {
    if(openAttributePanel) {
      fetchAllProducts();
    }
    setTypeOfPanel(type);
    setDataForPanel(data);
    setOpenAttributePanel(!openAttributePanel)
  }

  const closeAttributePanel = (data=[], type) => {
    if(openAttributePanel) {
      fetchAllProducts();
    }
    setOpenAttributePanel(!openAttributePanel)
    setTypeOfPanel(type);
    setDataForPanel(data);
  }
  const closeSidePanel = (data = [], type) => {
    if (openPanel) {
      fetchAllProducts();
      // setOpenAttributePanel(true);
    }
    setOpenPanel(!openPanel);
    setTypeOfPanel(type);
    setDataForPanel(data);
  };

  const deleteProduct = async deleteData => {
    dispatch(deleteProductPending());
    try {
      const deleteProduct = await deleteProductApi(deleteData);
     

      dispatch(deleteProductSuccess(deleteProduct));
      toast({
        title: `${deleteProductMessage}`,
        position: 'top-right',
        status: 'success',
        isClosable: true,
      });

      fetchAllProducts();
    } catch (error) {
     
      dispatch(deleteProductFail(error));
      toast({
        title: `${error}`,
        position: 'top-right',
        status: 'error',
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredData = products.filter(element => {
    const {
      product_id,
      product_name,
      description,
      category_level_id,
    } = element;
    

    return (
      product_name.toLowerCase().indexOf(searchInput.toLowerCase()) >= 0 ||
      product_id.toString().indexOf(searchInput) >= 0 ||
      description.toLowerCase().indexOf(searchInput.toLowerCase()) >= 0 ||
      category_level_id.toString().indexOf(searchInput) >= 0
    );
  });
  return (
    <Flex m={{ base: 3, md: 6 }} direction={['column']}>
      <Flex direction="row" justifyContent={'space-between'}>
        <Heading color={useColorModeValue('gray.700', 'white')}>
          All Products
        </Heading>
        <Button
          variant={'outline'}
          rightIcon={
            <AiOutlinePlus color={useColorModeValue('#ae0000', 'white')} />
          }
          rounded="full"
          onClick={() => openSidePanel([], 'add')}
          color={useColorModeValue('gray.700', 'white')}
        >
          <Text fontWeight={'bold'}>Add Product</Text>
        </Button>
      </Flex>
      <Divider marginY={{ base: 3, md: 5 }} />
      <InputGroup w={{ base: 'full', md: 'md' }} marginY={3}>
        <InputRightElement>
          <IconButton
            color="#ae0000"
            aria-label="Call Segun"
            size="sm"
            borderRadius={'full'}
            icon={<HiOutlineSearch />}
          />
        </InputRightElement>
        <Input
          id="searchData"
          placeholder="search here.."
          name="searchData"
          type={'text'}
          autoComplete="current-searchData"
          required
          variant={'flushed'}
          onChange={event => setSearchInput(event.target.value)}
        />
      </InputGroup>
      <Flex
        direction={{ base: 'column', md: 'row' }}
        justifyContent="flex-start"
        wrap={'wrap'}
        
      >
        {isLoading && (
          <AbsoluteCenter>
            <CustomSpinner />
          </AbsoluteCenter>
        )}
        {filteredData.map((element, index) => {
          let tempData = {
            product_id: element.product_id,
            product_name: element.product_name,
            category_level_id: element.category_level_id,
          };
          return (
            <ProductQuickCards
              key={index + element.id}
              action={(data, type) => openSidePanel(element, type)}
              openAttributes={(data,type) => openAttributeSidePanel(element,type)}
              data={tempData}
              deleteProductCard={element => deleteProduct(element)}
              type={'product'}
            />
          );
        })}
      </Flex>

      {openPanel && (
        <SideDrawer
          isOpen={openPanel}
          onClose={() => closeSidePanel()}
          type={typeOfPanel}
          data={dataForPanel}
        />
      )}

      {openAttributePanel && (
        <AttributeSideDrawer isOpen={openAttributePanel} onClose={() => closeAttributePanel()} type={typeOfPanel}  data={dataForPanel.product_id}/>
      )}
      

      {/*<div className="dashboard-component">
      <h4 className="component-title">
        PRODUCTS{" "}
        <button
          className="link-btn title-btn"
          onClick={(event) => setShowAddProductFlag(event)}
        >
          <span className="show-on-medium-and-up hide-on-small-only">
            ADD PRODUCTS
          </span>{" "}
          <i className="material-icons show-on-small hide-on-med-and-up">add</i>
        </button>
      </h4>
      <div className="row">
        <div className="product-list-container">
          
          <ul className="product-list">
            {isLoading && <h3>Loading Data...</h3>}
            {isLoading === false && products.length > 0 ? (
              <div>
                {products.map((item, index) => {
                  console.log(item);
                  return (
                    <div>
                      <li
                        key={item.product_id + index}
                        className="each-product-item"
                      >
                        <div
                          className="each-product"
                          onClick={setShowProductFlag}
                        >
                          <img
                            src="/assets/redhood-logo-only.png"
                            alt="Product-icon"
                            className="product-image"
                          />
                          <div className="product-desc">
                            <p className="title">{item.product_name}</p>
                            <p className="description">{item.description}</p>
                          </div>
                        </div>
                      </li>
                    </div>
                  );
                })}
              </div>
            ) : isLoading === false && products.length === 0 && error === "" ? (
              <h3>Nothing to display :p Try adding a few data.</h3>
            ) : (
              <div>{error}</div>
            )}
          </ul>
        </div>
      </div>
      
            </div>*/}
    </Flex>
  );
};

export default Products;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  deleteProductSuccessful: false,
  deleteProductIsLoading: false,
  deleteProductError: "",
  deleteProductMessage:"",
};

const deleteProductSlice = createSlice({
  name: "deleteProduct",
  initialState,
  reducers: {
    deleteProductPending: (state) => {
      state.deleteProductIsLoading = true;
    },
    deleteProductSuccess: (state, { payload }) => {
      state.deleteProductIsLoading = false;
      state.addProductSuccessful = true;
      state.addProductError = "";
      state.addProductMessage = payload;
    },
    deleteProductFail: (state, { payload }) => {
      state.deleteProductIsLoading = false;
      state.addProductError = payload;
    },
  },
});

const { reducer, actions } = deleteProductSlice;
export const { deleteProductPending, deleteProductSuccess, deleteProductFail } =
  actions;

export default reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  attributes: [],
  getAttributeByIdLoading: false,
  getAttributeByIdError: "",
};

const getAttributeByIdSlice = createSlice({
  name: "Attributes",
  initialState,
  reducers: {
    getAttributeByIdPending: (state) => {
      state.getAttributeByIdLoading = true;
    },
    getAttributeByIdSuccess: (state, { payload }) => {
      state.getAttributeByIdLoading = false;
      state.attributes = payload;
      state.getAttributeByIdError = "";
    },
    getAttributeByIdFail: (state, { payload }) => {
      state.getAttributeByIdLoading = false;
      state.getAttributeByIdError = payload;
    },
  },
});

const { reducer, actions } = getAttributeByIdSlice;
export const { getAttributeByIdPending, getAttributeByIdSuccess, getAttributeByIdFail } =
  actions;

export default reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addProductSuccessful: false,
  addProductLoading: false,
  addProductError: "",
  addProductMessage:"",
};

const addProductSlice = createSlice({
  name: "addProduct",
  initialState,
  reducers: {
    addProductPending: (state) => {
      state.addProductLoading = true;
    },
    addProductSuccess: (state, { payload }) => {
      state.addProductLoading = false;
      state.addProductSuccessful = true;
      state.addProductError = "";
      state.addProductMessage = payload;
    },
    addProductFail: (state, { payload }) => {
      state.addProductLoading = false;
      state.addProductError = payload;
    },
  },
});

const { reducer, actions } = addProductSlice;
export const { addProductPending, addProductSuccess, addProductFail } =
  actions;

export default reducer;

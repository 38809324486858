import {
    Button,
    Flex,
    Heading,
    Link,
    Stack,
    Text,
    useColorModeValue as mode,
} from '@chakra-ui/react'
import * as React from 'react'
import { FaArrowRight } from 'react-icons/fa'
import { useNavigate } from 'react-router'
import { formatPrice } from './PriceTag'
import { useSelector } from 'react-redux'
import { colorConfig } from '../../../../colorConfig'


//   const  = (props) => {
//     const { label, value, children } = props
//     return (
//       <Flex justify="space-between" fontSize="sm">
//         <Text fontWeight="medium" color={mode('gray.600', 'gray.400')}>
//           {label}
//         </Text>
//         {value ? <Text fontWeight="medium">{value}</Text> : children}
//       </Flex>
//     )
//   }

export const AddressSummary = () => {
    const { userDetail, getUserByIdLoading } = useSelector(state => state.getUserById);
    console.log("🚀 ~ file: AddressSummary.js:31 ~ AddressSummary ~ userDetail:", userDetail)
    const navigate = useNavigate();

    return (
        <Stack spacing="8" borderWidth="1px" rounded="lg" padding="8" width="full">
            <Heading size="md">Address</Heading>
            <Stack spacing="6">

                <Flex justify="space-between">
                    <Text fontSize="lg" fontWeight="semibold">
                        {userDetail.present_address}
                    </Text>
                </Flex>
            </Stack>
            {/*<Button onClick={() => navigate(`/checkout/${sessionStorage.getItem('user_id')}`)} bg={mode(colorConfig.primary)} _hover={{ backgroundColor: '#000000', color: '#ffffff' }} color={mode(colorConfig.textColor)} colorscheme="blue" size="lg" fontSize="md" >
                Change/Update Address
    </Button>*/}
        </Stack>
    )
}

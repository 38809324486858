import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  deleteGroupSuccessful: false,
  deleteGroupIsLoading: false,
  deleteGroupError: "",
  deleteGroupMessage:"",
};

const deleteGroupSlice = createSlice({
  name: "addGroup",
  initialState,
  reducers: {
    deleteGroupPending: (state) => {
      state.deleteGroupIsLoading = true;
    },
    deleteGroupSuccess: (state, { payload }) => {
      state.deleteGroupIsLoading = false;
      state.addGroupSuccessful = true;
      state.addGroupError = "";
      state.addGroupMessage = payload;
    },
    deleteGroupFail: (state, { payload }) => {
      state.deleteGroupIsLoading = false;
      state.addGroupError = payload;
    },
  },
});

const { reducer, actions } = deleteGroupSlice;
export const { deleteGroupPending, deleteGroupSuccess, deleteGroupFail } =
  actions;

export default reducer;

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Stack,
  useColorModeValue as mode,
} from '@chakra-ui/react';

import * as React from 'react';
import { CartItem } from '../Cart/CartItem';
import { CartOrderSummary } from './CartOrderSummary';
import { cartData } from './_data';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { AddressSummary } from './AddressSummary';
import { getUserByIdFail, getUserByIdPending, getUserByIdSuccess } from '../../../services/slice/getUserByIdSlice';
import {getCartItemByIdPending, getCartItemByIdSuccess, getCartItemByIdFail} from '../../../services/slice/getCartItemSlice';
import { getUserById } from '../../../services/api/users-api';
import { useEffect } from 'react';
import { colorConfig } from '../../../../colorConfig';
import { Link } from 'react-router-dom';
import { getCartItemByIdApi } from '../../../services/api/cart-api';

const Cart = () => {
  const initialValue = 0;
  const totalValue = cartData.reduce((accumulator, currentValue) => accumulator + currentValue.mrp, initialValue);
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { isAuth } = useSelector(state => state.login);
  React.useEffect(() => {
    if (!isAuth) {
      navigate('/signin', { push: true });
    }
  }, [isAuth]);

  const fetchUserById = async (payload = params.userId) => {
    dispatch(getUserByIdPending());
    try {
      const getUser = await getUserById(payload);

      dispatch(getUserByIdSuccess(getUser[0]));
      // reset(getUser[0]);
    } catch (error) {
      dispatch(getUserByIdFail(error));
    }
  };


  const fetchCartItems = async (payload = {user_id:params.userId}) => {
    dispatch(getCartItemByIdPending());
    console.log("🚀 ~ file: Cart.js:59 ~ fetchCartItems ~ payload:", payload)
    try {
      const getCart = await getCartItemByIdApi(payload);
      console.log("🚀 ~ file: Cart.js:61 ~ fetchCartItems ~ getCart:", getCart)

      dispatch(getCartItemByIdSuccess(getCart));
      // reset(getUser[0]);
    } catch (error) {
      dispatch(getCartItemByIdFail(error.message));
      console.log("🚀 ~ file: Cart.js:66 ~ fetchCartItems ~ error.message:", error)
    }
  }

  useEffect(() => {
    fetchUserById();
    fetchCartItems();
  }, [])

  return (
    <Box
      maxW={{
        base: '3xl',
        lg: '7xl',
      }}
      mx="auto"
      px={{
        base: '4',
        md: '8',
        lg: '12',
      }}
      py={{
        base: '6',
        md: '8',
        lg: '12',
      }}
    >
      <Stack
        direction={{
          base: 'column',
          lg: 'row',
        }}
        align={{
          lg: 'flex-start',
        }}
        spacing={{
          base: '8',
          md: '16',
        }}
      >
        <Stack
          spacing={{
            base: '8',
            md: '10',
          }}
          flex="2"
        >
          <Heading fontSize="2xl" fontWeight="extrabold">
            {`Shopping Cart (${cartData.length} items)`}
          </Heading>

          <Stack  spacing="2">
            {cartData.map(item => (
              <CartItem  key={item.id} {...item} />
            ))}
          </Stack>
        </Stack>

        <Flex direction="column" rowGap={2} align="center" flex="1">
          <AddressSummary />
          <CartOrderSummary totalValue = {totalValue} />
          <HStack mt="6" fontWeight="semibold">
            <p>or</p>


            <Link to="/allcourses/all">
              <Button
                color={colorConfig.primary}
                variant="link"
                to="/allcourses/all"
              >
                Continue shopping
              </Button>
            </Link>
          </HStack>
        </Flex>
      </Stack>
    </Box>
  );
};
export default Cart;

import React from 'react';
import {
  Flex,
  Box,
  Heading,

  Button,
  VStack,

  FormControl,
  FormLabel,
  Input,
  InputGroup,

  Textarea,
  useColorModeValue,
  InputLeftElement,
  Stack,
  IconButton,
} from '@chakra-ui/react';
import { AiFillInstagram, AiFillLinkedin, AiOutlineTwitter } from 'react-icons/ai';
import { GoPerson } from 'react-icons/go';
import { FaAddressCard, FaFacebook } from 'react-icons/fa';
import { MdEmail, MdPhoneIphone, MdCall } from 'react-icons/md';
import { colorConfig } from '../../../../colorConfig';


export default function Contact() {
  return (

    <Flex
      marginBottom={10}
      borderRadius={'md'}
      bg={useColorModeValue('gray.100', 'gray.900')}
      align="center"
      justify="center"
      centerContent
      css={{
        // backgroundImage: useColorModeValue(CONFETTI_LIGHT, CONFETTI_DARK),
        backgroundAttachment: 'fixed',
      }}
      id="contact">
      <Box borderRadius="md" m={{ base: 5, md: 10, lg: 10 }} p={{ base: 5, lg: 10 }}>
        <Box>
          <VStack spacing={{ base: 4, md: 8, lg: 20 }}>
            <Heading
              fontSize={{
                base: '4xl',
                md: '5xl',
              }}>
              Get in Touch
            </Heading>

            <Stack
              spacing={{ base: 4, md: 8, lg: 20 }}
              direction={{ base: 'column', md: 'row' }}>
              <Stack
                align="center"
                justify="space-around"
                direction={{ base: 'row', md: 'column' }}>

                <Box>
                  <a href="tel:+91 8892453988">
                    <IconButton
                      cursor={'pointer'}
                      aria-label="phone"
                      variant="ghost"
                      fontSize={{ base: '20px', md: '35px' }}
                      icon={<MdCall />}
                      _hover={{
                        bg: colorConfig.primary,
                        fontSize: '40px',
                        color: useColorModeValue('white', 'gray.700'),
                      }}

                      isRound
                    />
                  </a>
                </Box>

                <Box>
                  <a href="https://redhood.in/">
                    <IconButton
                      cursor={'pointer'}
                      aria-label="phone"
                      variant="ghost"
                      fontSize={{ base: '20px', md: '35px' }}
                      icon={<FaAddressCard />}
                      _hover={{
                        bg: colorConfig.primary,
                        fontSize: '40px',
                        color: useColorModeValue('white', 'gray.700'),
                      }}

                      isRound
                    />
                  </a>
                </Box>

                <Box>
                  <a href="mailto:info@redhood.ai">
                    <IconButton
                      cursor={'pointer'}
                      aria-label="email"
                      variant="ghost"
                      fontSize={{ base: '20px', md: '35px' }}
                      icon={<MdEmail />}
                      _hover={{
                        bg: colorConfig.primary,
                        fontSize: '40px',
                        color: useColorModeValue('white', 'gray.700'),
                      }}

                      isRound
                    />
                  </a>
                </Box>
                <Box>
                  <a href="https://www.instagram.com/redhood.ai/">
                    <IconButton
                      cursor={'pointer'}
                      aria-label="github"
                      variant="ghost"
                      fontSize={{ base: '20px', md: '35px' }}
                      icon={<AiFillInstagram />}
                      _hover={{
                        bg: colorConfig.primary,
                        fontSize: '40px',
                        color: useColorModeValue('white', 'gray.700'),
                      }}
                      isRound
                    />
                  </a>
                </Box>

                <Box>
                  <a href="https://www.facebook.com/redhoodai">
                    <IconButton
                      cursor={'pointer'}
                      aria-label="facebook"
                      variant="ghost"

                      fontSize={{ base: '20px', md: '35px' }}
                      icon={<FaFacebook />}
                      _hover={{
                        bg: colorConfig.primary,
                        fontSize: '40px',
                        color: useColorModeValue('white', 'gray.700'),
                      }}
                      isRound
                    />
                  </a>
                </Box>


                {/*   <Box >
                  <a href="https://twitter.com/redhoodai">
                    <IconButton
                      cursor={'pointer'}
                      aria-label="twitter"
                      variant="ghost"
                      fontSize={{ base: '20px', md: '35px' }}

                      icon={<FaSquareXTwitter />}
                      _hover={{
                        bg: colorConfig.primary,
                        fontSize: '40px',
                        color: useColorModeValue('white', 'gray.700'),
                      }}
                      isRound
                    />
                  </a>
                </Box>*/}

                <Box>
                  <a href="https://www.linkedin.com/company/redhoodai/">
                    <IconButton
                      cursor={'pointer'}
                      aria-label="linkedin"
                      variant="ghost"
                      fontSize={{ base: '20px', md: '35px' }}
                      icon={<AiFillLinkedin />}
                      _hover={{
                        bg: colorConfig.primary,
                        fontSize: '40px',
                        color: useColorModeValue('white', 'gray.700'),
                      }}
                      isRound
                    />
                  </a>
                </Box>
              </Stack>

              <Box
                bg={useColorModeValue('white', 'gray.700')}
                borderRadius="lg"
                p={8}
                color={useColorModeValue('gray.700', 'whiteAlpha.900')}
                shadow="base">

                <form action="https://docs.google.com/forms/d/e/1FAIpQLSdTxUP14oATq9AbGuJChyIgStqyxZxFElVmD0F21HLJGpRlMQ/formResponse"
                  target="_self"
                  method="POST"
                  id="mG61Hd">
                  <VStack spacing={5}>
                    <FormControl isRequired>
                      <FormLabel>Name</FormLabel>

                      <InputGroup>
                        <InputLeftElement>
                          <GoPerson />
                        </InputLeftElement>
                        <Input focusBorderColor={colorConfig.primary} type="text" className="
                        whsOnd zHQkBf
                    "
                          aria-labelledby="i1"
                          aria-describedby="i2 i3"
                          jsname="YPqjbf"
                          autocomplete="off"
                          tabindex="0"
                          aria-label="Name"
                          name="entry.2005620554"

                          required
                          placeholder="Your Full Name" />
                      </InputGroup>
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Phone Number</FormLabel>

                      <InputGroup>
                        <InputLeftElement>
                          <MdPhoneIphone />
                        </InputLeftElement>
                        <Input focusBorderColor={colorConfig.primary} type="text" className="
                        whsOnd zHQkBf
                    "
                          aria-labelledby="i5" aria-describedby="i6 i7"
                          jsname="YPqjbf"
                          autocomplete="off"
                          tabindex="0"

                          dir="auto"
                          data-initial-dir="auto"
                          data-initial-value=""
                          id="phone"
                          required
                          name="entry.1166974658"
                          placeholder="Phone Number" />
                      </InputGroup>
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Email</FormLabel>

                      <InputGroup>
                        <InputLeftElement>
                          <MdEmail />
                        </InputLeftElement>
                        <Input focusBorderColor={colorConfig.primary} type="email"
                          className="
                          quantumWizTextinputPaperinputInput
                          exportInput
                          form-control
                        "
                          aria-labelledby="i9" aria-describedby="i10 i11"
                          jsname="YPqjbf"
                          autocomplete="off"
                          tabindex="0"
                          aria-label="Email"

                          name="entry.1045781291"
                          dir="auto"
                          data-initial-dir="auto"
                          data-initial-value=""
                          id="email"
                          required
                          placeholder="Email" />
                      </InputGroup>
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Message</FormLabel>

                      <Textarea
                        focusBorderColor={colorConfig.primary}
                        jscontroller="gZjhIf" jsaction="input:Lg5SV;ti6hGc:XMgOHc;rcuQ6b:WYd;" required="" dir="auto" data-initial-dir="auto" data-initial-value="" aria-labelledby="i13" aria-describedby="i14 i15"
                        className="
                                    quantumWizTextinputPapertextareaInput
                                    exportTextarea
                                    form-control
                                  "
                        jsname="YPqjbf"
                        data-rows="1"
                        tabindex="0"
                        aria-label="Message"

                        name="entry.1065046570"




                        id="message"
                        placeholder="Write message"
                        rows={6}
                        resize="none"
                      />
                    </FormControl>

                    <Button
                      colorScheme="blue"
                      bg={colorConfig.primary}
                      color="white"
                      _hover={{
                        bg: colorConfig.secondary,
                      }}
                      type='submit'
                      width="full">
                      Send Message
                    </Button>
                  </VStack>
                </form>

              </Box>
            </Stack>
          </VStack>
        </Box>
      </Box>
    </Flex>
  );
}

/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Link,
  Stack,
  Text,
  useColorModeValue as mode,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import * as React from 'react'
import { FaArrowRight } from 'react-icons/fa'
import { formatPrice } from '../Cart/PriceTag'
import { CartItem } from '../Cart/CartItem'
import { cartData } from './_data'
import { useForm } from 'react-hook-form'
import { colorConfig } from '../../../../colorConfig'
import { useSelector } from 'react-redux'


const OrderSummaryItem = (props) => {
  const { label, value, children } = props
  return (
    <Flex justify="space-between" fontSize="sm">
      <Text fontWeight="medium" color={mode('gray.600', 'gray.400')}>
        {label}
      </Text>
      {value ? <Text fontWeight="medium">{value}</Text> : children}
    </Flex>
  )
}

export const CartPreview = () => {

  const {
    register,
    // eslint-disable-next-line no-unused-vars
    handleSubmit,
    // eslint-disable-next-line no-unused-vars
    formState: { errors },
  } = useForm();
  const bg = useColorModeValue('#F7FAFC', 'gray.900');
  const { userDetail, getUserByIdLoading } = useSelector(state => state.getUserById);
  console.log("🚀 ~ file: CartPreview.js:52 ~ CartPreview ~ userDetail:", userDetail)
  return (
    <Box
      maxW={{
        base: '3xl',
        lg: '7xl',
      }}
      mx="auto"
      px={{
        base: '4',
        md: '8',
        lg: '12',
      }}
      py={{
        base: '6',
        md: '8',
        lg: '12',
      }}
      borderRadius={'md'}
      bg={bg}
    >
      <Stack
        spacing={{
          base: '8',
          md: '10',
        }}
        flex="2"
      >
        <Heading fontSize="2xl" fontWeight="extrabold" >
          Shipping and Payment Information
        </Heading>

        <form>
          <VStack width="full" spacing="6">
            <FormControl id="first_name">
              <FormLabel>Full Name</FormLabel>
              <Input
                type="text"
                maxLength={255}
                required
                isReadOnly
                {...register('full_name', { required: true })}
                defaultValue={userDetail.first_name + ' ' + userDetail.last_name}
              />
            </FormControl>

            <FormControl id="email">
              <FormLabel color={useColorModeValue('black', 'white')}>Email</FormLabel>
              <Input
                type="email"
                isReadOnly
                required
                {...register('email', { required: true })}
                defaultValue={userDetail.email}
              />
            </FormControl>

            <FormControl id="phone">
              <FormLabel>Phone</FormLabel>
              <InputGroup>
                <InputLeftAddon
                  children={'+91'}
                  type="number"
                  required
                  isReadOnly
                  {...register('country_code', { required: true })}
                />
                <Input
                  type="number"
                  required
                  isReadOnly
                  {...register('phone', { required: true })}
                  defaultValue={userDetail.phone}
                />
              </InputGroup>
            </FormControl>

            <Heading fontSize="2xl" fontWeight="extrabold">
              Payment Information
            </Heading>
            <FormControl id="card_number">
              <FormLabel>Card Number</FormLabel>
              <Input
                type="number"
                maxLength={255}
                required
                {...register('card_number', { required: true })}
                defaultValue=""
              />
            </FormControl>
            <FormControl id="name_on_card">
              <FormLabel>Name on Card</FormLabel>
              <Input
                type="text"
                maxLength={255}
                required
                {...register('name_on_card', { required: true })}
                defaultValue=""
              />
            </FormControl>
            <FormControl id="date_valid">
              <FormLabel>Validity</FormLabel>
              <Input
                type="text"
                maxLength={255}
                required
                {...register('date_valid', { required: true })}
                defaultValue=""
              />

            </FormControl>

            <FormControl id="cvv">
              <FormLabel>CVV</FormLabel>
              <Input
                type="text"
                maxLength={3}
                required
                {...register('cvv', { required: true })}
                defaultValue=""
              />

            </FormControl>
          </VStack>
        </form>
        <Button onClick={() => { }} type="submit" bg={mode(colorConfig.primary)} _hover={{ backgroundColor: '#000000', color: '#ffffff' }} color={mode(colorConfig.textColor)}>
          Checkout
        </Button>
      </Stack>

    </Box>
  )
}

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  AbsoluteCenter,
  Avatar,
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Radio,
  RadioGroup,
  Stack,
  StackDivider,
  Text,
  Textarea,
  useBreakpointValue,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { HiCloudUpload } from 'react-icons/hi';
import { MdArrowBack } from 'react-icons/md';
import { RiLockPasswordLine } from 'react-icons/ri';
import { FieldGroup } from './FieldGroup';
import CustomSpinner from '../../../common/CustomSpinner';
import { useForm } from 'react-hook-form';
import ChangePassword from '../../../common/ChangePassword/ChangePassword';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router'
import { Link, useLocation, useParams } from 'react-router-dom';

import {
  // deleteUserApi,
  getUserById,
} from '../../../services/api/users-api';

import {
  getUserByIdPending,
  getUserByIdSuccess,
  getUserByIdFail
} from '../../../services/slice/getUserByIdSlice';

const UserProfile = (props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    reset,
    register,
    handleSubmit,
    // eslint-disable-next-line no-unused-vars
    formState: { errors },
  } = useForm();

  const fetchUserById = async (payload = params.userId) => {
    console.log("🚀 ~ file: UserProfile.js:68 ~ fetchUserById ~ payload:", payload)
    dispatch(getUserByIdPending());
    try {
      const getUser = await getUserById(payload);

      dispatch(getUserByIdSuccess(getUser[0]));
      reset(getUser[0]);
    } catch (error) {
      dispatch(getUserByIdFail(error));
    }
  };

  const changePasswordTriggered = async () => {
    setShowChangePassword(true);
  }
  const { isAuth } = useSelector(state => state.login);
  const { userDetail, getUserByIdLoading } = useSelector(state => state.getUserById);

  useEffect(() => {

    if (!isAuth) {
      navigate("/signin", { replace: true })
    }
  }, [isAuth]);

  const color = useColorModeValue('gray.500', 'whiteAlpha.600');
  const widthOfInputField = useBreakpointValue({ base: 'full' });


  useEffect(() => {
    fetchUserById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      py="16"
      maxWidth="3xl"
      mx="auto"
    >
      {getUserByIdLoading ? (
        <AbsoluteCenter>
          <CustomSpinner />
        </AbsoluteCenter>
      ) : (
        <form
          id="settings-form"
          onSubmit={e => {
            e.preventDefault(); // form submit logic
          }}
        >

          <Stack spacing="4" divider={<StackDivider />}>
            <Heading size="lg" as="h1" paddingBottom="4">
              <HStack>
              <MdArrowBack  cursor='pointer' color="#ae0000" onClick={() => {navigate(-1)}} />
                <Text>Profile</Text>
              </HStack>
            </Heading>
            <FieldGroup title="Personal Info">
              <VStack width="full" spacing="6">
                <FormControl id="first_name">
                  <FormLabel>First Name</FormLabel>
                  <Input
                    type="text"
                    maxLength={255}
                    isReadOnly
                    required
                    {...register('first_name', { required: true })}
                    defaultValue={userDetail !== undefined ? userDetail.first_name : ''}
                  />
                </FormControl>

                <FormControl id="middle_Name">
                  <FormLabel>Middle Name</FormLabel>
                  <Input
                    type="text"
                    maxLength={255}
                    isReadOnly
                    {...register('middle_name', { required: false })}
                    defaultValue={userDetail !== undefined ? userDetail.middle_name : ''}
                  />
                </FormControl>

                <FormControl id="last_name">
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    type="text"
                    isReadOnly
                    maxLength={255}
                    required
                    {...register('last_name', { required: true })}
                    defaultValue={userDetail !== undefined ? userDetail.last_name : ''}
                  />
                </FormControl>

                <FormControl id="email">
                  <FormLabel>Email</FormLabel>
                  <Input
                    type="email"
                    isReadOnly
                    required
                    {...register('email', { required: true })}
                    defaultValue={userDetail !== undefined ? userDetail.email : ''}
                  />
                </FormControl>

                <FormControl id="phone">
                  <FormLabel>Phone</FormLabel>
                  <InputGroup>
                    <InputLeftAddon
                      children={'+91'}
                      type="number"
                      isReadOnly
                      required
                      {...register('country_code', { required: true })}
                      defaultValue={userDetail !== undefined ? userDetail.country_code : ''}
                    />
                    <Input
                      type="number"
                      required
                      isReadOnly
                      {...register('phone', { required: true })}
                      defaultValue={userDetail !== undefined ? userDetail.phone : ''}
                    />
                  </InputGroup>
                </FormControl>

                <FormControl id="Gender">
                  <FormLabel as="legend">Gender</FormLabel>
                  <RadioGroup defaultValue={userDetail.gender}>
                    <HStack spacing="24px">
                      <Radio
                        value="Male"
                        {...register('gender', { required: true })}
                      >
                        Male
                      </Radio>
                      <Radio
                        value="Female"
                        {...register('gender', { required: true })}
                      >
                        Female
                      </Radio>
                      <Radio
                        value="Others"
                        {...register('gender', { required: true })}
                      >
                        Others
                      </Radio>
                    </HStack>
                  </RadioGroup>
                </FormControl>

                <FormControl id="dateOfBirth">
                  <FormLabel>DOB</FormLabel>
                  <Input
                    isReadOnly
                    type="date"
                    defaultValue={userDetail !== undefined ? userDetail.dob : ''}

                    {...register('dob', { required: true })}
                  />
                  <FormHelperText>
                    Your birth date & year - DD-MM-YYYY HH:MM DAYTIME.
                  </FormHelperText>
                </FormControl>

                <FormControl id="bloodGroup">
                  <FormLabel>Blood Group</FormLabel>
                  <Input
                    type="text"
                    isReadOnly
                    defaultValue={userDetail !== undefined ? userDetail.blood_group : ''}
                    {...register('blood_group', { required: false })}
                  />
                  <FormHelperText>
                    as mentioned in your medical book/record.
                  </FormHelperText>
                </FormControl>

                <FormControl id="joining_date">
                  <FormLabel>Joining Date</FormLabel>
                  <Input
                    type="date"
                    isReadOnly
                    defaultValue={userDetail !== undefined ? userDetail.joining_date : ''}
                    {...register('joining_date', { required: true })}
                  />
                </FormControl>
                <FormControl id="exit_date">
                  <FormLabel>Exit Date</FormLabel>
                  <Input
                    type="date"
                    defaultValue={userDetail !== undefined ? userDetail.exit_date : ''}
                    {...register('exit_date', { required: true })}
                  />
                </FormControl>
              </VStack>
            </FieldGroup>
            <FieldGroup title="Profile">
              <VStack width="full" spacing="6">
                <Stack direction="row" spacing="6" align="center" width="full">
                  <Avatar
                    size="xl"
                    name={localStorage.getItem('user_name').toString()}
                  // src="https://images.unsplash.com/photo-1488282396544-0212eea56a21?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
                  />
                  <Box>
                    <HStack spacing="5">
                      <Button isDisabled leftIcon={<HiCloudUpload />}>
                        Change photo
                      </Button>
                      <Button isDisabled variant="ghost" colorscheme="red">
                        Delete
                      </Button>
                    </HStack>
                    <Text
                      fontSize="sm"
                      mt="3"
                      color={color}
                    >
                      .jpg, .gif, or .png. Max file size 700K.
                    </Text>
                  </Box>
                </Stack>
                <Button onClick={changePasswordTriggered} variant="outline" leftIcon={<RiLockPasswordLine />}>
                  Change Password
                </Button>
              </VStack>
            </FieldGroup>
            <FieldGroup title="Background">
              <VStack width="full" spacing="6">
                <FormControl id="father_name">
                  <FormLabel>Father Name</FormLabel>
                  <Input
                    type="text"
                    isReadOnly
                    maxLength={255}
                    width={widthOfInputField}
                    required
                    {...register('father_name', { required: true })}
                    defaultValue={userDetail !== undefined ? userDetail.father_name : ''}
                  />
                </FormControl>
                <FormControl id="father_phone">
                  <FormLabel>Father's Phone</FormLabel>
                  <InputGroup>
                    <Input
                      type="number"
                      required
                      {...register('father_phone', { required: true })}
                      defaultValue={userDetail !== undefined ? userDetail.father_phone : ''}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl id="mother_name">
                  <FormLabel>Mother's Name</FormLabel>
                  <Input
                    type="text"
                    isReadOnly
                    maxLength={255}
                    width={widthOfInputField}
                    required
                    {...register('mother_name', { required: true })}
                    defaultValue={userDetail !== undefined ? userDetail.mother_name : ''}
                  />
                </FormControl>
                <FormControl id="mother_phone">
                  <FormLabel>Mother's Phone</FormLabel>
                  <InputGroup>
                    <Input
                      type="number"
                      required
                      isReadOnly
                      {...register('mother_phone', { required: true })}
                      defaultValue={userDetail !== undefined ? userDetail.mother_phone : ''}
                    />
                  </InputGroup>
                </FormControl>

                <FormControl id="present_address">
                  <FormLabel>Present Address</FormLabel>
                  <Textarea
                    rows={5}
                    {...register('present_address', { required: true })}
                    isReadOnly
                    defaultValue={userDetail !== undefined ? userDetail.present_address : ''}
                  />
                </FormControl>
                <FormControl id="permanent_address">
                  <FormLabel>Permanent Address</FormLabel>
                  <Textarea
                    rows={5}
                    {...register('permanent_address', { required: true })}
                    isReadOnly
                    defaultValue={userDetail !== undefined ? userDetail.permanent_address : ''}
                  />
                </FormControl>
              </VStack>
            </FieldGroup>
            <FieldGroup title="Account Service">
              <Stack width="full" spacing="4">
                <Checkbox defaultChecked={userDetail.profile_status}>Account Status.</Checkbox>
                <Checkbox>
                  Get notifications about your account activities
                </Checkbox>
              </Stack>
            </FieldGroup>
            {/*<FieldGroup title="Connect accounts">
              <HStack width="full">
                <Button isDisabled variant="outline" leftIcon={<FaGithub />}>
                  Connect Github
                </Button>
                <Button
                  variant="outline"
                  isDisabled
                  leftIcon={<Box as={FaGoogle} color="red.400" />}
                >
                  Connect Google
                </Button>
              </HStack>
            </FieldGroup>*/}
          </Stack>
          {/* <FieldGroup mt="8">
            <HStack width="full">
              <Button type="submit" colorscheme="blue">
                Save Changes
              </Button>
              <Button variant="outline">Cancel</Button>
            </HStack>
          </FieldGroup>*/}
        </form>
      )}
      <Text as='i' color='#ae0000'>Note: If you want to update any information please contact the admin.</Text>
      {showChangePassword && <ChangePassword isOpen={showChangePassword} onClose={() => setShowChangePassword(false)} />}

    </Box>

  );
};
export default UserProfile;

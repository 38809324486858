/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Box, Flex, useColorModeValue, chakra, Button } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { wishlistdata } from './_data';
// eslint-disable-next-line no-unused-vars
import { Link } from 'react-router-dom';

import { getWishlistItemByIdApi } from '../../../services/api/wishlist-api';
import {
  getWishlistByUserIdPending,
  getWishlistByUserIdSuccess,
  getWishlistByUserIdFail,
} from '../../../services/slice/getWishlistByUserIdSlice';
import { useDispatch, useSelector } from 'react-redux';
import WishlistCard from './WishlistCard';
import CustomSpinner from '../../../common/CustomSpinner';
import EmptySpace from '../../../common/EmptyTemplate/EmptySpace';

const Wishlist = () => {
  const dispatch = useDispatch();

  const [combinedArray, setCombinedArray] = useState([]);
  const [mergedArray, setMergedArray] = useState([]);
  const { products } = useSelector(state => state.products);
  const { allAttributes } = useSelector(state => state.getAllAttribute);
  const { allMedia } = useSelector(state => state.getAllMedia);
  const { wishList, getWishlistByUserIdLoading } = useSelector(state => state.getWishListByUserId);


  const fetchWishList = async (payload = { user_id: sessionStorage.getItem('user_id') }) => {

    dispatch(getWishlistByUserIdPending());
    try {
      const getWishlist = await getWishlistItemByIdApi(payload);
      // console.log("🚀 ~ file: Wishlist.js:36 ~ fetchWishList ~ getWishlist:", getWishlist)
      dispatch(getWishlistByUserIdSuccess(getWishlist));

    } catch (error) {
      console.log("🚀 ~ file: ProductDetail.js:103 ~ fetchProductById ~ error:", error)
      dispatch(getWishlistByUserIdFail(error));
    }
  };

  useEffect(() => {
    fetchWishList();
  }, [])

  return (
    <Flex
      bg={useColorModeValue('#F9FAFB', 'gray.600')}
      p={50}
      columnGap={'4'}
      rowGap={'4'}
      w="full"
      alignItems="center"
      justifyContent="center"
      flexDirection={'column'}
    >
      {getWishlistByUserIdLoading && <CustomSpinner />}
      {wishlistdata.length > 0 && sessionStorage.getItem('user_id') === 0 ? wishlistdata.map(element => <WishlistCard data={element} />) : <EmptySpace />}
    </Flex>
  );
};

export default Wishlist;

import {
  FormControl,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useMergeRefs,
} from '@chakra-ui/react';
import * as React from 'react';
import { HiOutlineSearch } from 'react-icons/hi';
import { useNavigate } from 'react-router';

const Search = React.forwardRef((props, ref) => {
  const navigate = useNavigate();
  const inputRef = React.useRef(null);
  // console.log("🚀 ~ file: Search.js:14 ~ Search ~ inputRef:", inputRef.current.value)
  const mergeRef = useMergeRefs(inputRef, ref);

  return (
    <FormControl>
      <InputGroup w={{ base: 'full', md: 'md' }}>
        <InputRightElement>
          <IconButton
            color="#ae0000"
            aria-label="Call Segun"
            size="sm"
            borderRadius={'full'}
            icon={<HiOutlineSearch />}
            onClick={() => navigate(`/allcourses/search/${inputRef.current.value}`)}
          />
        </InputRightElement>
        <Input
          borderRadius="full"
          id="searchData"
          placeholder="Let's get started"
          ref={mergeRef}
          name="searchData"
          type={'text'}
          autoComplete="current-searchData"
          required
          {...props}
        />
      </InputGroup>
    </FormControl>
  );
});
export default Search;

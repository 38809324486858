/* eslint-disable jsx-a11y/accessible-emoji */
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  HStack,
  IconButton,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { FaGithub, FaLinkedin, FaTwitter, FaInstagram } from 'react-icons/fa';
import { RiWhatsappLine, RiMailSendFill } from 'react-icons/ri';
import Logo from '../../assets/images/redhood-logo.png';
import QRCode from '../../assets/images/redhood_ai_QR.png';
import TwitterX from '../../assets/images/twitterx.svg';

const Footer = () => (
  <Box bg={'#262830'}>
    <Container maxW={'container.xl'} as="footer" role="contentinfo">
      <Stack
        spacing="8"
        direction={{
          base: 'column',
          md: 'row',
        }}
        justify="space-between"
        py={{
          base: '12',
          md: '16',
        }}
      >
        <Stack
          spacing={{
            base: '6',
            md: '8',
          }}
          align="start"
        >
          <Image src={Logo} width="214px" />

          {/*<Stack spacing="4">
            <Text fontSize="sm" fontWeight="semibold" color="#ffffff">
              Stay up to date
            </Text>
            <Stack
              spacing="4"
              direction={{
                base: 'column',
                sm: 'row',
              }}
              maxW={{
                base: 'full',
                md: '360px',
              }}
            >
              <Input
                w={{ base: 'full', md: '150px' }}
                placeholder="Enter your email"
                type="email"
                required
              />
              <Button
                w={{ base: 'full', md: '90px' }}
                variant="solid"
                type="submit"
                flexShrink={0}
              >
                Subscribe
              </Button>
            </Stack>
          </Stack>*/}
        </Stack>
        <Stack
          direction={{
            base: 'column-reverse',
            md: 'column',
            lg: 'row',
          }}
          spacing={{
            base: '12',
            md: '8',
          }}
        >
          <Stack
            direction={{
              base: 'column-reverse',
              md: 'row',
            }}
            spacing="8"
          >
            <Stack spacing="3" minW="36" flex="1">
              <Text fontSize="sm" fontWeight="semibold" color="#ffffff">
                About
              </Text>
              <Stack spacing="3" shouldWrapChildren>
                <NavLink to="/faq">
                  <Button variant="link">FAQs</Button>
                </NavLink>
                <NavLink to="/cancellation-refunds">
                  <Button variant="link">Cancellation & Refund</Button>
                </NavLink>

              </Stack>
            </Stack>
            <Stack spacing="4" minW="36" flex="1">
              <Text fontSize="sm" fontWeight="semibold" color="#ffffff">
                Legal
              </Text>
              <Stack spacing="3" shouldWrapChildren>
                <NavLink to="/privacy-policy">
                  <Button variant="link">Privacy Policy</Button>
                </NavLink>
                <NavLink to="/terms-conditions">
                  <Button variant="link">Terms & Conditions</Button>
                </NavLink>
                <NavLink to="/shipping-delivery">
                  <Button variant="link">Shipping & Delivery</Button>
                </NavLink>
              </Stack>
            </Stack>

            <Stack spacing="4" minW="36" flex="1">
              <Text fontSize="sm" fontWeight="semibold" color="#ffffff">
                Reach Us
              </Text>
              <Stack spacing="3" shouldWrapChildren>
                <a href="https://wa.me/8892453988" target="_blank" rel="noreferrer">
                  <HStack>
                    <RiWhatsappLine size={25} color="#128C7E" />
                    <Text
                      fontSize={'sm'}
                      fontWeight="medium"
                      color="whiteAlpha.700"
                    >
                      +91 9845999090
                    </Text>
                  </HStack>
                </a>
                <a href="mailto:info@redhood.ai" target="_blank" rel="noreferrer">
                  <HStack>
                    <RiMailSendFill size={25} color="#ffffff" />
                    <Text
                      fontSize={'sm'}
                      fontWeight="medium"
                      color="whiteAlpha.700"
                    >
                      info@redhood.ai
                    </Text>
                  </HStack>
                </a>
                <Text
                  fontSize={'sm'}
                  fontWeight="medium"
                  color="whiteAlpha.700"
                >Redhood Technologies LLP</Text>
                <Text
                  fontSize={'sm'}
                  fontWeight="medium"
                  color="whiteAlpha.700"
                >
                  Address: 5, 1st Cross, Dibbur Munianjanappa Layout, K
                  Narayanapura, Kothanur post, Bengaluru, Karnataka 560077
                </Text>
                <Box>
                  <img
                    style={{ width: 80, height: 80 }}
                    src={QRCode}
                    alt="RedHood QR"
                  />
                </Box>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Divider />
      <Stack
        pt="5"
        pb="12"
        justify="space-between"
        direction={{
          base: 'column-reverse',
          md: 'row',
        }}
        align="center"
      >
        <Text fontSize="sm" fontWeight="semibold" color="#ffffff">
          Redhood Technologies LLP | Copyright &copy; All rights reserved.
        </Text>
        <ButtonGroup variant="ghost">
          <IconButton
            as="a"
            href="https://www.linkedin.com/company/redhoodai/about/"
            aria-label="LinkedIn"
            icon={<FaLinkedin fontSize="1.25rem" color="#ffffff" />}
          />
          <IconButton
            as="a"
            href="https://github.com/redhoodai"
            aria-label="GitHub"
            icon={<FaGithub fontSize="1.25rem" color="#ffffff" />}
          />


          <IconButton
            as="a"
            href="https://www.instagram.com/redhood.ai/"
            aria-label="Instagram"
            icon={<FaInstagram fontSize="1.25rem" color="#ffffff" />}
          />
        </ButtonGroup>
      </Stack>
    </Container>
  </Box>
);
export default Footer;

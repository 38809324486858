import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  deleteMediaImagesSuccessful: false,
  deleteMediaImagesIsLoading: false,
  deleteMediaImagesError: "",
  deleteMediaImagesMessage:"",
};

const deleteMediaImagesSlice = createSlice({
  name: "deleteMediaImages",
  initialState,
  reducers: {
    deleteMediaImagesPending: (state) => {
      state.deleteMediaImagesIsLoading = true;
    },
    deleteMediaImagesSuccess: (state, { payload }) => {
      state.deleteMediaImagesIsLoading = false;
      state.addMediaImagesSuccessful = true;
      state.addMediaImagesError = "";
      state.addMediaImagesMessage = payload;
    },
    deleteMediaImagesFail: (state, { payload }) => {
      state.deleteMediaImagesIsLoading = false;
      state.addMediaImagesError = payload;
    },
  },
});

const { reducer, actions } = deleteMediaImagesSlice;
export const { deleteMediaImagesPending, deleteMediaImagesSuccess, deleteMediaImagesFail } =
  actions;

export default reducer;

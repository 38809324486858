export const reviews = [
  {
    id: '1',
    author: 'Dinesh Kumar',
    createdAt: '11 months ago',
    comment:
      'I recently completed redhood institute online python course. It was great learning experience. The trainer was highly Knowledgeable and the trainer also assist in resume preparation. I had very good learning experience with redhood institute  it is a good place to learn new skill. I highly recommend redhood institute  for anyone who are looking for gaining skill and learning.',
    rating: 5,
  },
  {
    id: '2',
    author: 'Arvind',
    createdAt: '11 months ago',
    // title: 'Great price & quality content',
    comment:
      "I had a great experience learning python at redhood ai. The course was designed well and the trainer chethan sir was teach us very well and he Clarifies my doubt and provided a pratical exam to understand me easily and get concepts much better on that topics. I'll will highly recommend redhood ai",
    rating: 5,
  },
  {
    id: '3',
    author: 'Ayyanar C',
    createdAt: '11 months ago',
    // title: 'Highly recommended',
    comment:
      "redhood ai  Java Classes was helpful. The best part about their course is the knowledge growth after every lecture and the step-by-step guidance I received. I would recommend this redhood ai to others, anyone looking to learn or start there career in IT industry.",
    rating: 5,
  },
  {
    id: '4',
    author: 'Avinash',
    createdAt: '11 months ago',
    // title: 'Best course I have ever bought',
    comment:
      "I had a great experience at redhood ai. The trainer Dinesh Kumar is very knowledgeable person. He clears all doubts very well. I have completed python course at redhood ai is best option to build your career.",
    rating: 5,
  }
]

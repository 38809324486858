/* eslint-disable react-hooks/exhaustive-deps */
import {
    Button,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    FormControl,
    FormLabel,
    Input,
    Progress,
    Select,
    Stack,
    useColorModeValue,
    useToast,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineSave, AiOutlineReload } from 'react-icons/ai';

import {
    addSpecializationPending,
    addSpecializationSuccess,
    addSpecializationFail,
} from '../../../../../services/slice/addSpecializationSlice';

import {
    updateSpecializationPending,
    updateSpecializationSuccess,
    updateSpecializationFail,
} from '../../../../../services/slice/updateSpecializationSlice';

import {
    getAllQualificationsPending,
    getAllQualificationsSuccess,
    getAllQualificationsFail
} from '../../../../../services/slice/getAllQualificationSlice';

import {
    addSpecializationApi,
    updateSpecializationApi,
} from '../../../../../services/api/specialization-api';

import { useForm } from 'react-hook-form';

import { getAllQualificationApi } from '../../../../../services/api/qualification-api';

const SideDrawer = props => {
    console.log("🚀 ~ file: SpecializationSidePanel.js:40 ~ SideDrawer ~ props:", props)
    const { qualification, qualifiactionIsLoading } = useSelector(state => state.qualification);

    const {
        register,
        handleSubmit,
        // eslint-disable-next-line no-unused-vars
        formState: { errors },
    } = useForm();
    const dispatch = useDispatch();

    const {
        addSpecializationIsLoading,
    } = useSelector(state => state.addSpecialization);

    const {
        updateSpecializationSuccessful,
        updateSpecializationIsLoading,
    } = useSelector(state => state.updateSpecialization);

    const toast = useToast();

    const getAllQualification = async () => {
        dispatch(getAllQualificationsPending());
        try {
            const qualification = await getAllQualificationApi();
            console.log("🚀 ~ file: Qualification.js:31 ~ getAllQualification ~ qualification:", qualification);
            dispatch(getAllQualificationsSuccess(qualification));
        } catch (error) {
            dispatch(getAllQualificationsFail(error));
        }

    }

    useEffect(() => {

        getAllQualification();
    }, [])

    const onSubmit = async data => {

        dispatch(addSpecializationPending());

        try {
            const addSpecialization = await addSpecializationApi(data);
            console.log("🚀 ~ file: SpecializationSidePanel.js:68 ~ onSubmit ~ addSpecialization:", addSpecialization)

            await dispatch(addSpecializationSuccess(addSpecialization));
            toast({
                title: `Add Specialization ${addSpecialization}`,
                position: 'top-right',
                status: 'success',
                isClosable: true,
            });
            props.onClose();
        } catch (error) {

            dispatch(addSpecializationFail(error));
            toast({
                title: `${error}`,
                position: 'top-right',
                status: 'error',
                isClosable: true,
            });
        }
    };

    const onEditSubmit = async data => {

        data.id = props.data.id;

        dispatch(updateSpecializationPending());

        try {
            const updateProduct = await updateSpecializationApi(data);
            dispatch(updateSpecializationSuccess(updateProduct), () => {
                console.log(updateSpecializationSuccessful);
            });

            toast({
                title: `${updateProduct}`,
                position: 'top-right',
                status: 'success',
                isClosable: true,
            });

            props.onClose();
        } catch (error) {
            dispatch(updateSpecializationFail(error));
            toast({
                title: `${error}`,
                position: 'top-right',
                status: 'error',
                isClosable: true,
            });
        }
    };

    const color = useColorModeValue('#ae0000', 'white');
    return (
        <>
            <Drawer onClose={props.onClose} isOpen={props.isOpen} size={'lg'}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerHeader>
                        {props.type === 'add' ? 'Add Specialization' : 'Edit Specialization'}
                    </DrawerHeader>
                    <Drawer />
                    <DrawerBody>
                        <form
                            onSubmit={
                                props.type === 'add'
                                    ? handleSubmit(onSubmit)
                                    : handleSubmit(onEditSubmit)
                            }
                        >
                            <Stack spacing="6">
                                <FormControl id="specialization_id">
                                    <FormLabel>Specialization Level ID</FormLabel>
                                    <Input
                                        name="specialization_id"
                                        isDisabled
                                        {...register('id', { required: false })}
                                        type="text"
                                        defaultValue={props.data.id}
                                    />
                                </FormControl>

                                <FormControl id="qualification_id" isRequired>
                                    <FormLabel>Qualification</FormLabel>
                                    <Select
                                        {...register('qualification_id', { required: true })}
                                        placeholder="Select Qualification"
                                        defaultValue={props.data.qualification_id}
                                    >
                                        {qualification.map((element, item) => {
                                            return (
                                                <option
                                                    key={item + element.id}
                                                    value={element.id}
                                                >
                                                    {element.type}
                                                </option>
                                            );
                                        })}
                                    </Select>
                                </FormControl>

                                <FormControl id="specialization_type">
                                    <FormLabel>Specialization</FormLabel>
                                    <Input
                                        name="specialization"
                                        {...register('specialization', { required: true })}
                                        type="text"
                                        autoComplete="text"
                                        required
                                        defaultValue={props.data.specialization}
                                    />
                                </FormControl>


                                <Button
                                    type="submit"
                                    leftIcon={
                                        addSpecializationIsLoading || updateSpecializationIsLoading ? (
                                            <Progress size="xs" isIndeterminate />
                                        ) : (
                                            <AiOutlineSave
                                                color={color}
                                            />
                                        )
                                    }
                                    width='full'
                                >
                                    Submit
                                </Button>
                                <Button
                                    variant={'link'}
                                    leftIcon={
                                        <AiOutlineReload
                                            color={color}
                                        />
                                    }
                                    width='full'
                                >
                                    {props.type === 'add' ? 'Reset' : 'Cancel'}
                                </Button>
                            </Stack>
                        </form>
                        {qualifiactionIsLoading && <Progress size="xs" isIndeterminate />}
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default SideDrawer;

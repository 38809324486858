export const BASE_URL = 'https://redhood.ai:30361';

export const LOGIN_API = BASE_URL + '/login';
export const LOG_OUT_API = BASE_URL + '/logout';
export const GET_ALL_USER_API = BASE_URL + '/users/selectAll';
export const ADD_USER_API = BASE_URL + '/users/insertId';
export const UPDATE_USER_API = BASE_URL + '/users/update';
export const DELETE_USER_API = BASE_URL + '/users/delete';
export const GET_ALL_PRODUCTS_API = BASE_URL + '/ecommerce/products/selectAll';
export const GET_ALL_PRODUCTS_VIEW_API = BASE_URL + '/ecommerce/product/view/selectAll';
export const GET_ALL_GROUPS_API = BASE_URL + '/users/group/selectAll';
export const ADD_GROUP_API = BASE_URL + '/users/group/insertId';
export const DELETE_GROUP_API = BASE_URL + '/users/group/delete';
export const UPDATE_GROUP_API = BASE_URL + '/users/group/update';
export const ADD_PRODUCTS_API = BASE_URL + '/ecommerce/products/insertId';
export const UPDATE_PRODUCT_API = BASE_URL + '/ecommerce/products/update';
export const DELETE_PRODUCT_API = BASE_URL + '/ecommerce/products/delete';
export const GET_ALL_CATEGORY_API = BASE_URL + '/ecommerce/category/selectAll';
export const ADD_CATEGORY_API = BASE_URL + '/ecommerce/category/insertId';
export const UPDATE_CATEGORY_API = BASE_URL + '/ecommerce/category/update';
export const DELETE_CATEGORY_API = BASE_URL + '/ecommerce/category/delete';
export const GET_ALL_BRAND_API = BASE_URL + '/ecommerce/brands/selectAll';
export const ADD_BRAND_API = BASE_URL + '/ecommerce/brands/insertId';
export const UPDATE_BRAND_API = BASE_URL + '/ecommerce/brands/update';
export const DELETE_BRAND_API = BASE_URL + '/ecommerce/brands/delete';
export const GET_ALL_BATCH_API = BASE_URL + '/batch/selectAll';
export const ADD_BATCH_API = BASE_URL + '/batch/insertId';
export const UPDATE_BATCH_API = BASE_URL + '/batch/update';
export const DELETE_BATCH_API = BASE_URL + '/batch/delete';
export const ADD_ATTRIBUTE_API = BASE_URL + '/ecommerce/attributes/insertId';
export const UPDATE_ATTRIBUTE_API = BASE_URL + '/ecommerce/attributes/update';
export const DELETE_ATTRIBUTE_API = BASE_URL + '/ecommerce/attributes/delete';
export const GET_ALL_ATTRIBUTE_API = BASE_URL + '/ecommerce/attributes/selectAll';
export const GET_ATTRIBUTE_BY_ID_API = BASE_URL + '/ecommerce/attributes/';
export const GET_PRODUCT_BY_ID_API = BASE_URL + '/ecommerce/products/';
export const GET_USER_BY_ID_API = BASE_URL + '/users/list/';
export const GET_CART_ITEM_BY_ID_API = BASE_URL + '/ecommerce/cart/view/select';
export const GET_ALL_CART_ITEM_API = BASE_URL + '/ecommerce/cart/selectAll';
export const ADD_ITEM_TO_CART_API = BASE_URL + '/ecommerce/cart/insertId';
export const UPDATE_CART_API = BASE_URL + '/ecommerce/cart/update';
export const ADD_ITEM_TO_WISHLIST_API = BASE_URL + '/ecommerce/wishlist/insertId';
export const GET_WISHLIST_ITEM_BY_USER_ID_VIEW_API = BASE_URL + '/ecommerce/wishlist/view/select';
export const GET_ALL_QUALIFICATION_API = BASE_URL + '/qualification/selectAll';
export const GET_QUALIFICATION_BY_ID_API = BASE_URL + '/qualification/'
export const ADD_QUALIFICATION_API = BASE_URL + '/qualification/insert';
export const UPDATE_QUALIFICATION_API = BASE_URL + '/qualification/update';
export const DELETE_QUALIFICATION_API = BASE_URL + '/qualification/delete';
export const GET_ALL_SPECIALIZATION_API = BASE_URL + '/qualification/specialization/selectAll';
export const GET_SPECIALIZATION_BY_ID_API = BASE_URL + '/qualification/specialization/'
export const ADD_SPECIALIZATION_API = BASE_URL + '/qualification/specialization/insert';
export const UPDATE_SPECIALIZATION_API = BASE_URL + '/qualification/specialization/update';
export const DELETE_SPECIALIZATION_API = BASE_URL + '/qualification/specialization/delete';
export const ADD_MEDIA_IMAGES_API = BASE_URL + '/ecommerce/media/insertId';
export const DELETE_MEDIA_IMAGES_API = BASE_URL + '/ecommerce/media/';
export const GET_ALL_MEDIA_IMAGES_API = BASE_URL + '/ecommerce/media/selectAll';
export const GET_MEDIA_BY_ATTRIBUTE_API = BASE_URL + '/ecommerce/media/';
export const GET_ALL_FEATURES_API = BASE_URL + '/users/feature/selectAll';

import axios from 'axios';
import {
  ADD_CATEGORY_API,
  UPDATE_CATEGORY_API,
  GET_ALL_CATEGORY_API,
  DELETE_CATEGORY_API,
} from './generic-api';

export const getAllCategoryApi = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(GET_ALL_CATEGORY_API, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
        },
      });
      
      resolve(response.data);
    } catch (error) {
      reject(error.message);
    }
  });
};
export const addCategoryApi = payload => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(ADD_CATEGORY_API, payload, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
        },
      });
     
      resolve(response.data);
    } catch (error) {
      reject(error.message);
    }
  });
};
export const updateCategoryApi = payLoadData => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.patch(UPDATE_CATEGORY_API, payLoadData, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
          'Access-Control-Allow-Origin': '*',
          withCredentials: false,
        },
      });

      resolve(response.data);
      // }
    } catch (error) {
      reject(error.message);
    }
  });
};

export const deleteCategoryApi = payLoadData => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(DELETE_CATEGORY_API, payLoadData, {
        headers: {
          session_id: sessionStorage.getItem('session_id'),
          'Content-type': 'application/json',
        },
      });
      resolve(response.data);
    } catch (error) {
      reject(error.message);
    }
  });
};
